import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';
import VerificationInput from 'react-verification-input';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';

import { useSnackbar } from 'src/components/snackbar';
import { useConfirmAPI, useRegisterAPI, useSendCodeAPI } from 'src/api/auth';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

const VerifyAccount = ({
  email,
  isLogin,
  setActiveStep = () => {},
}: {
  email: string;
  isLogin?: boolean;
  setActiveStep?: any;
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disableResend, setDisableResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(30);
  const { isLoading, isSuccess, confirmCode } = useConfirmAPI();
  const { sendCode } = useSendCodeAPI();
  const { registerAccount: resendCode } = useRegisterAPI(true);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (isLogin) {
        return navigate('/dashboard/business/dashboard', { replace: true });
      }
      setActiveStep((step: number) => step + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  const handlesendCode = () => {
    setTimeLeft(30);
    setDisableResend(true);
    if (!isLogin) {
      return resendCode(email);
    }
    sendCode(email);
  };
  const handleConfirmCode = (code: string) => confirmCode({ email, code });

  useEffect(() => {
    timeLeft > 0 && setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    timeLeft <= 0 && setDisableResend(false);
  }, [timeLeft]);

  return (
    <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}>
      <Typography fontSize={22} fontWeight={'bold'}>
        A message with verification code was sent to your email
      </Typography>
      <Box display={'inline-block'} sx={{ my: 3 }} alignItems={'center'} alignContent={'center'}>
        <VerificationInput
          length={6}
          validChars={'0-9'}
          placeholder=""
          classNames={{
            character: 'character',
            characterInactive: 'character--inactive',
          }}
          onComplete={handleConfirmCode}
        />
      </Box>
      <Box>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          fullWidth
          sx={{
            fontSize: 18,
            fontFamily: 'CircularStd',
            height: 50,
            width: 300,
            mb: 2,
          }}
          onClick={() =>
            enqueueSnackbar('Type a code first!', {
              variant: 'error',
            })
          }
        >
          Continue
        </LoadingButton>
        <BusinessStyledTypograhpy mb={1}>
          Time left for resending code: {timeLeft}
        </BusinessStyledTypograhpy>
        <BusinessStyledTypograhpy sx={{ mt: 2 }}>Didn’t get the email?</BusinessStyledTypograhpy>
        <Button
          endIcon={<ArrowForwardIcon sx={{ width: '24px', height: '24px' }} />}
          disabled={disableResend}
          onClick={handlesendCode}
        >
          Re-send Code
        </Button>
      </Box>
    </Box>
  );
};

export default VerifyAccount;
