import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UUID } from '.';
import { businessAPI } from './businessAPI';
import { useSnackbar } from 'notistack';

export const useGetBusinessBalanceAPI = (id: UUID) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, isSuccess, error, data } = useQuery(
    ['balance', id],
    () => businessAPI.getBusinessBalance(id),
    {
      onError(err: AxiosError) {
        enqueueSnackbar(`Business balance: ${err.message} `, { variant: 'error' });
      },
      enabled: !!id,
    }
  );

  return { isLoading, isSuccess, error, data };
};

export const useGetSystemBalanceAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useQuery(
    'systemBalance',
    () => businessAPI.getSystemBalance(),
    {
      onError(err: AxiosError) {
        enqueueSnackbar(`System balance: ${err.message} `, { variant: 'error' });
      },
    }
  );

  return { isLoading, error, data };
};

export const useTopupBalanceAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess, mutate, reset } = useMutation(businessAPI.topupBalance, {
    onSuccess() {
      queryClient.invalidateQueries('balance');
      enqueueSnackbar(`Bones added successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Top up: ${err.message} `, { variant: 'error' });
    },
  });

  return { data, request: { isLoading, isSuccess }, topupBalance: mutate, reset };
};

export const useTopupUserBalanceAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess, mutate, reset } = useMutation(businessAPI.topupUserBalance, {
    onSuccess() {
      queryClient.invalidateQueries('user');
      enqueueSnackbar(`Bones added successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Top up: ${err.message} `, { variant: 'error' });
    },
  });

  return { data, isLoading, isSuccess, topupUserBalance: mutate, reset };
};
