import { history } from 'src/store';
import { Order } from './useDynamicTableActions';
import { useState } from 'react';
import { useLocation } from 'react-router';

export enum IParamsKeys {
  PAGE = 'page',
  ROWS_PER_PAGE = 'rowsperpage',
  ORDER = 'order',
  ORDER_BY = 'orderby',
  SEARCH_TEXT = 'searchtext',
}

export interface IParams {
  [IParamsKeys.PAGE]?: number | null;
  [IParamsKeys.ROWS_PER_PAGE]?: number | null;
  [IParamsKeys.ORDER]?: Order | null;
  [IParamsKeys.ORDER_BY]?: string | null;
  [IParamsKeys.SEARCH_TEXT]?: string | null;
}

export const useSearchQuery = () => {
  const { search } = useLocation();
  const [params, setParams] = useState(() => getParamsFromLocation());
  const currentLocation = history.location;
  
  function getParamsFromLocation(): IParams {
    const queryParams = new URLSearchParams(search);

    return {
      [IParamsKeys.PAGE]: queryParams.get(IParamsKeys.PAGE)
        ? Number(queryParams.get(IParamsKeys.PAGE))
        : undefined,
      [IParamsKeys.ROWS_PER_PAGE]: queryParams.get(IParamsKeys.ROWS_PER_PAGE)
        ? Number(queryParams.get(IParamsKeys.ROWS_PER_PAGE))
        : undefined,
      [IParamsKeys.ORDER]: queryParams.get(IParamsKeys.ORDER)
        ? (queryParams.get(IParamsKeys.ORDER) as Order)
        : undefined,
      [IParamsKeys.ORDER_BY]: queryParams.get(IParamsKeys.ORDER_BY)
        ? queryParams.get(IParamsKeys.ORDER_BY)
        : undefined,
      [IParamsKeys.SEARCH_TEXT]: queryParams.get(IParamsKeys.SEARCH_TEXT)
        ? queryParams.get(IParamsKeys.SEARCH_TEXT)
        : undefined,
    };
  }

  function onParamsChange(changedParams: IParams) {
    const queryObject: Record<string, string> = {};
    const mergedParams = {
      ...params,
      ...changedParams,
    };

    setParams(mergedParams);
    const keys = Object.keys(mergedParams);

    keys.forEach((key) => {
      if (
        mergedParams[key as IParamsKeys] === null ||
        mergedParams[key as IParamsKeys] === undefined
      ) {
        return;
      }

      queryObject[key] = String(mergedParams[key as IParamsKeys]);
    });

    const queryParams = new URLSearchParams(queryObject).toString();

    const newLocation = {
      ...currentLocation,
      pathname: window.location.pathname,
      search: queryParams,
    };
    
    history.replace(newLocation);
  }

  return {
    params,
    onParamsChange,
  };
};
