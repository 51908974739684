import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { businessAPI, UUID } from './index';
import { useSnackbar } from '../components/snackbar';
import { useDispatch } from 'src/store';
import { setOfferDetails } from 'src/store/actions/offers';
import { IGetSortParams } from './businessAPI';

export const useGetAPI = (id?: UUID) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, isSuccess, error, data, remove } = useQuery(
    ['offer', id],
    () => businessAPI.getOfferDetails(id),
    {
      onSuccess(data) {
        dispatch(setOfferDetails(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Offers: ${err.message} `, { variant: 'error' });
      },
      enabled: !!id,
    }
  );

  return { isLoading, isSuccess, error, data, remove };
};

export const useGetDiscountsAPI = ({ id, params }: { id?: UUID; params?: IGetSortParams }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, isSuccess, error, data } = useQuery(
    ['discounts', id, params],
    () => businessAPI.getDiscountCodes({ id, params }),
    {
      onError(err: AxiosError) {
        enqueueSnackbar(`Offers: ${err.message} `, { variant: 'error' });
      },
      enabled: !!id,
    }
  );

  return { isLoading, isSuccess, error, discountsData: data };
};

export const useMutateAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isLoading, isSuccess, mutate, reset } = useMutation(businessAPI.updateOffer, {
    onSuccess() {
      queryClient.invalidateQueries('offer');
      queryClient.invalidateQueries('offers');
      enqueueSnackbar(`Offer updated successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Offer: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, updateOffer: mutate, reset };
};
