import { UUID } from 'src/api';

export const SET_PINS_LOCATIONS = 'SET_PINS_LOCATIONS';

export enum EPinLocationStatus {
  POLLING = 'POLLING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  DECLINED_BY_COMPLAINT = 'DECLINED_BY_COMPLAINT',
  APPROVED_BY_ADMIN = 'APPROVED_BY_ADMIN',
}

export enum EType {
  CAFE = 'CAFE',
  BAR = 'BAR',
  RESTAURANT = 'RESTAURANT',
  PARK = 'PARK',
  HOTEL = 'HOTEL',
  SUPERMARKET = 'SUPERMARKET',
  PET_SHOP = 'PET_SHOP',
  VET = 'VET',
  GROOMER = 'GROOMER',
  BEACH = 'BEACH',
  OTHER = 'OTHER',
}

export enum EVoteResult {
  NOT_FRIENDLY = 'NOT_FRIENDLY',
  NO_SUCH_PLACE = 'NO_SUCH_PLACE',
  DOG_FRIENDLY = 'DOG_FRIENDLY',
}

export enum EComplaint {
  NO_LONGER_DOG_FRIENDLY = 'NO_LONGER_DOG_FRIENDLY',
  NOT_EXISTS = 'NOT_EXISTS',
  DUPLICATE = 'DUPLICATE',
  ABUSIVE_INFO = 'ABUSIVE_INFO',
  OTHER = 'OTHER',
}

export interface IPinLocation {
  id: UUID;
  name: string;
  status: EPinLocationStatus;
  type: EType;
  voteResult: EVoteResult;
  complaintStatus: EComplaint;
  createdDate: Date;
  createdBy: {
    id: UUID;
    name: string;
    image: string;
  };
  address: string;
  phone: string;
  notes: string;
  lat: number;
  lng: number;
}

export interface PinsState {
  pinsLocations: IPinLocation[];
  totalPages: number;
  totalElements: number;
}

const initialState: PinsState = {
  pinsLocations: [],
  totalPages: 0,
  totalElements: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const pinsLocationsReducer = (state = initialState, action: any): PinsState => {
  switch (action.type) {
    case SET_PINS_LOCATIONS:
      return {
        pinsLocations: action.payload.content,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
};

export const setPinsLocations = (pinsData: IPinLocation[]) => ({
  type: SET_PINS_LOCATIONS,
  payload: pinsData,
});

export default pinsLocationsReducer;
