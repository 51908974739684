import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { getRegisterInProgress } from 'src/Keycloak';
import { Box } from '@mui/material';

import Show from '../components/Show/Show';
import Logo from 'src/components/logo/Logo';
import LoginLayout from 'src/layouts/login/LoginLayout';
import Register from '../components/RegisterSteps/Register';
import VerifyAccount from '../components/RegisterSteps/VerifyAccount';
import RegisterBusinessInfo from '../components/RegisterSteps/RegisterBusinessInfo';

const BusinessManagerRegister = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (getRegisterInProgress()) {
      setActiveStep(2);
    }
  }, []);

  return (
    <>
      <LoginLayout>
        <Helmet>
          <title>Register business| LEV</title>
        </Helmet>
        <Logo sx={{ width: 144, height: 36 }} />
        <Box sx={{ maxWidth: 374, m: '48px auto 0' }}>
          <Show condition={activeStep === 0}>
            <Register setActiveStep={setActiveStep} setEmail={setEmail} />
          </Show>
          <Show condition={activeStep === 1}>
            <VerifyAccount setActiveStep={setActiveStep} email={email} />
          </Show>
          <Show condition={activeStep === 2}>
            <RegisterBusinessInfo />
          </Show>
        </Box>
      </LoginLayout>
    </>
  );
};

export default BusinessManagerRegister;
