export const getRegisterInProgress = () => localStorage.getItem('registerInProgress');
export const checkTokenValidity = (exp: number) => new Date().getTime() / 1000 < exp;

export const clearTokens = () => {
  localStorage.removeItem('keycloakToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('token');
};

export const setTokens = (token: string, refreshToken: string) => {
  localStorage.setItem('keycloakToken', token);
  localStorage.setItem('refreshToken', refreshToken);
};
