import axios from 'axios';

import { authAPI } from './authAPI';
import { usersAPI } from './usersAPI';
import { businessAPI } from './businessAPI';
import { clearTokens, setTokens } from 'src/Keycloak';

export type UUID = string;

export const getTokens = () => {
  const { keycloakToken, refreshToken } = localStorage;
  if (keycloakToken === null) return undefined;
  return { keycloakToken, refreshToken };
};

export const getAuthorizationHeader = () => `Bearer ${getTokens()?.keycloakToken}`;

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  (request) => {
    if (request?.headers) {
      request.headers.Authorization = getAuthorizationHeader();
    }
    return request;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && originalRequest) {
      try {
        const data = await authAPI.refreshToken(getTokens()?.refreshToken);
        setTokens(data?.data?.access_token, data?.data?.refresh_token);
        return await instance({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${data?.data?.access_token}`,
          },
          sent: true,
        });
      } catch (error) {
        // for some time
        // clearTokens();
      }
    }
    return Promise.reject(error);
  }
);

export { usersAPI, businessAPI };
