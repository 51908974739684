import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { createBrowserHistory } from 'history';
import { AnyAction, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import {
  usersReducer,
  userDetailInfoReducer,
  accountReducer,
  businessesReducer,
  businessDetailInfoReducer,
  batchesReducer,
  batchDetailInfoReducer,
  offersReducer,
  businessActiveTabReducer,
  offerDetailInfoReducer,
  transactionsReducer,
  statisticsReducer,
  locationsReducer,
  pinsLocationsReducer,
  campaignsReducer,
  campaignDetailsReducer,
} from './reducers';
import marketplacePerformanceOffersReducer from './reducers/marketplacePerformance';

export const USER_LOGOUT = 'USER_LOGOUT';

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const history = createBrowserHistory();

const appReducer = combineReducers({
  router: connectRouter(history),
  users: usersReducer,
  account: accountReducer,
  businesses: businessesReducer,
  userDetailInfo: userDetailInfoReducer,
  businessDetailInfo: businessDetailInfoReducer,
  batches: batchesReducer,
  batchDetailInfo: batchDetailInfoReducer,
  offers: offersReducer,
  businessActiveTab: businessActiveTabReducer,
  offerDetailInfo: offerDetailInfoReducer,
  transactions: transactionsReducer,
  statistics: statisticsReducer,
  locations: locationsReducer,
  pinsLocations: pinsLocationsReducer,
  campaigns: campaignsReducer,
  campaignDetails: campaignDetailsReducer,
  marketplacePerformanceOffers: marketplacePerformanceOffersReducer
});

const createRootReducer = () => (state: any, action: any) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: createRootReducer(),
  middleware: [thunk, routerMiddleware(history)],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

const useDispatch = () => useAppDispatch<TypedDispatch>();

const { dispatch } = store;

export { store, dispatch, useSelector, useDispatch };
