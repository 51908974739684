import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StyledTextField } from 'src/pages/components/RegisterSteps/Register';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

export interface IDateRangePicker {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  setStartDate: (value: Dayjs | null) => void;
  setEndDate: (value: Dayjs | null) => void;
}

function DateRangePicker({ startDate, endDate, setStartDate, setEndDate }: IDateRangePicker) {
  const onDateChange = (startDate: Dayjs | null, endDate: Dayjs | null) => {
    if (!startDate?.isValid() || !endDate?.isValid()) {
      return;
    }

    if(startDate?.isAfter(endDate)) {
      setEndDate(startDate)
      setStartDate(startDate)

      return;
    }

    setStartDate(startDate)
    setEndDate(endDate);
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display={'flex'} gap={10} mb={2}>
          <DesktopDatePicker
            label="Start date"
            inputFormat="MM/DD/YYYY"
            value={startDate}
            onChange={(newValue) => onDateChange(newValue, endDate)}
            renderInput={(params) => <StyledTextField required {...params} />}
            maxDate={dayjs()}
          />
          <DesktopDatePicker

            label="End date"
            inputFormat="MM/DD/YYYY"
            value={endDate}
            onChange={(newValue) => onDateChange(startDate, newValue)}
            renderInput={(params) => <StyledTextField required disabled {...params} />}
            minDate={startDate}
            maxDate={dayjs()}
          />
        </Box>
      </LocalizationProvider>
    </>
  );
}

export default DateRangePicker;
