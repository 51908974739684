export const fieldError = (errors: any, name: string) => !!errors?.[name];

export const fieldHelpertext = (errors: any, name: string) =>
  errors?.[name] ? errors?.[name].message : '';

export const acceptOnlyNumbers = (event: any) => {
  if (event.key.length === 1 && /\D/.test(event.key)) {
    event.preventDefault();
  }
};

export const isValidHttpUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getOrNull = (value: any, all: string) => (value === all ? undefined : value);

export const fileCreator = (blob: Blob, name: string, type: string) =>
  new File([blob], name, { type: type });
