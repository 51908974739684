import { useSnackbar } from 'src/components/snackbar';
import { IMarketplaceperformanceParams, businessAPI } from './businessAPI';
import { useQuery } from 'react-query';
import { dispatch } from 'src/store';
import { AxiosError } from 'axios';
import { setMarketplacePerformanceOffers } from 'src/store/actions/marketplacePerformance';

export const useGetMarketPerformanceOffersAPI = (params: IMarketplaceperformanceParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, error, data } = useQuery(
      ['marketplacePerformance', params],
      () => businessAPI.getMarketPlacePerformance(params),
      {
        onSuccess(data) {
          dispatch(setMarketplacePerformanceOffers(data.data));
        },
        onError(err: AxiosError) {
          enqueueSnackbar(`Offers: ${err.message}`, { variant: 'error' });
        },
      }
    );

  return { isLoading, error, data };
};
