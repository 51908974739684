import dayjs from 'dayjs';
import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Tooltip as MuiToolTip,
  useTheme,
} from '@mui/material';
import { ALL } from 'src/constants';
import { useSelector } from 'src/store';
import { EDayPeriod } from 'src/api/businessAPI';
import { getOrNull } from 'src/helpers/formHeleprs';
import { StyledMenuItem } from './PurchasedOffersStats';
import { IGeneralStatistics } from './GeneralStatistics';
import { IconButtonAnimate } from 'src/components/animate';
import { useGetCollectedBonesStatsAPI } from 'src/api/statistics';
import { StyledTextField } from 'src/pages/components/RegisterSteps/Register';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import CustomTooltip from './CustomToolTip';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const DAY_PERIODS = [
  { label: 'Night', value: EDayPeriod.NIGHT },
  { label: 'Morning', value: EDayPeriod.MORNING },
  { label: 'Day', value: EDayPeriod.DAY },
  { label: 'Evening', value: EDayPeriod.EVENING },
  { label: 'All day', value: EDayPeriod.ALL_DAY },
];

const TITLE = (
  <Box sx={{ fontSize: 4 }}>
    <BusinessStyledTypograhpy sx={{ fontSize: 12 }}>
      Night: Bones collected between 23:00-5:00
    </BusinessStyledTypograhpy>
    <BusinessStyledTypograhpy sx={{ fontSize: 12 }}>
      Morning: Bones collected between 5:00-11:00
    </BusinessStyledTypograhpy>
    <BusinessStyledTypograhpy sx={{ fontSize: 12 }}>
      Day: Bones collected between 11:00-17:00
    </BusinessStyledTypograhpy>
    <BusinessStyledTypograhpy sx={{ fontSize: 12 }}>
      Evening: Bones collected between 17:00-23:00
    </BusinessStyledTypograhpy>
  </Box>
);

const CollectedBonesStats = ({
  startDate,
  endDate,
  timezone,
  groupBy,
  businessId,
}: IGeneralStatistics) => {
  const theme = useTheme();
  const [locationId, setLocationId] = useState(ALL);
  const [byUserUniq, setByUserUniq] = useState<boolean>(false);
  const [dayPeriod, setDayPeriod] = useState<EDayPeriod>(EDayPeriod.ALL_DAY);

  const locations = useSelector((state) => state.locations?.locations);
  const collectedBones = useSelector((state) => state.statistics.collectedBones);

  useGetCollectedBonesStatsAPI({
    locationId: getOrNull(locationId, ALL),
    startDate: startDate || dayjs().subtract(7, 'day'),
    endDate: endDate || dayjs(),
    groupBy,
    timezone,
    dayPeriod,
    businessId,
    byUserUniq,
  });

  return (
    <Box>
      <Box display={'flex'} gap={2}>
        <BusinessStyledTypograhpy fontSize={'26px'}>
          Ads viewed:{' '}
          <Typography
            fontFamily={'CircularStd'}
            fontSize={'26px'}
            component="span"
            color={theme.palette.primary.main}
          >
            {collectedBones?.totalBones}
          </Typography>
        </BusinessStyledTypograhpy>
        <MuiToolTip title={TITLE}>
          <IconButtonAnimate>
            <QuestionMarkIcon />
          </IconButtonAnimate>
        </MuiToolTip>
        <FormControlLabel
          sx={{ '& label': { fontFamily: 'CircularStd' } }}
          control={<Checkbox checked={byUserUniq} onChange={() => setByUserUniq(!byUserUniq)} />}
          label={<BusinessStyledTypograhpy children={'Unique users'} />}
        />
      </Box>
      <Box display={'flex'} gap={7}>
        <StyledTextField
          select
          sx={{ width: '10rem', mt: 2 }}
          label="Day period"
          defaultValue={dayPeriod}
          disabled={byUserUniq}
          onChange={(e) => setDayPeriod(e.target.value as EDayPeriod)}
        >
          {DAY_PERIODS.map(({ value, label }) => (
            <StyledMenuItem key={value} value={value}>
              {label}
            </StyledMenuItem>
          ))}
        </StyledTextField>
        <StyledTextField
          select
          sx={{ width: '10rem', mt: 2 }}
          label="Location"
          defaultValue={locationId}
          onChange={(e) => setLocationId(e.target.value)}
        >
          <StyledMenuItem value="ALL" selected={true}>
            All
          </StyledMenuItem>
          {locations?.map(({ id, title }) => (
            <StyledMenuItem key={id} value={id}>
              {title}
            </StyledMenuItem>
          ))}
        </StyledTextField>
      </Box>
      <BarChart
        width={650}
        height={500}
        data={collectedBones?.collectedBones}
        margin={{
          top: 20,
          right: 0,
          left: -20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} cursor={{ fillOpacity: '25%' }} />
        <Legend />
        <Bar dataKey="morning" stackId="a" fill="#F7AB00" />
        <Bar dataKey="day" stackId="a" fill="#4BBDE3" />
        <Bar dataKey="evening" stackId="a" fill="#3EAB54" />
        <Bar dataKey="night" stackId="a" fill="#C4CDD5" />
        <Bar name="all day" dataKey="allDay" stackId="a" fill="#078DEE" />
      </BarChart>
    </Box>
  );
};

export default CollectedBonesStats;
