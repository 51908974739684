import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Divider, Tab, Typography, useTheme } from '@mui/material';

import { UUID } from 'src/api';
import { useGetAPI } from 'src/api/businessDetails';
import { LOCATION_STATUSES } from 'src/api/businessAPI';
import { IconButtonAnimate } from 'src/components/animate';
import { setActiveTab } from 'src/store/reducers/businessDetailTabs';
import { history, RootState, useDispatch, useSelector } from 'src/store';
import { useGetBusinessBalanceAPI, useTopupBalanceAPI } from 'src/api/balance';
import { UpdateLocationSchema, CreateLocationSchema, TopupSchema } from 'src/utils/validators';
import { useMutateAPI, useUpdateAPI, useGetAPI as useGetLocationsAPI } from 'src/api/location';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Show from '../Show/Show';
import BusinessCard from './BusinessCard';
import Offers from './TabsContent/Offers';
import Locations from './TabsContent/Locations';
import BatchOfBones from './TabsContent/BatchOfBones';
import Statistics from './TabsContent/Statistics/Statistics';
import LocationsModal from 'src/components/modal/LocationsModal';
import TopupBonesBalanceModal from 'src/components/modal/TopupBonesBalanceModal';

export interface StyledTabListProps {
  children?: React.ReactNode;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export const StyledTabList = styled((props: StyledTabListProps) => (
  <TabList
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

interface StyledTabProps {
  label: string;
  value: string;
}

export const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(18),
  marginRight: theme.spacing(1),
}));

const DetailedBusinessDescription = () => {
  const [checkedLocId, setCheckedLocId] = useState<UUID | null>(null);
  const [locationId, setLocationId] = useState<UUID | null>(null);
  const [businessId, setBusinessId] = useState<UUID | null>(null);
  const [shouldAddBones, setShouldAddBones] = useState<boolean>(false);
  const [isCreateNewLocation, setIsCreateNewLocation] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();

  const activeTab = useSelector((state: RootState) => state.businessActiveTab);

  const { id = '' } = useParams();
  const businessDetailInfo = useSelector((state: RootState) => state.businessDetailInfo);

  useGetAPI({ id });
  const { refetch } = useGetLocationsAPI({ businessId: id, status: LOCATION_STATUSES.ACTIVE });

  const { data } = useGetBusinessBalanceAPI(id);

  const { isLoading, isSuccess, createLocation } = useMutateAPI();
  const { request, topupBalance, reset } = useTopupBalanceAPI();
  const { isLoading: loading, isSuccess: success, updateLocation } = useUpdateAPI();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (!isCreateNewLocation) {
        setBusinessId(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  useEffect(() => {
    !loading && success && setLocationId('');
  }, [loading, success]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setActiveTab(newValue));
  };
  return (
    <>
      <Helmet>
        <title>Business Detailed View | LEV</title>
      </Helmet>
      <Box sx={{ width: '100%', padding: '0 20px', display: 'flex', flexWrap: 'nowrap' }}>
        <Box marginRight={1}>
          <IconButtonAnimate color="primary" onClick={() => history.back()} sx={{ p: 1.25 }}>
            <ArrowBackIcon />
          </IconButtonAnimate>
        </Box>
        <Box flexGrow={1}>
          <BusinessCard
            id={id}
            userEmail={businessDetailInfo.userEmail}
            name={businessDetailInfo?.name}
            description={businessDetailInfo?.description}
            addresses={businessDetailInfo.addresses}
          />
          <Box mt={2}>
            <Typography variant="h4">
              Total bones purchased:{' '}
              <Typography
                color={theme.palette.primary.main}
                display={'inline-block'}
                component={'p'}
                variant="h4"
              >
                {data?.data?.totalPurchasedBones || 0}
              </Typography>
            </Typography>
          </Box>
          <Box mt={2} maxWidth={480} display={'flex'} justifyContent={'space-between'}>
            <Typography variant="h4">
              Current bones balance:{' '}
              <Typography
                color={theme.palette.primary.main}
                display={'inline-block'}
                component={'p'}
                variant="h4"
              >
                {data?.data?.balance || 0}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{ mb: '1rem' }}
              onClick={() => setShouldAddBones(!shouldAddBones)}
            >
              Purchase bones
            </Button>
          </Box>
          <Divider sx={{ mt: 4 }} />
          <TabContext value={activeTab}>
            <Box sx={{ width: '100%', pt: 2 }}>
              <Box>
                <StyledTabList onChange={handleChange}>
                  <StyledTab label="Locations" value="1" />
                  <StyledTab label="Ad campaigns" value="2" />
                  <StyledTab label="Offers" value="3" />
                  <StyledTab label="Statistics" value="4" />
                  <StyledTab label="Deleted locations" value="5" />
                </StyledTabList>
              </Box>
              <TabPanel value="1">
                <Locations
                  businessId={id}
                  checkedLocId={checkedLocId}
                  status={LOCATION_STATUSES.ACTIVE}
                  setBusinessId={setBusinessId}
                  setLocationId={setLocationId}
                  setCheckedLocId={setCheckedLocId}
                />
              </TabPanel>
              <TabPanel value="2">
                <BatchOfBones
                  locations={businessDetailInfo?.locations}
                  businessId={id}
                  balance={data?.data?.balance}
                />
              </TabPanel>
              <TabPanel value="3">
                <Offers businessId={id} />
              </TabPanel>
              <TabPanel value="4">
                <Statistics businessId={id} />
              </TabPanel>
              <TabPanel value="5">
                <Locations businessId={id} status={LOCATION_STATUSES.ARCHIVED} />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Box>
      <Show condition={!!businessId}>
        <LocationsModal
          businessId={id}
          openId={businessId}
          isLoading={isLoading}
          isSuccess={isSuccess}
          label={'Add new'}
          schema={UpdateLocationSchema}
          businessCity={businessDetailInfo?.addresses?.[0]?.city}
          setOpenId={setBusinessId}
          mutate={createLocation}
          setIsCreateNewLocation={setIsCreateNewLocation}
          isCreateNewLocation={isCreateNewLocation}
          refetchLocations={refetch}
        />
      </Show>
      <Show condition={!!locationId}>
        <LocationsModal
          businessId={id}
          data={businessDetailInfo?.locations?.find((location) => location?.id === locationId)}
          openId={locationId}
          isLoading={loading}
          label={'Update'}
          schema={CreateLocationSchema}
          setOpenId={setLocationId}
          setCheckedLocId={setCheckedLocId}
          mutate={updateLocation}
          refetchLocations={refetch}
        />
      </Show>
      <Show condition={shouldAddBones}>
        <TopupBonesBalanceModal
          id={id}
          open={shouldAddBones}
          schema={TopupSchema}
          isLoading={request.isLoading}
          isSuccess={request.isSuccess}
          mutate={topupBalance}
          resetMutation={reset}
          setIsOpen={setShouldAddBones}
        />
      </Show>
    </>
  );
};
export default DetailedBusinessDescription;
