import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';

import { UUID } from 'src/api';
import { percentage } from 'src/helpers';
import { RootState, useSelector } from 'src/store';
import { useMutateAPI } from 'src/api/batchDetails';
import { useSnackbar } from 'src/components/snackbar';
import { LocationData } from 'src/store/actions/businesses';
import { useGetAPI as useGetOffersAPI } from 'src/api/offers';
import { useDynamicTableActions } from 'src/hooks/useDynamicTableActions';
import { useGetAPI, useStartBatchAPI, useStopBatchAPI } from 'src/api/batchBones';
import { BATCHES_HEAD_CELLS, OFFER_ALL_STATUSES, BATCH_STATUSES } from 'src/constants';
import { BatchMessageSchema, BatchNotesSchema, BatchPromoTextSchema } from 'src/utils/validators';

import Show from '../../Show/Show';
import CreateBatch from './CreateBatch';
import OneFieldModal from 'src/components/modal/OneFieldModal';
import DynamicTableHead from '../../DynamicTableHead';
import DynamicTablePagination from '../../DynamicTablePagination';

export const BATCH_STATUS_VALUES: any = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In progress',
  STOPPED: 'Stopped',
  FINISHED: 'Finished',
  WAITING_FOR_START: 'Scheduled',
};

interface IBatchOfBones {
  businessId: UUID;
  balance: number;
  locations: LocationData[];
}

const BatchOfBones = ({ businessId, balance, locations }: IBatchOfBones) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [notesModalId, setNotesModalId] = useState<string | null>(null);
  const [messageModalId, setMessageModalId] = useState<string | null>(null);
  const [promoTextModalId, setPromoTextModalId] = useState<string | null>(null);

  const batchesData = useSelector((state: RootState) => state.batches);
  const offers = useSelector((state: RootState) => state.offers.offers);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    sort,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
  } = useDynamicTableActions('audit.createdDate');

  useGetAPI({ businessId, page, size: rowsPerPage, sort });
  useGetOffersAPI({ size: 100, businessId });

  const { isLoading, isSuccess, updateBatch, variables } = useMutateAPI(setPromoTextModalId);
  const { startBatch } = useStartBatchAPI();
  const { stopBatch } = useStopBatchAPI();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (notesModalId) {
        setNotesModalId('');
      } else if (messageModalId) {
        setMessageModalId('');
      } else if (promoTextModalId && !variables?.params?.offerId) {
        setPromoTextModalId('');
      }
      // may be should be used later
      // if (!notesModalId && !messageModalId) {
      //   setNotesModalId(currentBatchId);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  const handleStartBatch = (id: UUID) => {
    startBatch(id);
  };

  const handleStopBatch = (id: UUID) => {
    stopBatch(id);
  };

  const handleOfferChange = (event: React.ChangeEvent<HTMLInputElement>, id: UUID) => {
    const { value } = event.target;
    const offerId = value === 'NONE' ? null : value;
    updateBatch({ id, params: { offerId } });
  };

  return (
    <Show
      condition={!isCreate}
      or={<CreateBatch locations={locations} setIsCreate={setIsCreate} businessId={businessId} />}
    >
      <Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4" component="h1" paragraph>
              Ad campaigns: {batchesData?.totalElements}
            </Typography>
            <Button
              variant="contained"
              sx={{ mb: '1rem' }}
              onClick={() => {
                if (balance < 50) {
                  enqueueSnackbar('You should add bones to your balance first', {
                    variant: 'error',
                  });
                  return;
                }
                setIsCreate(!isCreate);
              }}
            >
              Add new
            </Button>
          </Box>
          <Show
            condition={!!batchesData?.batches?.length}
            or={
              <Typography variant="h4" component="h1" paragraph>
                There are no batches
              </Typography>
            }
          >
            <Paper sx={{ width: '100%', overflow: 'hidden', mb: 2 }}>
              <TableContainer
                component={Paper}
                sx={{
                  height: 'min-content',
                  '&::-webkit-scrollbar:vertical': {
                    display: 'none',
                  },

                  '&::-webkit-scrollbar': {
                    height: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(145, 158, 171, 0.16)',
                    borderRadius: '12px',
                  },
                }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                  }}
                  stickyHeader
                  aria-label="sticky table"
                  size={'medium'}
                >
                  <DynamicTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={onRequestSort}
                    rowsCount={batchesData?.batches?.length}
                    headCells={BATCHES_HEAD_CELLS}
                  />
                  <TableBody>
                    {batchesData?.batches?.map((batch) => (
                      <TableRow
                        hover
                        onClick={() => {}}
                        tabIndex={-1}
                        key={batch?.id}
                        sx={{
                          '& td': {
                            padding: '16px 8px',
                          },
                        }}
                      >
                        <TableCell align="center">{batch?.displayId || '-'}</TableCell>
                        <TableCell
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            minWidth: 120,
                            color: !Object.keys(batch?.location)?.length ? 'red' : '',
                          }}
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {Object.keys(batch?.location)?.length ? batch?.location?.name : 'deleted'}
                        </TableCell>
                        <TableCell align="center">{batch?.totalBones || '-'}</TableCell>
                        <TableCell align="center">{batch?.bonesLeft || '-'}</TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            minWidth: 140,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              display: '-webkit-box !important',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {batch?.promoText}
                          </Typography>
                          <Button
                            sx={{ minWidth: 0 }}
                            title="edit promo text"
                            onClick={() => setPromoTextModalId(batch?.id)}
                          >
                            <Edit fontSize="small" />
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box sx={{ width: '100%', mr: 1 }}>
                              <LinearProgress
                                sx={{ height: 10 }}
                                variant="determinate"
                                value={percentage(batch?.totalBones, batch?.bonesLeft)}
                              />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                              <Typography variant="body2" color="text.secondary">
                                {`${Math.trunc(percentage(batch?.totalBones, batch?.bonesLeft))}%`}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            minWidth: 180,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              display: '-webkit-box !important',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {batch?.message}
                          </Typography>
                          <Button
                            sx={{ minWidth: 0 }}
                            title="add/edit note"
                            onClick={() => setMessageModalId(batch?.id)}
                          >
                            <Edit fontSize="small" />
                          </Button>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            minWidth: 170,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'baseline',
                              justifyContent: 'space-around',
                            }}
                          >
                            {BATCH_STATUS_VALUES[batch?.status || '']}
                            <Show condition={batch?.status === BATCH_STATUSES.STOPPED}>
                              <>
                                <Divider orientation="vertical" flexItem />
                                <Button
                                  variant="contained"
                                  sx={{
                                    display: 'inline-block',
                                    padding: 0.02,
                                    minWidth: '48px',
                                  }}
                                  onClick={() => handleStartBatch(batch?.id)}
                                >
                                  Start
                                </Button>
                              </>
                            </Show>
                            <Show
                              condition={[
                                BATCH_STATUSES.PENDING,
                                BATCH_STATUSES.IN_PROGRESS,
                              ].includes(batch?.status)}
                            >
                              <>
                                <Divider orientation="vertical" flexItem />
                                <Button
                                  variant="contained"
                                  sx={{
                                    display: 'inline-block',
                                    padding: 0.02,
                                    minWidth: '48px',
                                  }}
                                  onClick={() => handleStopBatch(batch?.id)}
                                >
                                  Stop
                                </Button>
                              </>
                            </Show>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            select
                            value={batch?.offer?.id || 'NONE'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                              handleOfferChange(e, batch?.id)
                            }
                            variant="standard"
                            color="warning"
                            sx={{
                              '& .MuiSelect-select': {
                                overflow: 'hidden',
                                color:
                                  batch?.offer &&
                                  [
                                    OFFER_ALL_STATUSES.EMPTY,
                                    OFFER_ALL_STATUSES.PAUSED,
                                    OFFER_ALL_STATUSES.NOT_ACTIVE,
                                  ].includes(batch?.offer?.status)
                                    ? 'red'
                                    : '',
                              },
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            SelectProps={{
                              MenuProps: { disableScrollLock: true, sx: { maxHeight: 400 } },
                            }}
                          >
                            <MenuItem key={'NONE'} value="NONE">
                              None
                            </MenuItem>
                            {offers?.map(({ id, title, isUseInBatchBones, status }) => (
                              <MenuItem
                                key={id}
                                value={id}
                                sx={{
                                  color:
                                    [
                                      OFFER_ALL_STATUSES.EMPTY,
                                      OFFER_ALL_STATUSES.PAUSED,
                                      OFFER_ALL_STATUSES.NOT_ACTIVE,
                                    ].includes(status) && isUseInBatchBones
                                      ? 'red'
                                      : '',
                                }}
                              >
                                {title}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            position: 'relative',
                          }}
                        >
                          <Typography
                            sx={{
                              maxWidth: 250,
                              overflow: 'hidden',
                              display: '-webkit-box !important',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical',
                              marginRight: '20px',
                            }}
                          >
                            {batch?.notes || '-'}
                          </Typography>
                          <Button
                            sx={{
                              minWidth: 0,
                              position: 'absolute',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              right: '-12px',
                            }}
                            title="add/edit note"
                            onClick={() => setNotesModalId(batch?.id)}
                          >
                            <Add fontSize="small" />
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          {new Date(batch?.createdDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="center">
                          {batch?.finishedDate
                            ? new Date(batch?.finishedDate).toLocaleDateString()
                            : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
              <DynamicTablePagination
                page={page}
                rowsPerPage={rowsPerPage}
                totalPages={batchesData?.totalPages}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
              />
            </Paper>
          </Show>
        </Box>
        {messageModalId && (
          <OneFieldModal
            data={batchesData?.batches?.find((batch) => batch?.id === messageModalId)?.message}
            open={messageModalId}
            setOpened={setMessageModalId}
            label={'Message'}
            field={'message'}
            schema={BatchMessageSchema}
            update={updateBatch}
            isLoading={isLoading}
          />
        )}
        {notesModalId && (
          <OneFieldModal
            data={batchesData?.batches?.find((batch) => batch?.id === notesModalId)?.notes}
            open={notesModalId}
            setOpened={setNotesModalId}
            label={'Notes'}
            field={'notes'}
            schema={BatchNotesSchema}
            update={updateBatch}
            isLoading={isLoading}
          />
        )}
        {promoTextModalId && (
          <OneFieldModal
            data={batchesData?.batches?.find((batch) => batch?.id === promoTextModalId)?.promoText}
            open={promoTextModalId}
            setOpened={setPromoTextModalId}
            label={'Promotional text'}
            field={'promoText'}
            schema={BatchPromoTextSchema}
            update={updateBatch}
            isLoading={isLoading}
          />
        )}
      </Box>
    </Show>
  );
};

export default BatchOfBones;
