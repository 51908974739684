const SET_COLLECTED_BONES_STATS = 'SET_COLLECTED_BONES_STATS';
const SET_PURCHASED_OFFERS_STATS = 'SET_PURCHASED_OFFERS_STATS';
const SET_OPENED_OFFERS_STATS = 'SET_OPENED_OFFERS_STATS';
const SET_ACTIVATED_OFFERS_STATS = 'SET_ACTIVATED_OFFERS_STATS';

export const setCollectedBonesStats = (data: any) => ({
  type: SET_COLLECTED_BONES_STATS,
  data,
});

export const setPurchasedOffersStats = (data: any) => ({
  type: SET_PURCHASED_OFFERS_STATS,
  data,
});

export const setOpenedOffersStats = (data: any) => ({
  type: SET_OPENED_OFFERS_STATS,
  data,
});

export const setActivatedOffersStats = (data: any) => ({
  type: SET_ACTIVATED_OFFERS_STATS,
  data,
});

interface IStats {
  collectedBones: {
    totalBones: number;
    collectedBones: {
      name: string;
      morning: number;
      day: number;
      evening: number;
      night: number;
    }[];
  };
  purchasedOffers: {
    totalOffers: number;
    purchasedOffers: {
      name: string;
      totalOffers: number;
    }[];
  };
  openedOffers: {
    totalOffers: number;
    openedOffers: {
      name: string;
      totalOffers: number;
    }[];
  };
  activatedOffers: {
    totalOffers: number;

    activatedOffers: {
      name: string;
      totalOffers: number;
    }[];
  };
}

const initialState: IStats = {
  collectedBones: {
    totalBones: 0,
    collectedBones: [],
  },
  purchasedOffers: {
    totalOffers: 0,
    purchasedOffers: [],
  },
  openedOffers: {
    totalOffers: 0,
    openedOffers: [],
  },
  activatedOffers: {
    totalOffers: 0,
    activatedOffers: [],
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const StatisticsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_COLLECTED_BONES_STATS:
      return {
        ...state,
        collectedBones: {
          ...action.data,
        },
      };
    case SET_PURCHASED_OFFERS_STATS:
      return {
        ...state,
        purchasedOffers: {
          ...action.data,
        },
      };
    case SET_OPENED_OFFERS_STATS:
      return {
        ...state,
        openedOffers: {
          ...action.data,
        },
      };
    case SET_ACTIVATED_OFFERS_STATS:
      return {
        ...state,
        activatedOffers: {
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export default StatisticsReducer;
