import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import { useDispatch } from 'src/store';
import { useSnackbar } from 'notistack';
import { businessAPI, IGetSortParams } from './businessAPI';
import { setBusinesses } from 'src/store/actions/businesses';

const DEFAULT_PAGE_SIZE = 10;

export const useGetAPI = ({ page, size, sort, enabled = true, querySearch }: IGetSortParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess, error, refetch } = useQuery(
    ['businesses', page, size, sort, querySearch],
    () => businessAPI.getBusinesses({ page, size: (size && size > 1) ? size : DEFAULT_PAGE_SIZE, sort, querySearch }),
    {
      onSuccess(data) {
        dispatch(setBusinesses(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Business list: ${err.message} `, { variant: 'error' });
      },
      refetchInterval: 10000,
      refetchOnMount: false,
      enabled,
    }
  );

  return { isLoading, isSuccess, error, data, refetch };
};

export const useGetBusinessAPI = ({ page, size, sort, enabled = true, querySearch }: IGetSortParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const { data, isLoading, isSuccess, error, refetch } = useQuery(
        ['localBusinesses', page, size, sort, querySearch],
        () => businessAPI.getBusinesses({ page, size, sort, querySearch }),
        {
            onError(err: AxiosError) {
                enqueueSnackbar(`Business list: ${err.message} `, { variant: 'error' });
            },
            enabled,
        }
    );

    return { isLoading, isSuccess, error, data, refetch };
};


export const useMutateAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isSuccess, mutate } = useMutation(businessAPI.createBusiness, {
    onSuccess() {
      enqueueSnackbar(`Business created successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Business create: ${err.message} `, { variant: 'error' });
    },
  });

  return { data, isLoading, isSuccess, createBusiness: mutate };
};
