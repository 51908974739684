import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
    Page404,
    Users,
    Businesses,
    Login,
    Treasury,
    RoleBusinessCampaignDetails,
    RoleBusinessOfferDetails,
    RoleBusinessAccount,
    RoleBusinessAnalytics,
    RoleBusinessCampaigns,
    RoleBusinessDashboard,
    RoleBusinessLocations,
    RoleBusinessOffers
} from './elements';

import { RootState, useSelector } from 'src/store';
import { ROLE_BUSINESS_MANAGER } from 'src/constants';

import BusinessManagerLogin from 'src/pages/BusinessManagerAuth/BusinessManagerLogin';
import BusinessManagerRegister from 'src/pages/BusinessManagerAuth/BusinessManagerRegister';
import BusinessTopup from 'src/pages/BusinessTopup/BusinessTopup';
import CustomLocations from 'src/pages/CustomLocations/CustomLocations';
import CreateBusiness from 'src/pages/components/BusinessDetails/CreateBusiness';
import DetailedUserDescription from 'src/pages/components/UserDetails/DetailedUserDescription';
import DetailedOffersDescription from 'src/pages/components/BusinessDetails/DetailedOffersDescription';
import DetailedBusinessDescription from 'src/pages/components/BusinessDetails/DetailedBusinessDescription';

import RoleBusinessWrapper from 'src/pages/RoleBusinessWrapper/RoleBusinessWrapper';
import MarketplacePerformance from 'src/pages/MarketplacePerformance/MarketplacePerformance';

type PageProps = {
    role: string | undefined
}


function RootPage(props: PageProps) {
    return <Navigate
        to={
            props.role === ROLE_BUSINESS_MANAGER ? 'dashboard/business/dashboard' : PATH_AFTER_LOGIN
        }
        replace
    />
}

function ManagerAdminLoginPage() {
    return (<GuestGuard><BusinessManagerLogin /></GuestGuard>)
}

function RegisterPage() {
    return (<GuestGuard> <BusinessManagerRegister /> </GuestGuard>);
}

function AdminLoginPage() {
    return (<GuestGuard><Login /></GuestGuard>)
}


function DashboardPage() {
    return (<AuthGuard><DashboardLayout /></AuthGuard>)
}

function RootDashboardPage(props: PageProps) {
    return (
        <Navigate to={props.role === ROLE_BUSINESS_MANAGER ? 'dashboard/business/dashboard' : PATH_AFTER_LOGIN} replace />
    )
}


export default function Router() {
    const role = useSelector((state: RootState) => state.account.role);

    const routes = [
        {
            path: '/',
            children: [
                {
                    element: <RootPage role={role} />,
                    index: true,
                },
                {
                    path: 'admin-login',
                    element: <AdminLoginPage />,
                },
                {
                    path: 'register',
                    element: <RegisterPage />,
                },
                {
                    path: 'manager-login',
                    element: <ManagerAdminLoginPage />,
                },
            ],
        },
        {
            path: '/dashboard',
            element: <DashboardPage />,
            children: [
                {
                    element: <RootDashboardPage role={role} />,
                    index: true,
                },
                { path: 'users', element: <Users /> },
                { path: 'treasure', element: <Treasury /> },
                { path: 'business-topup', element: <BusinessTopup /> },
                {
                    path: 'users/:id',
                    element: <DetailedUserDescription />,
                },
                { path: 'businesses', element: <Businesses /> },
                {
                    path: 'businesses/:id',
                    element: <DetailedBusinessDescription />,
                },
                { path: 'businesses/offers/:id', element: <DetailedOffersDescription /> },
                {
                    path: 'businesses/create',
                    element: <CreateBusiness />,
                },
                {
                    path: 'custom-locations',
                    element: <CustomLocations />,
                },
                {
                    path: 'marketplace-performance',
                    element: <MarketplacePerformance />
                },
                {
                    path: 'business/dashboard',
                    element: (
                        <RoleBusinessWrapper>
                            <RoleBusinessDashboard />
                        </RoleBusinessWrapper>
                    ),
                },
                {
                    path: 'business/campaigns',
                    element: (
                        <RoleBusinessWrapper>
                            <RoleBusinessCampaigns />
                        </RoleBusinessWrapper>
                    ),
                },
                {
                    path: 'business/campaigns/:id',
                    element: (
                        <RoleBusinessWrapper>
                            <RoleBusinessCampaignDetails />
                        </RoleBusinessWrapper>
                    ),
                },
                {
                    path: 'business/locations',
                    element: (
                        <RoleBusinessWrapper>
                            <RoleBusinessLocations />
                        </RoleBusinessWrapper>
                    ),
                },
                {
                    path: 'business/offers',
                    element: (
                        <RoleBusinessWrapper>
                            <RoleBusinessOffers />
                        </RoleBusinessWrapper>
                    ),
                },
                {
                    path: 'business/offers/:id',
                    element: (
                        <RoleBusinessWrapper>
                            <RoleBusinessOfferDetails />
                        </RoleBusinessWrapper>
                    ),
                },
                {
                    path: 'business/analytics',
                    element: (
                        <RoleBusinessWrapper>
                            <RoleBusinessAnalytics />
                        </RoleBusinessWrapper>
                    ),
                },
                {
                    path: 'business/account',
                    element: (
                        <RoleBusinessWrapper>
                            <RoleBusinessAccount />
                        </RoleBusinessWrapper>
                    ),
                },
            ],
        },
        {
            element: <CompactLayout />,
            children: [{ path: '404', element: <Page404 /> }],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]

    return useRoutes(routes);
}
