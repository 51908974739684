import { UUID } from 'src/api';
import { SET_USER_DETAILS } from '../actions/user';

export interface Dog {
  id: UUID;
  name: string;
  breed: string;
  gender: string;
  photo?: unknown;
  birthday?: string;
  weight?: string;
  characteristics?: string;
}
export interface DetailInfoState {
  name: string;
  email: string;
  totalBonesSpent: number;
  totalBones: number;
  created: string;
  lastActive: string;
  dogs: Dog[];
}

const initialState: DetailInfoState = {
  name: '',
  email: '',
  totalBonesSpent: 0,
  totalBones: 0,
  created: '',
  lastActive: '',
  dogs: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const userDetailInfoReducer = (state = initialState, action: any): DetailInfoState => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default userDetailInfoReducer;
