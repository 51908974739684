import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { instance, UUID } from '.';
import { useSnackbar } from '../components/snackbar';

export const useUploadFileAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate, isSuccess, reset } = useMutation(
    (file: FormData) =>
      instance.post(`files/offer-banners`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries('business');
        enqueueSnackbar(`Image uploaded`, { variant: 'success' });
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Image upload: ${err.message} `, { variant: 'error' });
      },
    }
  );

  return { fileData: data, isLoading, isSuccess, uploadFile: mutate, reset };
};

export const useUploadCsvAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate, isSuccess } = useMutation(
    ({ id, file }: { id?: UUID; file: FormData }) =>
      instance.post(`admin/offer/${id}/upload-discounts`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries('offer');
        queryClient.invalidateQueries('offers');
        queryClient.invalidateQueries('discounts');
        enqueueSnackbar(`Discount codes upload was successful`, { variant: 'success' });
      },
      onError(err: AxiosError) {
        enqueueSnackbar('Something went wrong. Please, check the document and repeat upload', {
          variant: 'error',
        });
      },
    }
  );

  return { csvData: data, isLoading, isSuccess, uploadCsvFile: mutate };
};
