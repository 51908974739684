import { UUID } from 'src/api';
import { EBatchStatuses } from './campaigns';

export const SET_BATCHES = 'SET_BATCHES';
export const SET_BATCH_DETAILS = 'SET_BATCH_DETAILS';

export enum EOfferStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  EMPTY = 'EMPTY',
  NOT_ACTIVE = 'NOT_ACTIVE',
  COMPLETED = 'COMPLETED',
}

export interface BatchesData {
  id: UUID;
  displayId: number;
  status: EBatchStatuses;
  message: string;
  notes?: string;
  totalBones: number;
  bonesLeft: number;
  createdDate: string;
  finishedDate?: string;
  promoText?: string;
  location: {
    id: UUID;
    name: string;
  };
  offer?: {
    id: UUID;
    title: string;
    status: EOfferStatus;
  };
}

export const setBatches = (batchesData: BatchesData[]) => ({
  type: SET_BATCHES,
  payload: batchesData,
});

export const setBatchDetails = (batchesData: BatchesData) => ({
  type: SET_BATCHES,
  payload: batchesData,
});
