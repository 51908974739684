import { OffersData, SET_OFFERS } from '../actions/offers';

export interface OffersState {
  offers: OffersData[];
  totalPages: number;
  totalElements: number;
}

const initialState: OffersState = {
  offers: [],
  totalPages: 0,
  totalElements: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const offersReducer = (state = initialState, action: any): OffersState => {
  switch (action.type) {
    case SET_OFFERS:
      return {
        offers: action.payload.content,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
};

export default offersReducer;
