import * as yup from 'yup';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import { Box, TextField, Button, Autocomplete } from '@mui/material';

import { UUID } from 'src/api';
import { EDIT_MODE } from './BusinessCard';
import { EditBusinessAddressSchema } from 'src/utils/validators';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { useUpdateAPI as useMutateAPI } from 'src/api/businessDetails';
import { AddressData } from 'src/store/actions/businesses';
import { useGetAPI } from 'src/api/countries';

export interface BusinessAddressUpdaterProps {
  address: AddressData;
  setEditMode: Dispatch<SetStateAction<EDIT_MODE>>;
}

type BusinessAddressInput = yup.Asserts<typeof EditBusinessAddressSchema>;

const BusinessAddressUpdater = ({
  address: { address, city, country, id, zip, state = '' },
  setEditMode,
}: BusinessAddressUpdaterProps) => {
  const [isEdited, setIsEdited] = useState(false);

  const {
    register,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm<BusinessAddressInput>({
    mode: 'all',
    resolver: yupResolver(EditBusinessAddressSchema),
  });

  const { isLoading, isSuccess, updateAddress } = useMutateAPI(reset);
  const { data: countriesData } = useGetAPI();

  const onSubmitHandler: SubmitHandler<BusinessAddressInput> = (values) => {
    const params = {
      ...values,
      state: values.state || null,
    };
    updateAddress({ id: id, params });
  };

  //it's a temporary thing
  const changedFields = Object.keys(dirtyFields);

  useEffect(() => {
    setIsEdited(changedFields.length > 0);
  }, [changedFields]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setEditMode(EDIT_MODE.OFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (countriesData) {
      const country = countriesData?.data.find(
        (country: { name: string; id: UUID }) => country.name === 'United States'
      );
      setValue('countryId', country?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesData]);

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
      <TextField
        sx={{ mb: 2 }}
        label="Address"
        fullWidth
        required
        defaultValue={address}
        error={fieldError(errors, 'address')}
        helperText={fieldHelpertext(errors, 'address')}
        {...register('address')}
      />
      <TextField
        sx={{ mb: 2 }}
        label="Zip"
        fullWidth
        required
        defaultValue={zip}
        error={fieldError(errors, 'zip')}
        helperText={fieldHelpertext(errors, 'zip')}
        {...register('zip')}
      />
      <TextField
        sx={{ mb: 2 }}
        label="City"
        fullWidth
        required
        defaultValue={city}
        error={fieldError(errors, 'city')}
        helperText={fieldHelpertext(errors, 'city')}
        {...register('city')}
      />
      <TextField
        sx={{ mb: 2 }}
        label="State"
        fullWidth
        defaultValue={state}
        error={fieldError(errors, 'state')}
        helperText={fieldHelpertext(errors, 'state')}
        {...register('state')}
      />
      <Controller
        name="countryId"
        control={control}
        render={({ field: { onChange } }) => (
          <Autocomplete
            sx={{ mb: 2 }}
            options={countriesData?.data || []}
            getOptionLabel={(option): any => option.name}
            onChange={(_, newValue) => onChange(newValue?.id || undefined)}
            defaultValue={{
              name: country.name,
              id: country.id,
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={fieldError(errors, 'countryId')}
                helperText={fieldHelpertext(errors, 'countryId')}
                FormHelperTextProps={{
                  style: {
                    color: '#FF5630',
                  },
                }}
              />
            )}
          />
        )}
      />
      <Box display={'flex'} gap={2}>
        <LoadingButton
          disabled={!(isValid && isEdited)}
          loading={isLoading}
          variant="contained"
          fullWidth
          type="submit"
          sx={{ py: '0.8rem' }}
        >
          Save Changes
        </LoadingButton>
        <Button
          variant="contained"
          fullWidth
          sx={{ py: '0.8rem' }}
          onClick={() => {
            reset();
            setEditMode(EDIT_MODE.OFF);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
export default BusinessAddressUpdater;
