import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { useDispatch } from 'src/store';
import { setUserDetails } from 'src/store/actions/user';

import { usersAPI, UUID } from '.';
import { useSnackbar } from '../components/snackbar';

export const useGetAPI = (id: UUID) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, error, data } = useQuery(['user', id], () => usersAPI.getUserDetails(id), {
    onSuccess(data) {
      dispatch(setUserDetails(data.data));
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`User Details: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, error, data };
};
