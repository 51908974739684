import { UUID } from 'src/api';
import { AddressData, LocationData, SET_BUSINESS_DETAILS } from '../actions/businesses';

export interface DetailInfoState {
  id: UUID;
  name: string;
  userEmail: string;
  description: string;
  locations: LocationData[];
  addresses: AddressData[];
  createdDate: string;
  updatedDate: string;
}

const initialState: DetailInfoState = {
  id: '',
  name: '',
  userEmail: '',
  description: '',
  locations: [
    {
      id: '',
      title: '',
      longitude: 0,
      latitude: 0,
      status: '',
    },
  ],
  addresses: [
    {
      id: '',
      country: {
        id: '',
        name: '',
        avatar: '',
      },
      city: '',
      zip: '',
      address: '',
    },
  ],
  createdDate: '',
  updatedDate: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const businessDetailInfoReducer = (state = initialState, action: any): DetailInfoState => {
  switch (action.type) {
    case SET_BUSINESS_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default businessDetailInfoReducer;
