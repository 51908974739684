import { Dispatch, SetStateAction, useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { UUID } from 'src/api';
import { RootState, useSelector } from 'src/store';
import { useSnackbar } from 'src/components/snackbar';
import { LOCATION_STATUSES } from 'src/api/businessAPI';
import { LOACTIONS_INFO_HEAD_CELLS } from 'src/constants';
import { IconButtonAnimate } from 'src/components/animate';
import {
  useArchiveAPI,
  useCheckAPI,
  useDeleteAPI,
  useGetAPI as useGetLocationsAPI,
} from 'src/api/location';

import Edit from '@mui/icons-material/Edit';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Delete from '@mui/icons-material/Delete';

import Show from '../../Show/Show';
import DynamicTableHead from '../../DynamicTableHead';
import confirmModalStyles from 'src/helpers/modalStyles';
import DynamicTablePagination from '../../DynamicTablePagination';
import { useDynamicTableActions } from 'src/hooks/useDynamicTableActions';

interface ILocations {
  businessId: UUID;
  status: LOCATION_STATUSES;
  checkedLocId?: UUID | null;
  setBusinessId?: Dispatch<SetStateAction<UUID | null>>;
  setLocationId?: Dispatch<SetStateAction<UUID | null>>;
  setCheckedLocId?: Dispatch<SetStateAction<UUID | null>>;
}

const Locations = ({
  businessId,
  status,
  checkedLocId = null,
  setBusinessId = () => {},
  setLocationId = () => {},
  setCheckedLocId = () => {},
}: ILocations) => {
  const theme = useTheme();
  const confirm = useConfirm();

  const { enqueueSnackbar } = useSnackbar();
  const locations = useSelector((state: RootState) => state.locations);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    sort,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
  } = useDynamicTableActions('title');

  useGetLocationsAPI({ page, size: rowsPerPage, sort, businessId, status });

  const { archiveLocation } = useArchiveAPI();
  const { deleteLocation } = useDeleteAPI();
  const { data } = useCheckAPI(checkedLocId);

  const handleOpenModal = ({
    btnText,
    title,
    id,
    action = () => {},
  }: {
    btnText: string;
    title: string;
    id?: UUID;
    action?: (value: UUID) => void;
  }) => {
    confirm({
      title,
      ...confirmModalStyles(theme, btnText),
    })
      .then(() => {
        if (id) {
          action(id);
        }
        setLocationId(checkedLocId);
      })
      .catch(() => {
        if (!id) {
          setLocationId(null);
          setCheckedLocId(null);
        }
      });
  };

  useEffect(() => {
    if (data?.data?.isActiveBatchBones && data?.data?.totalLinkedBatchBones) {
      setCheckedLocId('');
      enqueueSnackbar(
        'This location is used in active batch of bones. Please, pause batch first.',
        { variant: 'error' }
      );
      return;
    }
    if (!data?.data?.isActiveBatchBones && data?.data?.totalLinkedBatchBones) {
      setCheckedLocId('');
      return handleOpenModal({
        btnText: 'Continue',
        title:
          'Are you sure you want to change this bones location? It is currently used in a batch of bones',
      });
    }
    setLocationId(checkedLocId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box sx={{ width: 'max-content' }}>
      <Show
        condition={status === 'ACTIVE'}
        or={
          <Typography variant="h4" component="h1" paragraph>
            Deleted Locations
          </Typography>
        }
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" component="h1" paragraph>
            Ad Locations
          </Typography>
          <Button variant="contained" sx={{ mb: '1rem' }} onClick={() => setBusinessId(businessId)}>
            Add new
          </Button>
        </Box>
      </Show>
      <Show
        condition={!!locations?.locations?.length}
        or={
          <Typography variant="h4" component="h1" paragraph>
            There are no locations
          </Typography>
        }
      >
        <Paper>
          <TableContainer>
            <Table sx={{ minWidth: 350 }} size="small">
              <DynamicTableHead
                headCells={LOACTIONS_INFO_HEAD_CELLS}
                order={order}
                orderBy={orderBy}
                rowsCount={locations?.locations?.length}
                onRequestSort={onRequestSort}
              />
              <TableBody>
                {locations?.locations?.map((location) => (
                  <TableRow
                    key={location?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ minWidth: 200 }} align="center">
                      {location?.title}
                    </TableCell>
                    <TableCell align="center">{location?.latitude}</TableCell>
                    <TableCell align="center">{location?.longitude}</TableCell>
                    <TableCell align="center">
                      <Show condition={location?.status === 'ACTIVE'}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-around',
                            gap: 0.5,
                          }}
                        >
                          <IconButtonAnimate
                            color="primary"
                            onClick={() => setCheckedLocId(location?.id)}
                          >
                            <Edit />
                          </IconButtonAnimate>
                          <Divider orientation="vertical" flexItem />
                          <IconButtonAnimate
                            color="primary"
                            onClick={() =>
                              handleOpenModal({
                                btnText: 'Archive',
                                title: 'Are you sure you want to archive location?',
                                action: archiveLocation,
                                id: location?.id,
                              })
                            }
                          >
                            <BookmarkBorderIcon />
                          </IconButtonAnimate>
                        </Box>
                      </Show>
                      <Show condition={location?.status === 'ARCHIVED'}>
                        <IconButtonAnimate
                          color="primary"
                          onClick={() =>
                            handleOpenModal({
                              btnText: 'Delete',
                              title:
                                'Are you sure you want to delete this bones location? If it is currently used in a batch of bones, it will be detached before being deleted',
                              action: deleteLocation,
                              id: location?.id,
                            })
                          }
                        >
                          <Delete />
                        </IconButtonAnimate>
                      </Show>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DynamicTablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            totalPages={locations?.totalPages}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
          />
        </Paper>
      </Show>
    </Box>
  );
};

export default Locations;
