import { Box } from '@mui/material';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';
import Show from 'src/pages/components/Show/Show';

const style = {
  padding: 6,
  backgroundColor: '#fff',
  border: '1px solid #ccc',
};

const CustomTooltip = (props: any) => {
  const { active, payload, purchasedOffers, usedOffers, openedOffers } = props;
  if (!active) {
    return null;
  }
  const data = payload?.[0]?.payload;

  return (
    <Box className="area-chart-tooltip" style={style}>
      <BusinessStyledTypograhpy color={'black'}>{data?.date}</BusinessStyledTypograhpy>
      <Show condition={!!data?.morning}>
        <BusinessStyledTypograhpy color={'#F7AB00'}>
          morning: {data?.morning}
        </BusinessStyledTypograhpy>
      </Show>
      <Show condition={!!data?.day}>
        <BusinessStyledTypograhpy color={'#4BBDE3'}>day: {data?.day}</BusinessStyledTypograhpy>
      </Show>
      <Show condition={!!data?.evening}>
        <BusinessStyledTypograhpy color={'#3EAB54'}>
          evening: {data?.evening}
        </BusinessStyledTypograhpy>
      </Show>
      <Show condition={!!data?.night}>
        <BusinessStyledTypograhpy color={'#C4CDD5'}>night: {data?.night}</BusinessStyledTypograhpy>
      </Show>
      <Show condition={!!data?.allDay}>
        <BusinessStyledTypograhpy color={'#078DEE'}>
          all day: {data?.allDay}
        </BusinessStyledTypograhpy>
      </Show>
      <Show condition={!!data?.totalOffers && purchasedOffers}>
        <BusinessStyledTypograhpy color={'#078DEE'}>
          {purchasedOffers}: {data?.totalOffers}
        </BusinessStyledTypograhpy>
      </Show>
      <Show condition={!!data?.totalOffers && openedOffers}>
        <BusinessStyledTypograhpy color={'#078DEE'}>
          {openedOffers}: {data?.totalOffers}
        </BusinessStyledTypograhpy>
      </Show>
      <Show condition={!!data?.totalOffers && usedOffers}>
        <BusinessStyledTypograhpy color={'#078DEE'}>
          {usedOffers}: {data?.totalOffers}
        </BusinessStyledTypograhpy>
      </Show>
    </Box>
  );
};

export default CustomTooltip;
