import * as yup from 'yup';
import Geocode from 'react-geocode';

import { v4 as uuidv4 } from 'uuid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ChangeEvent, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button, Divider, Fade, Modal, useTheme } from '@mui/material';

import { useGetAPI } from 'src/api/location';
import { IconButtonAnimate } from '../animate';
import { defaultLocation } from 'src/constants';
import { ILocationsModal } from './LocationsModal';
import { RootState, useSelector } from 'src/store';
import { useSnackbar } from 'src/components/snackbar';
import { modalStyles } from 'src/helpers/modalStyles';
import { LOCATION_STATUSES } from 'src/api/businessAPI';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { StyledTextField } from 'src/pages/components/RegisterSteps/Register';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import useMapLoad from 'src/hooks/useMapLoad';
import Show from 'src/pages/components/Show/Show';
import GoogleMap from 'src/pages/components/Map/Map';

import CloseIcon from '@mui/icons-material/Close';

const RoleBusinessLocationsModal = ({
  data,
  businessId,
  openId,
  isLoading,
  isSuccess,
  isCreateNewLocation,
  label,
  schema,
  businessCity,
  setOpenId,
  setCheckedLocId = () => {},
  setIsCreateNewLocation = () => {},
  mutate,
  refetchLocations,
}: ILocationsModal) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [zoom, setZoom] = useState<number>(10);
  const [address, setAddress] = useState<string>('');
  const [isEdited, setIsEdited] = useState(false);
  const [location, setLocation] = useState<{ lat: number; lng: number }>(
    data ? { lat: data?.latitude, lng: data?.longitude } : defaultLocation
  );
  const [center, setCenter] = useState<{ lat: number; lng: number }>(
    data ? { lat: data?.latitude, lng: data?.longitude } : defaultLocation
  );

  useGetAPI({ businessId, status: LOCATION_STATUSES.ACTIVE, size: 100, page: 1 });

  const locations = useSelector((state: RootState) => state.locations?.locations);

  const { isLoaded, onLoad } = useMapLoad();
  const {
    register,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm<yup.Asserts<typeof schema>>({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const watchLocation = watch(['latitude', 'longitude']);
  const changedFields = Object.keys(dirtyFields);

  useEffect(() => {
    setLocation({
      lat: parseFloat(watchLocation?.[0] || '0'),
      lng: parseFloat(watchLocation?.[1] || '0'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLocation[0], watchLocation[1]]);

  useEffect(() => {
    const fieldsLength = changedFields.length > 0;
    if (fieldsLength) {
      setIsEdited(fieldsLength);
    }
  }, [changedFields]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (isCreateNewLocation) {
        return reset();
      }
      refetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (businessCity) {
      Geocode.fromAddress(businessCity).then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter({ lat, lng });
        setValue('latitude', lat.toString());
        setValue('longitude', lng.toString());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCity]);

  useEffect(() => {
    if (address) {
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          if (lat && lng) {
            setCenter({ lat, lng });
            setZoom(19);
            enqueueSnackbar('Address found', { variant: 'success' });
          }
        },
        (err) => {
          enqueueSnackbar('Address was not found', { variant: 'error' });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const onSubmitHandler: SubmitHandler<yup.Asserts<typeof schema>> = (values) => {
    const params = {
      ...values,
      longitude: parseFloat(values.longitude || '0'),
      latitude: parseFloat(values.latitude || '0'),
    };
    mutate({ id: data ? data?.id : businessId, params });
    if (data) {
      setCheckedLocId(null);
    }
  };

  const handleSelectedLocationChange = (lat: number, lng: number) => {
    setValue('latitude', lat.toString());
    setValue('longitude', lng.toString());
    setIsEdited(true);
    clearErrors(['latitude', 'longitude']);
  };

  const handleSearchAddress = (e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);

  return (
    <Modal
      open={!!openId}
      onClose={() => {
        setOpenId(null);
        setCheckedLocId(null);
        refetchLocations();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={!!openId}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Box sx={{ ...modalStyles, maxWidth: 812 }}>
            <Box sx={{ margin: '0 auto' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <BusinessStyledTypograhpy
                  id="transition-modal-title"
                  fontSize={22}
                  fontWeight={'bold'}
                >
                  {label} location
                </BusinessStyledTypograhpy>
                <IconButtonAnimate
                  sx={{ width: 24, height: 24, cursor: 'pointer' }}
                  onClick={() => {
                    setOpenId(null);
                    setCheckedLocId(null);
                    refetchLocations();
                  }}
                >
                  <CloseIcon />
                </IconButtonAnimate>
              </Box>

              <Box
                sx={{
                  gap: 2,
                  mt: 3,
                  display: { xs: 'block', md: 'flex' },
                }}
              >
                <Show condition={isLoaded}>
                  <Box position={'relative'}>
                    <StyledTextField
                      value={address}
                      onChange={handleSearchAddress}
                      sx={{
                        width: 355,
                        height: 36,
                        m: 1,
                        position: 'absolute',
                        zIndex: 1000,
                        backgroundColor: theme.palette.mode === 'dark' ? '#212b36' : 'white',
                        input: {
                          height: 3,
                          '&::placeholder': {
                            color: '#aeb9ce',
                          },
                        },
                        '.MuiOutlinedInput-root': {
                          borderRadius: '11px',
                          '&:hover': { border: 'none!' },
                        },
                      }}
                      placeholder="Search"
                    />
                    <GoogleMap
                      location={location}
                      locations={locations?.filter((loc) => loc?.id !== openId)}
                      center={center}
                      zoom={zoom}
                      setLocation={setLocation}
                      onLoad={onLoad}
                      onSelectedLocationChange={handleSelectedLocationChange}
                      options={{
                        fullscreenControl: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                        controlSize: 22,
                      }}
                      styles={{
                        width: 372,
                        height: 350,
                        borderRadius: 12,
                      }}
                    />
                  </Box>
                </Show>
                <Box sx={{ mt: { xs: 2, md: 'unset' } }}>
                  <StyledTextField
                    sx={{ mb: 2, maxWidth: 372 }}
                    label="Location name"
                    required
                    fullWidth
                    defaultValue={data?.title}
                    error={fieldError(errors, 'title')}
                    helperText={fieldHelpertext(errors, 'title')}
                    {...register('title')}
                  />
                  <StyledTextField
                    sx={{ mb: 2, maxWidth: 372 }}
                    label="Latitude"
                    required
                    fullWidth
                    type="number"
                    defaultValue={data?.latitude || location.lat}
                    error={fieldError(errors, 'latitude')}
                    helperText={fieldHelpertext(errors, 'latitude')}
                    {...register('latitude')}
                  />
                  <StyledTextField
                    sx={{ mb: 2, maxWidth: 372 }}
                    label="Longitude"
                    required
                    fullWidth
                    type="number"
                    defaultValue={data?.longitude || location.lng}
                    error={fieldError(errors, 'longitude')}
                    helperText={fieldHelpertext(errors, 'longitude')}
                    {...register('longitude')}
                  />
                  {!data && (
                    <StyledTextField
                      InputProps={{
                        type: 'hidden',
                      }}
                      sx={{ visibility: 'hidden' }}
                      value={uuidv4()}
                      {...register('id')}
                    />
                  )}
                </Box>
              </Box>
              <Divider sx={{ mt: { xs: 2, md: 4 } }} />
              <Box
                sx={{
                  gap: 2,
                  mt: 3,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: { xs: 'column-reverse', md: 'unset' },
                }}
              >
                <Box
                  sx={{
                    width: { xs: '100%', md: 'unset' },
                  }}
                >
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      minWidth: 146,
                      mt: { xs: '1rem', md: 'unset' },
                      fontFamily: 'CircularStd',
                      fontSize: 16,
                    }}
                    onClick={() => {
                      reset();
                      setOpenId(null);
                      setCheckedLocId(null);
                      refetchLocations();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Show
                  condition={!data}
                  or={
                    <Box sx={{ width: { xs: '100%', md: 'unset' } }}>
                      <LoadingButton
                        disabled={!(isValid && isEdited)}
                        loading={isLoading}
                        variant="contained"
                        fullWidth
                        type="submit"
                        onClick={() => setIsCreateNewLocation(false)}
                        sx={{ fontSize: 16, fontFamily: 'CircularStd' }}
                      >
                        {label} Location
                      </LoadingButton>
                    </Box>
                  }
                >
                  <Box
                    display={'flex'}
                    gap={2}
                    sx={{
                      width: { xs: '100%', md: 'unset' },
                    }}
                  >
                    <LoadingButton
                      disabled={!(isValid && isEdited)}
                      loading={isLoading}
                      variant="outlined"
                      fullWidth
                      type="submit"
                      onClick={() => setIsCreateNewLocation(true)}
                      sx={{ width: 184, fontSize: 16, fontFamily: 'CircularStd' }}
                    >
                      Save & Add Another
                    </LoadingButton>
                    <LoadingButton
                      disabled={!(isValid && isEdited)}
                      loading={isLoading}
                      variant="contained"
                      fullWidth
                      type="submit"
                      onClick={() => setIsCreateNewLocation(false)}
                      sx={{ width: 146, fontSize: 16, fontFamily: 'CircularStd' }}
                    >
                      Save Location
                    </LoadingButton>
                  </Box>
                </Show>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default RoleBusinessLocationsModal;
