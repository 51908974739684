import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { businessAPI, UUID } from '.';
import { useDispatch } from 'src/store';
import { useSnackbar } from '../components/snackbar';
import { setLocations } from 'src/store/actions/businesses';
import { IGetSortParams, LOCATION_STATUSES } from './businessAPI';
import { setPinsLocations } from 'src/store/reducers/pinsLocations';

export const useGetAPI = (params: {
  businessId?: UUID;
  status: LOCATION_STATUSES;
  page?: number;
  size?: number;
  sort?: string[];
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, error, data, refetch } = useQuery(
    ['locations', params],
    () => businessAPI.getLocations(params),
    {
      onSuccess(data) {
        dispatch(setLocations(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Locations: ${err.message}`, { variant: 'error' });
      },
      enabled: !!params?.businessId,
    }
  );

  return { isLoading, error, data, refetch };
};

export const useGetLocationsStatsAPI = (businessId: UUID | null) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, isSuccess, error, data } = useQuery(
    ['locationsStats', businessId],
    () => businessAPI.getLocationsStats(businessId),
    {
      onError(err: AxiosError) {
        enqueueSnackbar(`Locations stats: ${err.message}`, { variant: 'error' });
      },
      enabled: !!businessId,
    }
  );

  return { isLoading, isSuccess, error, data };
};

export const useGetPinsLocationsAPI = (params: IGetSortParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, error, data } = useQuery(
    ['pinsLocations', params],
    () => businessAPI.getPinsLocations(params),
    {
      onSuccess(data) {
        dispatch(setPinsLocations(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Pins Locations: ${err.message}`, { variant: 'error' });
      },
    }
  );

  return { isLoading, error, data };
};

export const useMutateAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, mutate, isSuccess } = useMutation(businessAPI.createLocation, {
    onSuccess() {
      queryClient.invalidateQueries('locations');
      queryClient.invalidateQueries('business');
      enqueueSnackbar(`Location created successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Location create: ${(err.response?.data as AxiosError)?.message}`, {
        variant: 'error',
      });
    },
  });

  return { isLoading, isSuccess, createLocation: mutate };
};

export const useCreatePinAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, mutate, isSuccess, reset } = useMutation(businessAPI.createPinLocation, {
    onSuccess() {
      queryClient.invalidateQueries('pinsLocations');
      enqueueSnackbar(`Pin created successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Pin create: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, createPin: mutate, reset };
};

export const useUpdateAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, mutate, isSuccess } = useMutation(businessAPI.updateBusinessLocation, {
    onSuccess() {
      queryClient.invalidateQueries('business');
      queryClient.invalidateQueries('locations');
      enqueueSnackbar(`Location updated successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Location update: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, updateLocation: mutate };
};

export const useUpdatePinAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, mutate, isSuccess, reset } = useMutation(businessAPI.updatePinLocation, {
    onSuccess() {
      queryClient.invalidateQueries('pinsLocations');
      enqueueSnackbar(`Pin updated successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Pin update: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, updatePin: mutate, reset };
};

export const useArchiveAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, mutate, isSuccess } = useMutation(businessAPI.archiveLocation, {
    onSuccess() {
      queryClient.invalidateQueries('business');
      queryClient.invalidateQueries('locations');
      enqueueSnackbar(`Location archived successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`${(err.response?.data as AxiosError)?.message}`, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, archiveLocation: mutate };
};

export const useDeleteAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, mutate, isSuccess } = useMutation(businessAPI.deleteLocation, {
    onSuccess() {
      queryClient.invalidateQueries('business');
      queryClient.invalidateQueries('locations');
      enqueueSnackbar(`Location deleted successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Location delete: ${err.message}`, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, deleteLocation: mutate };
};

export const useCheckAPI = (id: UUID | null) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isSuccess } = useQuery(
    ['activeLocation', id],
    () => businessAPI.checkActiveLocation(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onError(err: AxiosError) {
        enqueueSnackbar(`Location: ${err.message}`, { variant: 'error' });
      },
    }
  );

  return { data, isLoading, isSuccess };
};
