import * as yup from 'yup';

import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box } from '@mui/material';

import { getTokens, UUID } from 'src/api';
import { StyledTextField } from './Register';
import { useGetAPI } from 'src/api/countries';
import { useMutateAPI } from 'src/api/businesses';
import { useRefreshTokenAPI } from 'src/api/auth';
import { getRegisterInProgress } from 'src/Keycloak';
import { RegisterBusinessInfoSchema } from 'src/utils/validators';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';
import {EBusinessRegStatus} from "../../../constants";

type RegisterBusinessInfoInput = yup.Asserts<typeof RegisterBusinessInfoSchema>;

const RegisterBusinessInfo = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState<any>({ name: 'United States', id: '' });
  const { data: countriesData } = useGetAPI();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
  } = useForm<RegisterBusinessInfoInput>({
    mode: 'all',
    resolver: yupResolver(RegisterBusinessInfoSchema),
  });

  useEffect(() => {
    if (!getRegisterInProgress()) {
      localStorage.setItem('registerInProgress', 'inProgress');
    }
  }, []);

  const { isLoading, isSuccess, createBusiness } = useMutateAPI();
  const { isLoading: loading, isSuccess: success, refreshToken } = useRefreshTokenAPI();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      localStorage.removeItem('registerInProgress');
      localStorage.setItem('businessRegistrationInfo', EBusinessRegStatus.COMPLETED);
      refreshToken(getTokens()?.refreshToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (!loading && success) {
      navigate('/dashboard/business/dashboard', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, success]);

  useEffect(() => {
    if (countriesData) {
      const country = countriesData?.data.find(
        (country: { name: string; id: UUID }) => country.name === 'United States'
      );
      setValue(`addresses.${0}.countryId`, country?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesData]);

  const onSubmitHandler: SubmitHandler<RegisterBusinessInfoInput> = (values) => {
    const params = {
      ...values,
      addresses: values?.addresses?.map((address) => ({
        ...address,
        state: address.state || undefined,
      })),
      locations: [],
    };

    createBusiness(params);
  };

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
      <Box>
        <BusinessStyledTypograhpy fontSize={22} fontWeight={'bold'} sx={{ mb: '1rem' }}>
          Enter Business Details
        </BusinessStyledTypograhpy>
        <StyledTextField
          sx={{ mb: 2 }}
          label="Business Name"
          fullWidth
          required
          error={fieldError(errors, 'name')}
          helperText={fieldHelpertext(errors, 'name')}
          {...register('name')}
        />
        <StyledTextField
          sx={{ mb: 2 }}
          label="Business Description"
          fullWidth
          required
          type="text"
          multiline={true}
          error={fieldError(errors, 'description')}
          helperText={fieldHelpertext(errors, 'description')}
          {...register('description')}
        />
        <StyledTextField
          sx={{ mb: 2 }}
          label="Street Address"
          fullWidth
          required
          error={fieldError(errors.addresses?.[0], 'address')}
          helperText={fieldHelpertext(errors.addresses?.[0], 'address')}
          {...register(`addresses.${0}.address`)}
        />
        <StyledTextField
          sx={{ mb: 2 }}
          label="City"
          defaultValue={'New York'}
          fullWidth
          required
          error={fieldError(errors.addresses?.[0], 'city')}
          helperText={fieldHelpertext(errors.addresses?.[0], 'city')}
          {...register(`addresses.${0}.city`)}
        />
        <StyledTextField
          sx={{ mb: 2 }}
          label="State (optional)"
          fullWidth
          error={fieldError(errors.addresses?.[0], 'state')}
          helperText={fieldHelpertext(errors.addresses?.[0], 'state')}
          {...register(`addresses.${0}.state`)}
        />
        <StyledTextField
          sx={{ mb: 2 }}
          label="Zip"
          fullWidth
          required
          error={fieldError(errors.addresses?.[0], 'zip')}
          helperText={fieldHelpertext(errors.addresses?.[0], 'zip')}
          {...register(`addresses.${0}.zip`)}
        />
        <Controller
          name={`addresses.${0}.countryId`}
          control={control}
          render={({ field: { onChange } }) => (
            <Autocomplete
              options={countriesData?.data || []}
              getOptionLabel={(option): any => option.name}
              onChange={(_, newValue) => {
                setCountry({ name: newValue?.name, id: newValue?.id });
                onChange(newValue?.id || undefined);
              }}
              value={country}
              defaultValue={country}
              disableClearable
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  required
                  error={fieldError(errors.addresses?.[0], 'countryId')}
                  helperText={fieldHelpertext(errors.addresses?.[0], 'countryId')}
                  FormHelperTextProps={{
                    style: {
                      color: '#FF5630',
                    },
                  }}
                />
              )}
            />
          )}
        />
        <StyledTextField
          InputProps={{
            type: 'hidden',
          }}
          sx={{ visibility: 'hidden' }}
          value={uuidv4()}
          {...register('id')}
        />
        <StyledTextField
          InputProps={{
            type: 'hidden',
          }}
          sx={{ visibility: 'hidden' }}
          value={uuidv4()}
          {...register(`addresses.${0}.id`)}
        />
        <LoadingButton
          disabled={!isValid}
          variant="contained"
          fullWidth
          type="submit"
          sx={{ py: '0.8rem', mt: '1rem', fontFamily: 'CircularStd', fontSize: 18, height: 50 }}
        >
          Complete Registration
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default RegisterBusinessInfo;
