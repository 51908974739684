import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';

import Show from '../Show/Show';

import { UUID } from 'src/api';
import { LocationData } from 'src/store/actions/businesses';
import { ICampaignDetailsLocation } from 'src/store/actions/campaigns';

interface MapProps {
  location?: { lat: number; lng: number };
  locations?: any[];
  locationIds?: UUID[];
  center: { lat: number; lng: number };
  options?: any;
  zoom: number;
  setLocation?: Dispatch<
    SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  onSelectLocationOnMap?: (value: UUID) => void;
  onLoad: any;
  onSelectedLocationChange?: (lat: number, lng: number) => void;
  styles: any;
}

const Map = ({
  location,
  locations,
  locationIds,
  center,
  zoom,
  styles,
  setLocation = () => {},
  onLoad,
  options = {},
  onSelectedLocationChange = () => {},
  onSelectLocationOnMap = () => {},
}: MapProps) => {
  const handleChangeLocation = (event: google.maps.MapMouseEvent) => {
    const { latLng } = event;
    const lat = latLng?.lat() || 0;
    const lng = latLng?.lng() || 0;
    setLocation({ lat, lng });
    onSelectedLocationChange(lat, lng);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <GoogleMap
        zoom={zoom}
        center={center}
        mapContainerStyle={styles}
        onLoad={onLoad}
        onClick={handleChangeLocation}
        options={options}
      >
        <>
          <Show condition={!locationIds}>
            <Marker
              position={location || { lat: 0, lng: 0 }}
              icon={'/assets/icons/map/greyMarker.png'}
            />
          </Show>
          <Show condition={!!locations}>
            <>
              {locations?.map(
                ({ id, latitude, longitude }: ICampaignDetailsLocation | LocationData) => (
                  <Marker
                    zIndex={1000}
                    key={id}
                    position={{ lat: latitude, lng: longitude }}
                    icon={
                      !locationIds
                        ? '/assets/icons/map/greenMarker.png'
                        : locationIds?.includes(id)
                        ? '/assets/icons/map/greenMarker.png'
                        : '/assets/icons/map/greyMarker.png'
                    }
                    onClick={() => onSelectLocationOnMap(id)}
                  />
                )
              )}
            </>
          </Show>
        </>
      </GoogleMap>
    </Box>
  );
};

export default Map;
