import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { EGroupBy, ETimezone } from 'src/api/businessAPI';

export interface UseGeneralFiltersProps {
  initStartDate?: Dayjs | null
  initEndDate?: Dayjs | null
}

export const useGeneralFilters = (props?: UseGeneralFiltersProps) => {
  const [groupBy, setGropuBy] = useState<EGroupBy>(EGroupBy.DAY);
  const [timezone, setTimezone] = useState<ETimezone>(ETimezone.NEW_YORK);
  const [fixedPeriod, setFixedPeriod] = useState<string>('LAST_7_DAYS');
  const [startDate, setStartDate] = useState<Dayjs | null>(() => {
    if(props?.initStartDate) {
      return props?.initStartDate
    }

    return dayjs().subtract(1, 'week')
  });
  const [endDate, setEndDate] = useState<Dayjs | null>(() => {
    if(props?.initEndDate) {
      return props.initEndDate
    }

    return dayjs()
  });

  const handleFixedPeriodChange = (value: string) => {
    setFixedPeriod(value);
    if (value === 'TODAY') {
      setStartDate(dayjs());
      setEndDate(dayjs());
    } else if (value === 'YESTERDAY') {
      setStartDate(dayjs().subtract(1, 'day'));
      setEndDate(dayjs().subtract(1, 'day'));
    } else if (value === 'LAST_7_DAYS') {
      setStartDate(dayjs().subtract(1, 'week'));
      setEndDate(dayjs());
    } else if (value === 'LAST_28_DAYS') {
      setStartDate(dayjs().subtract(4, 'week'));
      setEndDate(dayjs());
    }
  };

  return {
    groupBy,
    timezone,
    fixedPeriod,
    startDate,
    endDate,
    setGropuBy,
    setTimezone,
    onFixedPeriodChange: handleFixedPeriodChange,
    setStartDate,
    setEndDate,
  };
};
