import { UUID } from 'src/api';

export const SET_CAMPAIGN_DETAILS = 'SET_CAMPAIGN_DETAILS';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';

export enum EBatchStatuses {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  STOPPED = 'STOPPED',
  FINISHED = 'FINISHED',
  WAITING_FOR_START = 'WAITING_FOR_START',
}

export interface ICampaignDetailsLocation {
  id: UUID;
  batchBonesId: UUID;
  name: string;
  batchBonesStatus: EBatchStatuses | '';
  totalBones: number;
  bonesRemaining: number;
  latitude: number;
  longitude: number;
}
export interface CampaignsData {
  id: UUID;
  name: string;
  status?: EBatchStatuses | '';
  startDate: string;
  endDate: string;
  totalBones: number;
  bonesRemaining: number;
  promoText: string | null;
  campaignAdvertisement: string;
  createdDate: string;
  offer: {
    id: UUID;
    name: string;
    image?: string;
  };
  locations: ICampaignDetailsLocation[];
}

export const setCampaigns = (campaignsData: CampaignsData[]) => ({
  type: SET_CAMPAIGNS,
  payload: campaignsData,
});

export const setCampaignDetails = (campaignDetailsData: CampaignsData) => ({
  type: SET_CAMPAIGN_DETAILS,
  payload: campaignDetailsData,
});
