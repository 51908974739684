import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Button,
  Divider,
  LinearProgress,
  MenuItem,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import { UUID } from 'src/api';
import { percentage } from 'src/helpers';
import { RootState, useSelector } from 'src/store';
import { useUploadCsv } from 'src/hooks/useUploadCsv';
import { CreateOfferSchema } from 'src/utils/validators';
import { CsvDownloader } from '../DetailedOffersDescription';
import { useGetAPI, useMutateAPI, useUpdateAPI } from 'src/api/offers';
import { useDynamicTableActions } from 'src/hooks/useDynamicTableActions';
import { OFFERS_HEAD_CELLS, OFFER_ALL_STATUSES, OFFER_VALUES } from 'src/constants';

import Show from '../../Show/Show';
import DynamicTableHead from '../../DynamicTableHead';
import confirmModalStyles from 'src/helpers/modalStyles';
import CreateEditOffer, { STATUSES } from './CreateEditOffer';
import DynamicTablePagination from '../../DynamicTablePagination';

interface IOffersProps {
  businessId: UUID;
}

const Offers = ({ businessId }: IOffersProps) => {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [hasFlow, setHasFlow] = useState<boolean>(true);
  const [skipUpload, setSkipUpload] = useState<boolean>(false);

  const theme = useTheme();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const offersData = useSelector((state: RootState) => state.offers);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    sort,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
  } = useDynamicTableActions('title');

  const { updateStatus } = useUpdateAPI();
  const { data, isLoading, isSuccess, createOffer, reset } = useMutateAPI();
  const { loading, success, handleUploadCsv } = useUploadCsv(data?.data?.id);

  useGetAPI({ businessId, page, size: rowsPerPage, sort });

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>, id: UUID) => {
    const status = event.target.value;
    updateStatus({ id, status });
  };

  const handleOpenModal = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: UUID,
    btnText: string,
    title: string
  ) => {
    confirm({
      title,
      ...confirmModalStyles(theme, btnText),
    }).then(() => {
      const status = event.target.value;
      updateStatus({ id, status });
    });
  };

  const handleRowClick = (event: React.MouseEvent<HTMLElement>, offerId: UUID) => {
    const target = event.target as HTMLElement;
    if (['Active', 'Not active'].includes(target.innerText) || target.nodeName !== 'TD') {
      return;
    }

    navigate(`/dashboard/businesses/offers/${offerId}`);
  };

  useEffect(() => {
    if ((success && !loading) || skipUpload) {
      setIsCreate(false);
      setActiveStep(0);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, loading, skipUpload]);

  return isCreate ? (
    <Fragment>
      <Show condition={hasFlow}>
        <Stepper activeStep={activeStep} sx={{ width: 580, mb: 1 }}>
          {['Create offer', 'Upload Dicount Codes'].map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Show>
      <Show condition={activeStep === 0}>
        <CreateEditOffer
          businessId={businessId}
          schema={CreateOfferSchema}
          isLoading={isLoading}
          isSuccess={isSuccess}
          hasFlow={hasFlow}
          mutate={createOffer}
          setIsAction={setIsCreate}
          setActiveStep={setActiveStep}
          setHasFlow={setHasFlow}
          resetMutation={reset}
        />
      </Show>
      <Show condition={activeStep === 1 && hasFlow}>
        <>
          <Box sx={{ mt: '1rem' }}>
            <LoadingButton
              variant="contained"
              component="label"
              startIcon={<FileUploadIcon />}
              loading={loading}
              sx={{ mr: 1 }}
            >
              Upload CSV
              <input id="file" type="file" accept=".csv" hidden onChange={handleUploadCsv} />
            </LoadingButton>
            <Button onClick={() => setSkipUpload(true)} variant="contained" disabled={!data}>
              Skip
            </Button>
          </Box>
          <CsvDownloader />
        </>
      </Show>
    </Fragment>
  ) : (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" component="h1" paragraph>
            Offers
          </Typography>
          <Button variant="contained" sx={{ mb: '1rem' }} onClick={() => setIsCreate(!isCreate)}>
            Add new
          </Button>
        </Box>
        {offersData?.offers?.length ? (
          <Paper sx={{ width: '100%', overflow: 'hidden', mb: 2 }}>
            <TableContainer
              component={Paper}
              sx={{
                height: 'min-content',
                '&::-webkit-scrollbar:vertical': {
                  display: 'none',
                },

                '&::-webkit-scrollbar': {
                  height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(145, 158, 171, 0.16)',
                  borderRadius: '12px',
                },
              }}
            >
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
                size={'medium'}
              >
                <DynamicTableHead
                  headCells={OFFERS_HEAD_CELLS}
                  order={order}
                  orderBy={orderBy}
                  rowsCount={offersData?.offers.length}
                  onRequestSort={onRequestSort}
                />
                <TableBody>
                  {offersData?.offers?.map((offer) => (
                    <TableRow
                      hover
                      key={offer?.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                      onClick={(event) => handleRowClick(event, offer.id)}
                    >
                      <TableCell
                        sx={{
                          maxWidth: 350,
                          overflow: 'hidden',
                          display: '-webkit-box !important',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                          paddingBottom: '4px',
                        }}
                        align="center"
                      >
                        {offer?.title}
                      </TableCell>
                      <TableCell align="center">{OFFER_VALUES[offer?.category]}</TableCell>
                      <TableCell align="center">{offer?.price}</TableCell>
                      <TableCell align="center">{offer?.totalOffers}</TableCell>
                      <TableCell align="center">{offer?.offersLeft}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress
                              sx={{ height: 10 }}
                              variant="determinate"
                              value={percentage(offer?.totalOffers, offer?.offersLeft)}
                            />
                          </Box>
                          <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="text.secondary">
                              {offer?.totalOffers
                                ? `${Math.trunc(
                                    percentage(offer?.totalOffers, offer?.offersLeft)
                                  )}%`
                                : '0%'}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{OFFER_VALUES[offer?.type]}</TableCell>
                      <TableCell align="center">{OFFER_VALUES[offer?.discountType]}</TableCell>
                      <TableCell align="center">
                        <TextField
                          select
                          disabled={
                            !offer?.isUseInBatchBones ||
                            !offer?.totalOffers ||
                            offer?.status === OFFER_ALL_STATUSES.COMPLETED
                          }
                          value={offer?.status}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            if (
                              offer?.isUseInBatchBones &&
                              offer?.status === OFFER_ALL_STATUSES.ACTIVE
                            ) {
                              return handleOpenModal(
                                e,
                                offer?.id,
                                'Continue',
                                'This offer is currently used in a batch of bones. Are you sure you want to deactivate it?'
                              );
                            }
                            handleStatusChange(e, offer?.id);
                          }}
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                        >
                          {STATUSES.map(({ value, label }) => (
                            <MenuItem
                              key={value}
                              value={value}
                              disabled={
                                ![OFFER_ALL_STATUSES.ACTIVE, OFFER_ALL_STATUSES.PAUSED].includes(
                                  value
                                )
                              }
                            >
                              {label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            <DynamicTablePagination
              page={page}
              rowsPerPage={rowsPerPage}
              totalPages={offersData?.totalPages}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
            />
          </Paper>
        ) : (
          <Typography variant="h4" component="h1" paragraph>
            There are no Offers
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Offers;
