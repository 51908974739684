import { useConfirm } from 'material-ui-confirm';
import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Typography,
  useTheme,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';

import {
  useMutateAPI,
  useUpdateAPI,
  useGetAPI as useGetLocationsAPI,
  useArchiveAPI,
  useCheckAPI,
  useDeleteAPI,
} from 'src/api/location';

import { UUID } from 'src/api';
import { RootState, useSelector } from 'src/store';
import { useGetAPI } from 'src/api/businessDetails';
import { useSnackbar } from 'src/components/snackbar';
import { LOCATION_STATUSES } from 'src/api/businessAPI';
import { LOACTIONS_INFO_HEAD_CELLS } from 'src/constants';
import { IconButtonAnimate } from 'src/components/animate';
import { StyledTypography } from '../components/RegisterSteps/Register';
import { useDynamicTableActions } from 'src/hooks/useDynamicTableActions';
import { CreateLocationSchema, UpdateLocationSchema } from 'src/utils/validators';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Show from '../components/Show/Show';
import confirmModalStyles from 'src/helpers/modalStyles';
import SvgColor from 'src/components/svg-color/SvgColor';
import DynamicTableHead from '../components/DynamicTableHead';
import RoleBusinessLocationsModal from 'src/components/modal/RoleBusinessLocationsModal';
import DynamicTablePagination from '../components/DynamicTablePagination';

export const StyledTableCell = styled(TableCell)(() => ({
  fontFamily: 'CircularStd',
}));

export const BusinessStyledTypograhpy = styled(Typography)(() => ({
  fontFamily: 'CircularStd',
}));

const RoleBusinessLocations = () => {
  const theme = useTheme();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState<LOCATION_STATUSES>(LOCATION_STATUSES.ACTIVE);
  const [checkedLocId, setCheckedLocId] = useState<UUID | null>(null);
  const [locationId, setLocationId] = useState<UUID | null>(null);
  const [businessId, setBusinessId] = useState<UUID | null>(null);
  const [isCreateNewLocation, setIsCreateNewLocation] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openElem, setOpenElem] = useState(null);

  const businessDetailInfo = useSelector((state: RootState) => state.businessDetailInfo);
  const businesses = useSelector((state: RootState) => state.businesses);
  const locations = useSelector((state: RootState) => state.locations);

  const id = useMemo(() => businesses?.businesses[0]?.id, [businesses]);
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    sort,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
  } = useDynamicTableActions('title');

  useGetAPI({ id });
  const { refetch } = useGetLocationsAPI({ businessId: id, status, page, size: rowsPerPage, sort });

  const { isLoading, isSuccess, createLocation } = useMutateAPI();
  const { isLoading: loading, isSuccess: success, updateLocation } = useUpdateAPI();
  const { archiveLocation } = useArchiveAPI();
  const { deleteLocation } = useDeleteAPI();
  const { data } = useCheckAPI(checkedLocId);

  const handleOpenModal = ({
    btnText,
    title,
    id,
    action = () => {},
  }: {
    btnText: string;
    title: string;
    id?: UUID;
    action?: (value: UUID) => void;
  }) => {
    confirm({
      title,
      ...confirmModalStyles(theme, btnText),
    })
      .then(() => {
        if (id) {
          action(id);
        }
        setLocationId(checkedLocId);
      })
      .catch(() => {
        if (!id) {
          setLocationId(null);
          setCheckedLocId(null);
        }
      });
  };

  const handleOpenActions = (elem: any) => (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(elem);
  };

  useEffect(() => {
    if (data?.data?.isActiveBatchBones && data?.data?.totalLinkedBatchBones) {
      setCheckedLocId(null);
      enqueueSnackbar(
        'This location is used in active batch of bones. Please, pause batch first.',
        { variant: 'error' }
      );
      return;
    }
    if (!data?.data?.isActiveBatchBones && data?.data?.totalLinkedBatchBones) {
      setCheckedLocId(null);
      return handleOpenModal({
        btnText: 'Continue',
        title:
          'Are you sure you want to change this bones location? It is currently used in a batch of bones',
      });
    }
    setLocationId(checkedLocId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (!isCreateNewLocation) {
        setBusinessId('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (!loading && success) {
      setLocationId('');
    }
  }, [loading, success]);

  const isActive = status === LOCATION_STATUSES.ACTIVE;
  return (
    <>
      <StyledTypography
        children="Locations"
        sx={{
          fontSize: '26px',
          lineHeight: '30px',
        }}
      />
      <Box>
        <Box
          sx={{
            height: 36,
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2,
            mt: 3,
          }}
        >
          <Box
            sx={{ width: 200 }}
            display={'flex'}
            justifyContent={'space-around'}
            alignItems={'center'}
          >
            <Box>
              <BusinessStyledTypograhpy
                children="Active"
                display={'inline-block'}
                sx={{
                  color: isActive ? theme.palette.primary.main : 'unset',
                  fontWeight: isActive ? 700 : 500,
                  cursor: 'pointer',
                }}
                onClick={() => setStatus(LOCATION_STATUSES.ACTIVE)}
              />
            </Box>
            <Box>
              <BusinessStyledTypograhpy
                children="Archived"
                display={'inline-block'}
                sx={{
                  color: !isActive ? theme.palette.primary.main : 'unset',
                  fontWeight: !isActive ? 700 : 500,
                  cursor: 'pointer',
                }}
                onClick={() => setStatus(LOCATION_STATUSES.ARCHIVED)}
              />
            </Box>
          </Box>
          <Show condition={status === 'ACTIVE'}>
            <Button
              sx={{ fontFamily: 'CircularStd' }}
              variant="contained"
              onClick={() => setBusinessId(id)}
            >
              New Location
            </Button>
          </Show>
        </Box>
        <Show
          condition={!!locations?.locations?.length}
          or={
            <BusinessStyledTypograhpy variant="h4" paragraph>
              There are no locations
            </BusinessStyledTypograhpy>
          }
        >
          <Paper>
            <TableContainer>
              <Table size="small">
                <DynamicTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={onRequestSort}
                  rowsCount={locations?.locations?.length}
                  headCells={LOACTIONS_INFO_HEAD_CELLS}
                />
                <TableBody>
                  {locations?.locations?.map((location) => (
                    <TableRow
                      key={location?.id}
                      sx={{
                        fontFamily: 'CircularStd',
                      }}
                    >
                      <StyledTableCell sx={{ minWidth: 200 }}>{location?.title}</StyledTableCell>
                      <StyledTableCell>{location?.latitude}</StyledTableCell>
                      <StyledTableCell>{location?.longitude}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Show condition={location?.status === 'ACTIVE'}>
                          <>
                            <IconButtonAnimate
                              color="primary"
                              onClick={handleOpenActions(location)}
                            >
                              <MoreHorizIcon />
                            </IconButtonAnimate>
                            <Menu
                              anchorEl={anchorEl}
                              open={openElem === location}
                              onClose={() => {
                                setAnchorEl(null);
                                setOpenElem(null);
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  setCheckedLocId(location?.id);
                                  setAnchorEl(null);
                                  setOpenElem(null);
                                }}
                              >
                                <IconButtonAnimate>
                                  <SvgColor
                                    sx={{ width: 15, height: 14 }}
                                    src="/assets/icons/menu/ic_edit.svg"
                                  />
                                </IconButtonAnimate>
                                <Typography children={'Edit'} color={theme.palette.primary.main} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleOpenModal({
                                    btnText: 'Archive',
                                    title: 'Are you sure you want to archive location?',
                                    action: archiveLocation,
                                    id: location?.id,
                                  });
                                  setAnchorEl(null);
                                  setOpenElem(null);
                                }}
                              >
                                <IconButtonAnimate>
                                  <SvgColor
                                    sx={{ width: 17, height: 15 }}
                                    src="/assets/icons/menu/ic_archive.svg"
                                  />
                                </IconButtonAnimate>
                                <Typography
                                  children={'Archive'}
                                  color={theme.palette.primary.main}
                                />
                              </MenuItem>
                            </Menu>
                          </>
                        </Show>
                        <Show condition={location?.status === 'ARCHIVED'}>
                          <Button
                            onClick={() =>
                              handleOpenModal({
                                btnText: 'Delete',
                                title:
                                  'Are you sure you want to delete this bones location? If it is currently used in a batch of bones, it will be detached before being deleted',
                                action: deleteLocation,
                                id: location?.id,
                              })
                            }
                          >
                            Delete
                          </Button>
                        </Show>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <DynamicTablePagination
              page={page}
              rowsPerPage={rowsPerPage}
              totalPages={locations?.totalPages}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
            />
          </Paper>
        </Show>
      </Box>
      <Show condition={!!businessId}>
        <RoleBusinessLocationsModal
          businessId={id}
          openId={businessId}
          isLoading={isLoading}
          isSuccess={isSuccess}
          label={'New'}
          schema={UpdateLocationSchema}
          businessCity={businessDetailInfo?.addresses?.[0]?.city}
          setOpenId={setBusinessId}
          mutate={createLocation}
          setIsCreateNewLocation={setIsCreateNewLocation}
          isCreateNewLocation={isCreateNewLocation}
          refetchLocations={refetch}
        />
      </Show>
      <Show condition={!!locationId}>
        <RoleBusinessLocationsModal
          businessId={id}
          data={locations?.locations?.find((location) => location?.id === locationId)}
          openId={locationId}
          isLoading={loading}
          label={'Update'}
          schema={CreateLocationSchema}
          setOpenId={setLocationId}
          setCheckedLocId={setCheckedLocId}
          mutate={updateLocation}
          refetchLocations={refetch}
        />
      </Show>
    </>
  );
};

export default RoleBusinessLocations;
