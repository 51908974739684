import { Dayjs } from 'dayjs';

import { Box } from '@mui/material';

import { UUID } from 'src/api';
import { EGroupBy, ETimezone } from 'src/api/businessAPI';

import OpenedOffersStats from './OpenedOffersStats';
import CollectedBonesStats from './CollectedBonesStats';
import PurchasedOffersStats from './PurchasedOffersStats';
import ActivatedOffersStats from './ActivatedOffersStats';

export interface IGeneralStatistics {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  groupBy: EGroupBy;
  timezone: ETimezone;
  businessId: UUID;
}

const GeneralStatistics = (props: IGeneralStatistics) => (
  <Box mt={2} position={'relative'}>
    <Box display={'flex'} gap={4} flexWrap={'wrap'}>
      <CollectedBonesStats {...props} />
      <PurchasedOffersStats {...props} />
      <OpenedOffersStats {...props} />
      <ActivatedOffersStats {...props} />
    </Box>
  </Box>
);

export default GeneralStatistics;
