import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { instance } from './index';
import { useSnackbar } from '../components/snackbar';

export const useGetAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useQuery('countries', () => instance.get('countries'), {
    onError(err: AxiosError) {
      enqueueSnackbar(`Countries: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, error, data };
};
