import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from '@mui/material';
import { RootState, useSelector } from 'src/store';
import { ROLE_BUSINESS_MANAGER } from 'src/constants';

interface DynamicTablePaginationProps {
  page: number;
  totalPages: number;
  rowsPerPage: number;
  onChangeRowsPerPage: (e: any) => void;
  onChangePage: (e: any, value: number) => void;
}

const DynamicTablePagination = ({
  page,
  totalPages,
  rowsPerPage,
  onChangeRowsPerPage,
  onChangePage,
}: DynamicTablePaginationProps) => {
  const role = useSelector((state: RootState) => state.account.role);

  if (role === ROLE_BUSINESS_MANAGER) {
    return (
      <Box
        sx={{
          height: 60,
          m: '15px 0 10px',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'baseline',
          marginRight: '26px',
          gap: 3,
        }}
      >
        <Typography fontFamily={'CircularStd'} fontSize={15}>
          Rows per page:
        </Typography>
        <Select
          sx={{ fontSize: 15, fontFamily: 'CircularStd' }}
          size="small"
          value={rowsPerPage.toString()}
          label="Show"
          onChange={onChangeRowsPerPage}
          variant="standard"
          disableUnderline
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <Pagination count={totalPages} page={page} defaultPage={1} onChange={onChangePage} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: 60,
        m: '15px 0 10px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'baseline',
        flexFlow: 'row-reverse',
        marginRight: '26px',
      }}
    >
      <FormControl>
        <InputLabel id="show">Show</InputLabel>
        <Select
          labelId="show"
          value={rowsPerPage.toString()}
          label="Show"
          onChange={onChangeRowsPerPage}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
      <Pagination count={totalPages} page={page} defaultPage={1} onChange={onChangePage} />
    </Box>
  );
};

export default DynamicTablePagination;
