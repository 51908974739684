import { BatchesData, SET_BATCHES } from '../actions/batches';

export interface BatchesState {
  batches: BatchesData[];
  totalPages: number;
  totalElements: number;
}

const initialState: BatchesState = {
  batches: [],
  totalPages: 0,
  totalElements: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const batchesReducer = (state = initialState, action: any): BatchesState => {
  switch (action.type) {
    case SET_BATCHES:
      return {
        batches: action.payload.content,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
};

export default batchesReducer;
