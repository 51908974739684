import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, Button, Fade, Modal, TextField, Typography } from '@mui/material';

import { modalStyles } from 'src/helpers/modalStyles';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';

const OneFieldModal = memo(
  ({
    data,
    label,
    field,
    open,
    schema,
    isLoading,
    setOpened,
    update,
  }: {
    data?: string | null;
    label: string;
    field: string;
    schema: yup.AnyObjectSchema;
    open: string | null;
    isLoading: boolean;
    setOpened: Dispatch<SetStateAction<string | null>>;
    update?: any;
  }) => {
    const [isEdited, setIsEdited] = useState(false);

    const {
      register,
      formState: { errors, isValid, dirtyFields },
      handleSubmit,
      reset,
    } = useForm<yup.Asserts<typeof schema>>({
      mode: 'all',
      resolver: yupResolver(schema),
    });

    const onSubmitHandler: SubmitHandler<yup.Asserts<typeof schema>> = (values) => {
      const id = open;
      const promoText = field === 'promoText' && !values.promoText ? null : values.promoText;
      update({
        id,
        params: { ...values, promoText: field !== 'promoText' ? undefined : promoText },
      });
    };

    const changedFields = Object.keys(dirtyFields);

    useEffect(() => {
      setIsEdited(changedFields.length > 0);
    }, [changedFields]);

    return (
      <Modal
        open={!!open}
        onClose={() => setOpened(null)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!open}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box sx={modalStyles}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Edit/Add {label}
              </Typography>
              <TextField
                sx={{ mb: 2, mt: 2 }}
                label={label}
                fullWidth
                multiline
                required
                defaultValue={data}
                error={fieldError(errors, field)}
                helperText={fieldHelpertext(errors, field)}
                {...register(field)}
              />
              <Box display={'flex'} gap={2}>
                <LoadingButton
                  disabled={!(isValid && isEdited)}
                  loading={isLoading}
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{ py: '0.8rem', mt: '1rem' }}
                >
                  Save
                </LoadingButton>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ py: '0.8rem', mt: '1rem' }}
                  onClick={() => {
                    reset();
                    setOpened(null);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  }
);

export default OneFieldModal;
