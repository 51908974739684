import * as yup from 'yup';

import dayjs from 'dayjs';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, Button, MenuItem, SelectChangeEvent, TextField, Typography } from '@mui/material';

import { UUID } from 'src/api';
import { useGetAPI } from 'src/api/offers';
import { useMutateAPI } from 'src/api/batchBones';
import { RootState, useSelector } from 'src/store';
import { OFFER_ALL_STATUSES } from 'src/constants';
import { LocationData } from 'src/store/actions/businesses';
import { CreateBatchOfBonesSchema } from 'src/utils/validators';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';

import Show from '../../Show/Show';

export type BatchInput = yup.Asserts<typeof CreateBatchOfBonesSchema>;

interface CreateBatchProps {
  locations: LocationData[];
  businessId: UUID;
  setIsCreate: Dispatch<SetStateAction<boolean>>;
}

const CreateBatch = ({ locations, businessId, setIsCreate }: CreateBatchProps) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
  } = useForm<BatchInput>({
    mode: 'all',
    resolver: yupResolver(CreateBatchOfBonesSchema),
  });
  const [locationIds, setLocationIds] = useState<UUID[]>([]);
  const [startDate, setStartDate] = useState(dayjs().toISOString());
  const [endDate, setEndDate] = useState(dayjs().add(10, 'day').toISOString());
  const { isLoading, isSuccess, createBatch } = useMutateAPI();

  useGetAPI({ businessId });

  const offers = useSelector((state: RootState) => state.offers.offers);

  const watchOffer = watch('offerId');

  const onSubmitHandler: SubmitHandler<BatchInput> = (values) => {
    createBatch({
      ...values,
      businessId,
      offerId: values.offerId === 'NONE' ? null : values?.offerId,
      promoText: values.promoText ? values.promoText : null,
    });
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setIsCreate((isCreate) => !isCreate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  useEffect(() => {
    setValue('locationIds', locationIds, { shouldValidate: !!locationIds.length });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationIds]);

  const onKeyDown = (e: KeyboardEvent) => e.preventDefault();

  return (
    <Box sx={{ maxWidth: '25rem' }}>
      <Typography variant="h3" component="h1" paragraph>
        New Ad campaign
      </Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
        <TextField
          sx={{ mb: 2 }}
          label="Name"
          required
          fullWidth
          error={fieldError(errors, 'name')}
          helperText={fieldHelpertext(errors, 'name')}
          {...register('name')}
        />
        <Box sx={{ display: 'flex', mb: 2, gap: 2 }}>
          <Controller
            name={'startDate'}
            control={control}
            defaultValue={startDate}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start date"
                  inputFormat="MM/DD/YYYY"
                  value={startDate}
                  onChange={(newValue: any) => {
                    onChange(newValue.toISOString());
                    if (newValue.toISOString() > endDate) {
                      setEndDate(dayjs(newValue).add(10, 'day').toISOString());
                      setValue('endDate', dayjs(newValue).add(10, 'day').toISOString());
                    }
                    setStartDate(newValue);
                  }}
                  disablePast
                  renderInput={(params: any) => <TextField {...params} onKeyDown={onKeyDown} />}
                  minDate={dayjs()}
                />
              </LocalizationProvider>
            )}
          />
          <Controller
            name={'endDate'}
            control={control}
            defaultValue={endDate}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="End date"
                  inputFormat="MM/DD/YYYY"
                  value={endDate}
                  onChange={(newValue: any) => {
                    onChange(newValue.toISOString());
                    setEndDate(newValue);
                  }}
                  disablePast
                  renderInput={(params: any) => <TextField {...params} onKeyDown={onKeyDown} />}
                  minDate={dayjs(startDate)}
                  maxDate={dayjs(startDate).add(90, 'day')}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
        <TextField
          select
          sx={{ width: '25rem', mb: 2 }}
          label="Location"
          required
          SelectProps={{
            multiple: true,
            value: locationIds,
            onChange: (e: SelectChangeEvent<any>) => setLocationIds(e.target.value),
          }}
          error={fieldError(errors, 'locationIds')}
          helperText={fieldHelpertext(errors, 'locationIds')}
          {...register('locationIds')}
        >
          {locations
            ?.filter(({ status }) => status === OFFER_ALL_STATUSES.ACTIVE)
            ?.map(({ id, title }) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))}
        </TextField>
        <Box display={'flex'} flexDirection={'column'}>
          <TextField
            sx={{ mb: 2 }}
            label="Bones limit"
            required
            type="number"
            defaultValue={50}
            error={fieldError(errors, 'totalBones')}
            helperText={fieldHelpertext(errors, 'totalBones')}
            {...register('totalBones')}
          />
          <TextField
            sx={{ mb: 2 }}
            label="Advertisement"
            multiline={true}
            required
            error={fieldError(errors, 'message')}
            helperText={fieldHelpertext(errors, 'message')}
            {...register('message')}
          />
          <TextField
            select
            sx={{ width: '25rem', mb: 2 }}
            label="Offer"
            defaultValue={'NONE'}
            {...register('offerId')}
          >
            <MenuItem key={'NONE'} value="NONE">
              None
            </MenuItem>
            {offers?.map(({ id, title }) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))}
          </TextField>
          <Show condition={!!watchOffer}>
            <TextField
              sx={{ mb: 2 }}
              label="Promotional text"
              multiline
              error={fieldError(errors, 'promoText')}
              helperText={fieldHelpertext(errors, 'promoText')}
              {...register('promoText')}
            />
          </Show>
        </Box>
        <Box display={'flex'} gap={2}>
          <LoadingButton
            disabled={!isValid}
            loading={isLoading}
            variant="contained"
            type="submit"
            sx={{ py: '0.8rem' }}
          >
            Add new campaign
          </LoadingButton>
          <Button
            variant="contained"
            sx={{ py: '0.8rem' }}
            onClick={() => {
              reset();
              setIsCreate((isCreate) => !isCreate);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateBatch;
