import { MarketplacePerformanceOffersData, SET_MARKETPLACE_PERFORMANCE_OFFERS } from "../actions/marketplacePerformance"

const initialState: MarketplacePerformanceOffersData = {
    content: [],
    pageable: {
        pageNumber: 1,
        pageSize: 0
    },
    totalElements: 0,
    totalPages: 0
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const marketplacePerformanceOffersReducer = (state = initialState, action: any): MarketplacePerformanceOffersData => {
    switch (action.type) {
        case SET_MARKETPLACE_PERFORMANCE_OFFERS: {
            return action.payload
        }
        default: 
            return state
    }
}

export default marketplacePerformanceOffersReducer;