import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'src/store';
import { setBatches } from 'src/store/actions/batches';

import { businessAPI } from '.';
import { useSnackbar } from '../components/snackbar';
import { IGetSortParams } from './businessAPI';

export const useGetAPI = ({ businessId, page, size, sort }: IGetSortParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, error, data } = useQuery(
    ['batches', page, size, sort],
    () => businessAPI.getBatches({ page, size, sort, businessId }),
    {
      onSuccess(data) {
        dispatch(setBatches(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Batch list: ${err.message}`, { variant: 'error' });
      },
    }
  );

  return { isLoading, error, data };
};

export const useMutateAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, isSuccess, mutate } = useMutation(businessAPI.createBatch, {
    onSuccess() {
      queryClient.invalidateQueries('batches');
      enqueueSnackbar(`Batch created successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Batch create: ${(err.response?.data as AxiosError)?.message}`, {
        variant: 'error',
      });
    },
  });

  return { isLoading, isSuccess, createBatch: mutate };
};

export const useStartBatchAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, isSuccess, mutate } = useMutation(businessAPI.startBatch, {
    onSuccess() {
      queryClient.invalidateQueries('batches');
      queryClient.invalidateQueries('campaignDetails');
      enqueueSnackbar(`Batch started successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Batch start: ${err.message}`, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, startBatch: mutate };
};

export const useStopBatchAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, isSuccess, mutate } = useMutation(businessAPI.stopBatch, {
    onSuccess() {
      queryClient.invalidateQueries('batches');
      queryClient.invalidateQueries('campaignDetails');
      enqueueSnackbar(`Batch stoped successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Batch stop: ${err.message}`, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, stopBatch: mutate };
};
