import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Stack, Drawer, useTheme, Button, Typography } from '@mui/material';

import { NAV } from '../../../config';
import { RootState, useSelector } from 'src/store';
import { ROLE_BUSINESS_MANAGER } from 'src/constants';
import { navConfig, businessNavConfig } from './config';
import { useGetBusinessBalanceAPI } from 'src/api/balance';
import { NavSectionVertical } from '../../../components/nav-section';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import Logo from '../../../components/logo';
import Show from 'src/pages/components/Show/Show';
import Scrollbar from '../../../components/scrollbar';
import useResponsive from '../../../hooks/useResponsive';
import SvgColor from 'src/components/svg-color/SvgColor';
import PurchaseBonesComingSoonModal from 'src/components/modal/PurchaseBonesComingSoonModal';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const [opened, setIsOpened] = useState<boolean>(false);
  const { role } = useSelector((state: RootState) => state.account);
  const id = useSelector((state: RootState) => state.businesses?.businesses?.[0]?.id);

  const isDesktop = useResponsive('up', 'lg');

  const { data } = useGetBusinessBalanceAPI(id);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
        },
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={'38px'}
      >
        <Box>
          <Stack
            spacing={3}
            sx={{
              pt: 3,
              pb: 2,
              px: 2.5,
              flexShrink: 0,
            }}
          >
            <Logo sx={{ width: 250, height: 60 }} />
          </Stack>

          <NavSectionVertical
            data={role === ROLE_BUSINESS_MANAGER ? businessNavConfig : navConfig}
          />
        </Box>
        <Show condition={role === ROLE_BUSINESS_MANAGER}>
          <Box width={'100%'}>
            <Box display={'flex'} gap={1} justifyContent={'center'}>
              <SvgColor src="/assets/icons/navbar/ic_bone.svg" sx={{ width: 25, height: 25 }} />
              <BusinessStyledTypograhpy color={theme.palette.mode === 'dark' ? 'unset' : '#475060'}>
                Bones Balance:{' '}
                <Typography
                  fontSize={18}
                  fontFamily={'CircularStd'}
                  fontWeight={'bold'}
                  component={'span'}
                  color={theme.palette.primary.main}
                >
                  {data?.data?.balance}
                </Typography>
              </BusinessStyledTypograhpy>
            </Box>
            <Button
              sx={{ mx: '2rem', mt: '1rem', width: 215, fontFamily: 'CircularStd' }}
              variant="contained"
              onClick={() => setIsOpened(true)}
            >
              Purchase Bones
            </Button>
          </Box>
        </Show>
      </Box>
    </Scrollbar>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV.W_DASHBOARD },
        }}
      >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV.W_DASHBOARD,
                bgcolor: theme.palette.background.default,
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                width: NAV.W_DASHBOARD,
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>
      <Show condition={role === ROLE_BUSINESS_MANAGER}>
        <PurchaseBonesComingSoonModal open={opened} setOpened={setIsOpened} />
      </Show>
    </>
  );
}
