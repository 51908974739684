import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UUID, businessAPI } from './index';
import { useSnackbar } from '../components/snackbar';
import { IGetSortParams } from './businessAPI';
import { dispatch } from 'src/store';
import { setOffers } from 'src/store/actions/offers';

export const useGetAPI = (params: IGetSortParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useQuery(
    ['offers', params],
    () => businessAPI.getOffers(params),
    {
      onSuccess(data) {
        dispatch(setOffers(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Offers: ${err.message}`, { variant: 'error' });
      },
      enabled: !!params?.businessId,
    }
  );

  return { isLoading, error, data };
};

export const useGetOffersStatsAPI = (businessId: UUID | null) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, isSuccess, error, data } = useQuery(
    ['offersStats', businessId],
    () => businessAPI.getOffersStats(businessId),
    {
      onError(err: AxiosError) {
        enqueueSnackbar(`Offers stats: ${err.message}`, { variant: 'error' });
      },
      enabled: !!businessId,
    }
  );

  return { isLoading, isSuccess, error, data };
};

export const useMutateAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data, isLoading, isSuccess, mutate, reset } = useMutation(businessAPI.createOffer, {
    onSuccess() {
      queryClient.invalidateQueries('offers');
      enqueueSnackbar(`Offer created successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Offers: ${err.message}`, { variant: 'error' });
    },
  });

  return { data, isLoading, isSuccess, createOffer: mutate, reset };
};

export const useUpdateAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isLoading, isSuccess, mutate } = useMutation(businessAPI.updateOfferStatus, {
    onSuccess() {
      queryClient.invalidateQueries('offers');
      enqueueSnackbar(`Offer status updated successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Offer status: ${(err.response?.data as AxiosError)?.message}`, {
        variant: 'error',
      });
    },
  });

  return { isLoading, isSuccess, updateStatus: mutate };
};
