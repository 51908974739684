import { UUID } from 'src/api';
import { Page } from 'src/common/page';

export const SET_MARKETPLACE_PERFORMANCE_OFFERS = 'SET_MARKETPLACE_PERFORMANCE_OFFERS';

export interface MarketplaceOfferStatistic {
  id: UUID;
  business: string;
  title: string;
  opened: number;
  purchased: number;
  activated: number;
}

export type MarketplacePerformanceOffersData = Page<MarketplaceOfferStatistic>;

export const setMarketplacePerformanceOffers = (offerData: MarketplacePerformanceOffersData) => ({
  type: SET_MARKETPLACE_PERFORMANCE_OFFERS,
  payload: offerData,
});
