import { UUID } from 'src/api';

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';

export interface ITransaction {
  id: UUID;
  amount: string;
  user: {
    id: UUID;
    name: string;
    image: string;
  };
  business: {
    id: UUID;
    name: string;
    image: string;
  };
  direction: number;
  createdDate: string;
}

export const setTransactions = (transactionsData: ITransaction) => ({
  type: SET_TRANSACTIONS,
  payload: transactionsData,
});
