import { Helmet } from 'react-helmet-async';

import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { useSelector } from 'src/store';
import { useGetAPI } from 'src/api/transactions';
import { TRANSACTIONS_HEAD_CELLS } from 'src/constants';
import { useDynamicTableActions } from 'src/hooks/useDynamicTableActions';

import DynamicTablePagination from '../components/DynamicTablePagination';
import DynamicTableHead from '../components/DynamicTableHead';

const BusinessTopup = () => {
  const theme = useTheme();
  const transactions = useSelector((state) => state.transactions);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    sort,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
  } = useDynamicTableActions('createdDate');

  useGetAPI({ page, size: rowsPerPage, sort });

  return (
    <>
      <Helmet>
        <title>Bones purchase history| LEV</title>
      </Helmet>

      <Box sx={{ width: '100%', padding: '0 20px' }}>
        <Typography variant="h3" paragraph>
          Total bones purchased:{' '}
          <Typography variant="h3" component="span" color={theme.palette.primary.main}>
            {transactions?.totalElements}
          </Typography>
        </Typography>
        <Paper sx={{ width: '100%', overflow: 'hidden', mb: 2 }}>
          <TableContainer
            sx={{
              height: 670,
              '&::-webkit-scrollbar:vertical': {
                display: 'none',
              },

              '&::-webkit-scrollbar': {
                height: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(145, 158, 171, 0.16)',
                borderRadius: '12px',
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table" size={'medium'}>
              <DynamicTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                rowsCount={transactions?.transactions?.length}
                headCells={TRANSACTIONS_HEAD_CELLS}
              />
              <TableBody>
                {transactions?.transactions?.map((transaction) => (
                  <TableRow
                    hover
                    key={transaction?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{transaction?.business?.name}</TableCell>
                    <TableCell align="center">{transaction.amount}</TableCell>
                    <TableCell align="center">{transaction?.user?.name}</TableCell>
                    <TableCell align="center">
                      {new Date(transaction?.createdDate || '').toLocaleDateString()},{' '}
                      {new Date(transaction?.createdDate).toLocaleTimeString('en', {
                        timeStyle: 'short',
                        hour12: false,
                        timeZone: 'UTC',
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <DynamicTablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            totalPages={transactions?.totalPages}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default BusinessTopup;
