import { UUID } from 'src/api';

export const SET_USERS = 'SET_USERS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

export interface UsersData {
  id: UUID;
  email: string;
  name: string;
  bonesBalance?: number;
  totalDogs?: number;
  totalBonesSpent?: number;
  totalBonesSpentLast30Days?: string;
  linkedDogs?: number;
  created: string;
  lastActive: string;
  totalInvited: number;
}

export const setUsers = (usersData: UsersData) => ({
  type: SET_USERS,
  payload: usersData,
});

export const setUserDetails = (usersData: UsersData) => ({
  type: SET_USER_DETAILS,
  payload: usersData,
});
