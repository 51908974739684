/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, IconButton, MenuItem, TextField, Tooltip, Typography } from '@mui/material';

import { UUID } from 'src/api';
import { useUploadFileAPI } from 'src/api/file';
import { useSnackbar } from 'src/components/snackbar';
import { OffersData } from 'src/store/actions/offers';
import { EOfferStatus } from 'src/store/actions/batches';
import { useGetDiscountsAPI } from 'src/api/offerDetails';
import { IconButtonAnimate } from 'src/components/animate';
import { MAX_IMG_SIZE, OFFER_ALL_STATUSES } from 'src/constants';
import { acceptOnlyNumbers, fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import Show from '../../Show/Show';
import ClearIcon from '@mui/icons-material/Clear';
import ImageCroper from 'src/components/modal/ImageCroper';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export const CATEGORIES = [
  { label: 'Discount percentage', value: OFFER_ALL_STATUSES.PERCENTAGE },
  { label: 'Discount amount', value: OFFER_ALL_STATUSES.DISCOUNT_AMOUNT },
  { label: 'Free', value: OFFER_ALL_STATUSES.BUY_ONE_GET_ONE_FREE },
];

export const DISCOUNT_TYPES = [
  { label: 'Generate', value: OFFER_ALL_STATUSES.GENERATE_CODES },
  { label: 'Upload', value: OFFER_ALL_STATUSES.MANUALLY },
];

export const TYPES = [
  { label: 'Promo code', value: OFFER_ALL_STATUSES.PROMO_CODE },
  { label: 'Timed coupon', value: OFFER_ALL_STATUSES.TIMED_COUPON },
];

export const STATUSES = [
  { label: 'Active', value: OFFER_ALL_STATUSES.ACTIVE },
  { label: 'Paused', value: OFFER_ALL_STATUSES.PAUSED },
  { label: 'Empty', value: OFFER_ALL_STATUSES.EMPTY },
  { label: 'Not active', value: OFFER_ALL_STATUSES.NOT_ACTIVE },
  { label: 'Completed', value: OFFER_ALL_STATUSES.COMPLETED },
];

export const FORMATS = ['image/jpeg', 'image/png', 'image/heif', 'image/heic'];

export const TITLE = (
  <Box>
    <BusinessStyledTypograhpy sx={{ fontSize: 10 }}>
      Timed coupons will have a specified expiration time and users will have a limited window of
      time to activate the offer and show it at the cash desk.
    </BusinessStyledTypograhpy>
    <br />
    <BusinessStyledTypograhpy sx={{ fontSize: 10 }}>
      Promo codes will be displayed in the mobile app, allowing users to copy and apply them during
      checkout in your store.
    </BusinessStyledTypograhpy>
  </Box>
);

export interface CreateEditOfferProps {
  data?: OffersData;
  open?: boolean;
  businessId?: UUID;
  offerId?: UUID;
  schema: yup.AnyObjectSchema;
  isLoading: boolean;
  isSuccess: boolean;
  hasFlow?: boolean;
  mutate: any;
  resetMutation: () => void;
  removeDetailsCache?: () => void;
  setIsAction?: Dispatch<SetStateAction<boolean>>;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  setHasFlow?: Dispatch<SetStateAction<boolean>>;
  setOfferStatus?: Dispatch<SetStateAction<EOfferStatus>>;
}

const CreateEditOffer = ({
  data,
  businessId,
  offerId = '',
  schema,
  isLoading,
  isSuccess,
  hasFlow,
  mutate,
  resetMutation,
  setIsAction = () => {},
  setActiveStep = () => {},
  setHasFlow = () => {},
}: CreateEditOfferProps) => {
  const {
    register,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    resetField,
  } = useForm<yup.Asserts<typeof schema>>({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const [imageType, setImageType] = useState('');
  const [imageName, setImageName] = useState('');
  const [isEdited, setIsEdited] = useState(false);
  const [isCroperOpened, setIsCroperOpened] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>(data?.banner || '');
  const [imageFileData, setImageFileData] = useState<any>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { discountsData } = useGetDiscountsAPI({ id: offerId });
  const {
    fileData,
    uploadFile,
    isLoading: loading,
    isSuccess: success,
    reset: resetUploadMutation,
  } = useUploadFileAPI();

  const watchType = watch('type');
  const watchCategory = watch('category');
  const watchDiscountType = watch('discountType');

  const changedFields = Object.keys(dirtyFields);
  const label = data ? 'Update' : 'Create';

  const onSubmitHandler: SubmitHandler<yup.Asserts<typeof schema>> = (values) => {
    const params = {
      ...values,
      businessId,
      totalOffersLimit:
        values.discountType === OFFER_ALL_STATUSES.MANUALLY ? undefined : values.totalOffersLimit,
      activationTime: values.type === OFFER_ALL_STATUSES.TIMED_COUPON ? 15 : undefined,
      websiteUrl: values.type === OFFER_ALL_STATUSES.TIMED_COUPON ? null : values.websiteUrl,
    };
    setImageFileData(null);
    mutate(offerId ? { id: offerId, params } : params);
  };

  const handleDelete = () => {
    setPreviewUrl('');
    setValue('banner', '');
    setError('banner', { type: 'custom', message: 'image is required' });
    setIsEdited(true);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    if (!FORMATS.includes(file.type)) {
      return enqueueSnackbar('Unacceptable image type', { variant: 'error' });
    }

    if (file.size > MAX_IMG_SIZE) {
      return enqueueSnackbar('Size should be 4.2MB or less', { variant: 'error' });
    }

    setPreviewUrl(URL.createObjectURL(file));
    setImageName(file.name);
    setImageType(file.type);
    setIsCroperOpened(!isCroperOpened);
  };

  useEffect(() => {
    if (watchDiscountType === OFFER_ALL_STATUSES.MANUALLY) {
      setValue('totalOffersLimit', 1);
      setHasFlow(true);
    }
    if (watchDiscountType === OFFER_ALL_STATUSES.GENERATE_CODES) {
      setHasFlow(false);
    }
  }, [watchDiscountType]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (watchDiscountType === OFFER_ALL_STATUSES.MANUALLY && !data) {
        return setActiveStep((prevStep) => prevStep + 1);
      }
      if (!hasFlow) {
        resetMutation();
      }
      setIsAction(false);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (!loading && success && fileData) {
      setImageFileData(fileData);
      resetUploadMutation();
    }
  }, [loading, success, fileData]);

  useEffect(() => {
    if (imageFileData?.data?.id)
      setValue('banner', imageFileData?.data?.id, { shouldValidate: true });
  }, [imageFileData]);

  useEffect(() => {
    if (!loading && success) {
      setValue('banner', fileData?.data?.id, { shouldValidate: true });
    }
  }, [fileData]);

  useEffect(() => {
    if (data) {
      const bannerId = data?.banner.slice(-36);
      setValue('banner', bannerId, { shouldValidate: true });
    }
  }, [data]);

  useEffect(() => {
    const fieldsLength = changedFields.length > 0;
    if (fieldsLength) {
      setIsEdited(fieldsLength);
    }
  }, [changedFields]);

  useEffect(() => {
    if (discountsData?.data.content?.length) {
      setValue('discountType', data?.discountType);
    }
  }, [discountsData]);

  useEffect(() => {
    if (watchType === OFFER_ALL_STATUSES.TIMED_COUPON) {
      setValue('discountType', OFFER_ALL_STATUSES.GENERATE_CODES, { shouldValidate: true });
    }
  }, [watchType]);

  useEffect(() => {
    if (watchCategory === OFFER_ALL_STATUSES.PERCENTAGE) {
      resetField('amount');
      resetField('retailPrice');
      return;
    }
    if (watchCategory === OFFER_ALL_STATUSES.DISCOUNT_AMOUNT) {
      resetField('retailPrice');
      resetField('averageOrderValue');
      resetField('discountPercentage');
      return;
    }
    resetField('amount');
    resetField('averageOrderValue');
    resetField('discountPercentage');
  }, [watchCategory]);

  useEffect(() => setHasFlow(true), []);

  return (
    <Box sx={{ maxWidth: '50rem' }}>
      <Typography variant="h3" component="h1" paragraph>
        {label} Offer
      </Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
        <Box display={'flex'} gap={2}>
          <Box display={'flex'} flexDirection={'column'} width={400}>
            <Box display={'flex'} sx={{ mb: 2 }} gap={2}>
              <TextField
                label="Type"
                select
                fullWidth
                defaultValue={data?.type || OFFER_ALL_STATUSES.TIMED_COUPON}
                error={fieldError(errors, 'type')}
                helperText={fieldHelpertext(errors, 'type')}
                {...register('type')}
              >
                {TYPES.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
              <Tooltip title={TITLE}>
                <IconButtonAnimate>
                  <QuestionMarkIcon />
                </IconButtonAnimate>
              </Tooltip>
            </Box>
            <TextField
              sx={{ mb: 2 }}
              label="Title"
              required
              defaultValue={data?.title}
              error={fieldError(errors, 'title')}
              helperText={fieldHelpertext(errors, 'title')}
              {...register('title')}
            />
            <TextField
              sx={{ mb: 2 }}
              label="Category"
              select
              defaultValue={data?.category || OFFER_ALL_STATUSES.PERCENTAGE}
              error={fieldError(errors, 'category')}
              helperText={fieldHelpertext(errors, 'category')}
              {...register('category')}
            >
              {CATEGORIES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <Show condition={watchCategory === OFFER_ALL_STATUSES.DISCOUNT_AMOUNT}>
              <TextField
                sx={{ mb: 2 }}
                label="Amount"
                required
                type="number"
                onKeyDown={(event) => acceptOnlyNumbers(event)}
                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                defaultValue={data?.amount || 5}
                error={fieldError(errors, 'amount')}
                helperText={fieldHelpertext(errors, 'amount')}
                {...register('amount')}
              />
            </Show>
            <Show condition={watchCategory === OFFER_ALL_STATUSES.PERCENTAGE}>
              <>
                <TextField
                  sx={{ mb: 2 }}
                  label="Percentage"
                  required
                  type="number"
                  onKeyDown={(event) => acceptOnlyNumbers(event)}
                  onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                  defaultValue={data?.discountPercentage || 5}
                  error={fieldError(errors, 'discountPercentage')}
                  helperText={fieldHelpertext(errors, 'discountPercentage')}
                  {...register('discountPercentage')}
                />
                <TextField
                  sx={{ mb: 2 }}
                  label="Average order value"
                  required
                  type="number"
                  onKeyDown={(event) => acceptOnlyNumbers(event)}
                  onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                  defaultValue={data?.averageOrderValue || 5}
                  error={fieldError(errors, 'averageOrderValue')}
                  helperText={fieldHelpertext(errors, 'averageOrderValue')}
                  {...register('averageOrderValue')}
                />
              </>
            </Show>
            <Show condition={watchCategory === OFFER_ALL_STATUSES.BUY_ONE_GET_ONE_FREE}>
              <TextField
                sx={{ mb: 2 }}
                label="Retail price"
                required
                type="number"
                onKeyDown={(event) => acceptOnlyNumbers(event)}
                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                defaultValue={data?.retailPrice || 5}
                error={fieldError(errors, 'retailPrice')}
                helperText={fieldHelpertext(errors, 'retailPrice')}
                {...register('retailPrice')}
              />
            </Show>
            <Show condition={watchType !== OFFER_ALL_STATUSES.TIMED_COUPON}>
              <TextField
                sx={{ mb: 2 }}
                label="Codes type"
                select
                defaultValue={data?.discountType || OFFER_ALL_STATUSES.GENERATE_CODES}
                disabled={!!discountsData?.data.content?.length}
                error={fieldError(errors, 'discountType')}
                helperText={fieldHelpertext(errors, 'discountType')}
                {...register('discountType')}
              >
                {DISCOUNT_TYPES.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Show>
            <Box display={'flex'}>
              <LoadingButton
                variant="contained"
                component="label"
                sx={{ mb: 2, mr: '1rem' }}
                startIcon={<AddPhotoAlternateIcon />}
                loading={loading}
                disabled={data ? !!previewUrl : !!previewUrl && !!imageFileData}
              >
                Upload Image
                <input type="file" key={previewUrl} hidden onChange={handleFileUpload} />
              </LoadingButton>
              <Show condition={(imageFileData || data) && !!previewUrl}>
                <Box
                  sx={{
                    width: 50,
                    '&:hover button': {
                      display: 'inline-flex',
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      backgroundColor: 'black',
                      padding: 0,
                      zIndex: 2000,
                    },
                  }}
                  position={'relative'}
                >
                  <Box
                    component="img"
                    display={'inline-block'}
                    sx={{
                      height: 36,
                      width: '100%',
                      borderRadius: '20%',
                    }}
                    alt="banner"
                    src={previewUrl}
                  />
                  <IconButton
                    sx={{
                      display: 'none',
                    }}
                    onClick={handleDelete}
                  >
                    <ClearIcon fontSize="small" color="primary" />
                  </IconButton>
                </Box>
              </Show>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} width={400}>
            <TextField
              sx={{ mb: 2 }}
              label="Short Description"
              multiline
              required
              defaultValue={data?.shortDescription}
              error={fieldError(errors, 'shortDescription')}
              helperText={fieldHelpertext(errors, 'shortDescription')}
              {...register('shortDescription')}
            />
            <TextField
              sx={{ mb: 2 }}
              label="Full description"
              multiline
              required
              defaultValue={data?.description}
              error={fieldError(errors, 'description')}
              helperText={fieldHelpertext(errors, 'description')}
              {...register('description')}
            />
            <Show condition={!data && watchDiscountType === OFFER_ALL_STATUSES.GENERATE_CODES}>
              <TextField
                sx={{ mb: 2 }}
                label="Offers limit"
                required
                type="number"
                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                onKeyDown={(event) => acceptOnlyNumbers(event)}
                defaultValue={1}
                error={fieldError(errors, 'totalOffersLimit')}
                helperText={fieldHelpertext(errors, 'totalOffersLimit')}
                {...register('totalOffersLimit')}
              />
            </Show>
            <Show condition={watchType === OFFER_ALL_STATUSES.PROMO_CODE}>
              <TextField
                sx={{ mb: 2 }}
                label="URL"
                required
                defaultValue={data?.websiteUrl}
                error={fieldError(errors, 'websiteUrl')}
                helperText={fieldHelpertext(errors, 'websiteUrl')}
                {...register('websiteUrl')}
              />
            </Show>
            <Show condition={!data}>
              <TextField
                InputProps={{
                  type: 'hidden',
                }}
                sx={{ visibility: 'hidden' }}
                value={uuidv4()}
                {...register('id')}
              />
            </Show>
          </Box>
        </Box>
        <Show condition={!data}>
          <Typography fontSize={16} mb={2}>
            The offers will be priced in digital bones automatically. 80% of bones spent on this
            offer by LEV users will be credited to your account.
          </Typography>
        </Show>
        <Box display={'flex'} gap={2}>
          <LoadingButton
            disabled={!(isValid && isEdited)}
            loading={isLoading}
            variant="contained"
            type="submit"
            sx={{ py: '0.8rem' }}
          >
            {label} Offer
          </LoadingButton>
          <Button
            variant="contained"
            sx={{ py: '0.8rem' }}
            onClick={() => {
              reset();
              setIsAction(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
      <Show condition={isCroperOpened}>
        <ImageCroper
          open={isCroperOpened}
          previewUrl={previewUrl as string}
          imageName={imageName}
          imageType={imageType}
          setIsOpen={setIsCroperOpened}
          setPreviewUrl={setPreviewUrl}
          uploadFile={uploadFile}
          loading={loading}
          success={success}
        />
      </Show>
    </Box>
  );
};

export default CreateEditOffer;
