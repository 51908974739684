import { SET_USERS, UsersData } from '../actions/user';

export interface UsersState {
  users: UsersData[];
  totalPages: number;
  totalElements: number;
}

const initialState: UsersState = {
  users: [],
  totalPages: 0,
  totalElements: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const usersReducer = (state = initialState, action: any): UsersState => {
  switch (action.type) {
    case SET_USERS:
      return {
        users: action.payload.content,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
};

export default usersReducer;
