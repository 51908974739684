import dayjs from 'dayjs';
import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';

import { Box, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import { ALL } from 'src/constants';
import { useSelector } from 'src/store';
import { getOrNull } from 'src/helpers/formHeleprs';
import { EOfferLinkSource } from 'src/api/businessAPI';
import { StyledMenuItem } from './PurchasedOffersStats';
import { IGeneralStatistics } from './GeneralStatistics';
import { useGetOpenedOffersStatsAPI } from 'src/api/statistics';
import { StyledTextField } from 'src/pages/components/RegisterSteps/Register';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import CustomTooltip from './CustomToolTip';

const SOURCE = [
  { label: 'Marketplace', value: EOfferLinkSource.FROM_MARKETPLACE },
  { label: 'Bone collecting', value: EOfferLinkSource.FROM_BONE },
];

const OpenedOffersStats = ({
  startDate,
  endDate,
  timezone,
  groupBy,
  businessId,
}: IGeneralStatistics) => {
  const theme = useTheme();

  const [offerId, setOfferId] = useState(ALL);
  const [locationId, setLocationId] = useState(ALL);
  const [source, setSource] = useState<EOfferLinkSource | 'ALL'>(ALL);
  const [byUserUniq, setByUserUniq] = useState<boolean>(false);

  const offers = useSelector((state) => state.offers.offers);
  const locations = useSelector((state) => state.businessDetailInfo.locations);
  const openedOffers = useSelector((state) => state.statistics.openedOffers);

  useGetOpenedOffersStatsAPI({
    source: getOrNull(source, ALL),
    offerId: getOrNull(offerId, ALL),
    locationId: getOrNull(locationId, ALL),
    startDate: startDate || dayjs().subtract(7, 'day'),
    endDate: endDate || dayjs(),
    groupBy,
    timezone,
    businessId,
    byUserUniq,
  });

  return (
    <Box>
      <Box display={'flex'} gap={2}>
        <BusinessStyledTypograhpy fontSize={'26px'}>
          Offers opening:{' '}
          <Typography
            fontFamily={'CircularStd'}
            fontSize={'26px'}
            component="span"
            color={theme.palette.primary.main}
          >
            {openedOffers?.totalOffers}
          </Typography>
        </BusinessStyledTypograhpy>
        <FormControlLabel
          control={<Checkbox checked={byUserUniq} onChange={() => setByUserUniq(!byUserUniq)} />}
          label={<BusinessStyledTypograhpy children={'Unique users'} />}
        />
      </Box>
      <Box display={'flex'} gap={7}>
        <StyledTextField
          select
          sx={{ width: '10rem', mt: 2 }}
          label="Offer"
          defaultValue={offerId}
          onChange={(e) => setOfferId(e.target.value)}
        >
          <StyledMenuItem value="ALL" selected={true}>
            All
          </StyledMenuItem>
          {offers?.map(({ id, title }) => (
            <StyledMenuItem key={id} value={id}>
              {title}
            </StyledMenuItem>
          ))}
        </StyledTextField>
        <StyledTextField
          select
          sx={{ width: '10rem', mt: 2 }}
          label="Location"
          defaultValue={locationId}
          onChange={(e) => setLocationId(e.target.value)}
        >
          <StyledMenuItem value="ALL" selected={true}>
            All
          </StyledMenuItem>
          {locations?.map(({ id, title }) => (
            <StyledMenuItem key={id} value={id}>
              {title}
            </StyledMenuItem>
          ))}
        </StyledTextField>
        <StyledTextField
          select
          sx={{ width: '10rem', mt: 2 }}
          label="Source"
          defaultValue={source}
          onChange={(e) => setSource(e.target.value as EOfferLinkSource)}
        >
          <StyledMenuItem value="ALL" selected={true}>
            All
          </StyledMenuItem>
          {SOURCE?.map(({ label, value }) => (
            <StyledMenuItem key={value} value={value}>
              {label}
            </StyledMenuItem>
          ))}
        </StyledTextField>
      </Box>
      <BarChart
        width={650}
        height={500}
        data={openedOffers?.openedOffers}
        margin={{
          top: 20,
          right: 0,
          left: -20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          content={<CustomTooltip openedOffers={'offers opened'} />}
          cursor={{ fillOpacity: '25%' }}
        />
        <Legend />
        <Bar
          name="opened offers"
          dataKey="totalOffers"
          stackId="a"
          fill={theme.palette.primary.main}
        />
      </BarChart>
    </Box>
  );
};

export default OpenedOffersStats;
