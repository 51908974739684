import { CampaignsData, SET_CAMPAIGN_DETAILS } from '../actions/campaigns';

const initialState: CampaignsData = {
  id: '',
  name: '',
  startDate: '',
  endDate: '',
  status: '',
  totalBones: 0,
  bonesRemaining: 0,
  promoText: null,
  campaignAdvertisement: '',
  createdDate: '',
  offer: {
    id: '',
    name: '',
  },
  locations: [
    {
      id: '',
      batchBonesId: '',
      name: '',
      batchBonesStatus: '',
      totalBones: 0,
      bonesRemaining: 0,
      latitude: 0,
      longitude: 0,
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const campaignDetailsReducer = (state = initialState, action: any): CampaignsData => {
  switch (action.type) {
    case SET_CAMPAIGN_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default campaignDetailsReducer;
