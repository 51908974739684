import * as yup from 'yup';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EditBusinessBasicInfoSchema } from 'src/utils/validators';
import { useMutateAPI } from 'src/api/businessDetails';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { UUID } from 'src/api';
import { EDIT_MODE } from './BusinessCard';

export interface BusinessBasicInfoUpdaterProps {
  id: UUID;
  name: string;
  description: string;
  setEditMode: Dispatch<SetStateAction<EDIT_MODE>>;
}

type BusinessBasicInfoInput = yup.Asserts<typeof EditBusinessBasicInfoSchema>;

const BusinessBasicInfoUpdater = ({
  id,
  name,
  description,
  setEditMode,
}: BusinessBasicInfoUpdaterProps) => {
  const [isEdited, setIsEdited] = useState(false);

  const {
    register,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    reset,
  } = useForm<BusinessBasicInfoInput>({
    mode: 'all',
    resolver: yupResolver(EditBusinessBasicInfoSchema),
  });

  const { isLoading, isSuccess, updateBasicInfo } = useMutateAPI(reset);

  const onSubmitHandler: SubmitHandler<BusinessBasicInfoInput> = (values) => {
    updateBasicInfo(values);
  };

  //it's a temporary thing
  const changedFields = Object.keys(dirtyFields);

  useEffect(() => {
    setIsEdited(changedFields.length > 0);
  }, [changedFields]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setEditMode(EDIT_MODE.OFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
      <TextField
        sx={{ mb: 2 }}
        label="Name"
        fullWidth
        required
        defaultValue={name}
        error={fieldError(errors, 'name')}
        helperText={fieldHelpertext(errors, 'name')}
        {...register('name')}
      />
      <TextField
        sx={{ mb: 2 }}
        label="Description"
        fullWidth
        required
        type="text"
        multiline
        defaultValue={description}
        error={fieldError(errors, 'description')}
        helperText={fieldHelpertext(errors, 'description')}
        {...register('description')}
      />
      <TextField
        InputProps={{
          type: 'hidden',
        }}
        sx={{ visibility: 'hidden' }}
        value={id}
        {...register('id')}
      />
      <Box display={'flex'} gap={2}>
        <LoadingButton
          disabled={!(isValid && isEdited)}
          loading={isLoading}
          variant="contained"
          fullWidth
          type="submit"
          sx={{ py: '0.8rem' }}
        >
          Save Changes
        </LoadingButton>
        <Button
          variant="contained"
          fullWidth
          sx={{ py: '0.8rem' }}
          onClick={() => {
            reset();
            setEditMode(EDIT_MODE.OFF);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
export default BusinessBasicInfoUpdater;
