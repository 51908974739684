import { useState } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { useGetAPI } from 'src/api/userDetails';
import { IconButtonAnimate } from 'src/components/animate';
import { history, RootState, useSelector } from 'src/store';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { DOG_INFO_HEAD_CELLS } from 'src/constants';
import { UserTopupSchema } from 'src/utils/validators';
import { useTopupUserBalanceAPI } from 'src/api/balance';

import Show from '../Show/Show';
import UserCard from './UserCard';
import DynamicTableHead from '../DynamicTableHead';
import TopupBonesBalanceModal from 'src/components/modal/TopupBonesBalanceModal';

const DetailedUserDescription = () => {
  const [shouldAddBones, setShouldAddBones] = useState<boolean>(false);
  const { id = '' } = useParams();
  const userDetailInfo = useSelector((state: RootState) => state.userDetailInfo);

  useGetAPI(id);
  const { isLoading, isSuccess, topupUserBalance, reset } = useTopupUserBalanceAPI();

  return (
    <>
      <Helmet>
        <title>User Detailed View | LEV</title>
      </Helmet>
      <Box sx={{ width: '100%', padding: '0 20px', display: 'flex', flexWrap: 'nowrap' }}>
        <Box marginRight={1}>
          <IconButtonAnimate color="primary" onClick={() => history.back()} sx={{ p: 1.25 }}>
            <ArrowBackIcon />
          </IconButtonAnimate>
        </Box>
        <Box flexGrow={1}>
          <UserCard {...userDetailInfo} />
          <Button
            variant="contained"
            sx={{ mt: '1rem' }}
            onClick={() => setShouldAddBones(!shouldAddBones)}
          >
            Add bones
          </Button>
          <Box paddingTop={4}>
            <Typography variant="h3" component="h1" paragraph>
              Dogs Info
            </Typography>
            {userDetailInfo?.dogs?.length ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <DynamicTableHead headCells={DOG_INFO_HEAD_CELLS} />
                  <TableBody>
                    {userDetailInfo?.dogs?.map((dog: any) => (
                      <TableRow
                        key={dog.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{
                            borderRadius: '50%',
                          }}
                        >
                          {dog?.avatar ? (
                            <Box
                              component="img"
                              display={'inline-block'}
                              sx={{
                                height: 60,
                                width: 60,
                                borderRadius: '50%',
                                maxHeight: { xs: 233, md: 167 },
                                maxWidth: { xs: 350, md: 250 },
                              }}
                              alt="dog"
                              src={dog.avatar.url}
                            />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell align="center">{dog?.name}</TableCell>
                        <TableCell align="center">{dog?.breed.name}</TableCell>
                        <TableCell align="center">{dog?.gender}</TableCell>
                        <TableCell align="center">{dog?.birthday || '-'}</TableCell>
                        <TableCell align="center">{dog?.weight || '-'}</TableCell>
                        <TableCell align="center">{dog?.height || '-'}</TableCell>
                        <TableCell align="center">{dog?.bio || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h4" component="h1" paragraph>
                There are no dogs
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Show condition={shouldAddBones}>
        <TopupBonesBalanceModal
          isUser
          id={id}
          open={shouldAddBones}
          schema={UserTopupSchema}
          isLoading={isLoading}
          isSuccess={isSuccess}
          mutate={topupUserBalance}
          resetMutation={reset}
          setIsOpen={setShouldAddBones}
        />
      </Show>
    </>
  );
};

export default DetailedUserDescription;
