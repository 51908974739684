import { UUID } from 'src/api';

export const GET_OFFERS = 'GET_OFFERS';
export const SET_OFFERS = 'SET_OFFERS';
export const SET_OFFER_DETAILS = 'SET_OFFER_DETAILS';

export interface OffersData {
  id: UUID;
  banner: UUID;
  business: {
    id: UUID;
    name: string;
  };
  title: string;
  shortDescription: string;
  description: string;
  promoText: string;
  category: string;
  type: string;
  discountType: string;
  status: string;
  price: number;
  offersLeft: number;
  totalOffersLimit: number;
  totalOffers: number;
  isUseInBatchBones: boolean;
  websiteUrl?: string;
  amount: number;
  discountPercentage: number;
  averageOrderValue: number;
  retailPrice: number;
  createdDate: string;
}

export const setOffers = (offersData: OffersData[]) => ({
  type: SET_OFFERS,
  payload: offersData,
});

export const setOfferDetails = (offerData: OffersData) => ({
  type: SET_OFFER_DETAILS,
  payload: offerData,
});
