import AvatarEditor from 'react-avatar-editor';
import imageCompression from 'browser-image-compression';

import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { Backdrop, Box, Fade, Modal, Typography, useTheme } from '@mui/material';
import { modalStyles } from 'src/helpers/modalStyles';
import { fileCreator } from 'src/helpers/formHeleprs';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { LoadingButton } from '@mui/lab';

export interface IImageCroper {
  open: boolean;
  loading: boolean;
  success: boolean;
  imageName: string;
  imageType: string;
  previewUrl: string;
  uploadFile: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setPreviewUrl: Dispatch<SetStateAction<string>>;
}

const ImageCroper = ({
  open,
  loading,
  success,
  imageName,
  imageType,
  previewUrl,
  uploadFile,
  setIsOpen,
  setPreviewUrl,
}: IImageCroper) => {
  const theme = useTheme();
  const [scale, setScale] = useState(1);
  const editorRef = useRef<AvatarEditor>();

  const setEditorRef = async (editor: AvatarEditor) => (editorRef.current = editor);

  useEffect(() => {
    if (!loading && success) {
      setIsOpen(!open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, success]);

  const handleSave = async () => {
    if (editorRef.current) {
      const dataUrl = editorRef.current?.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      const newFile = fileCreator(blob, imageName, imageType);

      const compressedFileBlob = await imageCompression(newFile, { maxSizeMB: 4.2 });
      const compressedFile = fileCreator(compressedFileBlob, imageName, imageType);

      const formData = new FormData();
      formData.append('file', compressedFile);
      await uploadFile(formData);

      setPreviewUrl(URL.createObjectURL(compressedFile));
    }
  };

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  return (
    <Modal
      open={open}
      onClose={() => setIsOpen(!open)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyles}>
          <Typography variant="h4" textAlign={'center'} mb={1} fontFamily={'CircularStd'}>
            Move or scale
          </Typography>
          <Box display={'flex'} mb={1}>
            <Typography component="p" mr={1} fontSize={18} fontFamily={'CircularStd'}>
              Scale:
            </Typography>
            <input
              style={{
                accentColor: theme.palette.primary.main,
              }}
              type={'range'}
              onChange={handleScale}
              min={1}
              max="2"
              step="0.01"
              defaultValue={scale}
            />
          </Box>
          <AvatarEditor
            ref={(ref) => setEditorRef(ref as AvatarEditor)}
            image={previewUrl}
            width={393}
            height={295}
            border={50}
            scale={scale}
          />
          <Box>
            <LoadingButton
              variant="contained"
              component="label"
              startIcon={<FileUploadIcon />}
              sx={{ mt: '1rem', fontFamily: 'CircularStd' }}
              onClick={() => handleSave()}
              loading={loading}
            >
              Save changes
            </LoadingButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ImageCroper;
