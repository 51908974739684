import * as yup from 'yup';

import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Link } from '@mui/material';

import Show from '../components/Show/Show';
import Logo from 'src/components/logo/Logo';
import LoginLayout from 'src/layouts/login/LoginLayout';
import VerifyAccount from '../components/RegisterSteps/VerifyAccount';

import { LoadingButton } from '@mui/lab';
import { useSendCodeAPI } from 'src/api/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RegisterBusinessSchema } from 'src/utils/validators';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { StyledTextField } from '../components/RegisterSteps/Register';
import { BusinessStyledTypograhpy } from '../RoleBusinessLocations/RoleBusinessLocations';

type SignInBusinessInput = yup.Asserts<typeof RegisterBusinessSchema>;

const BusinessManagerLogin = () => {
  const [email, setEmail] = useState<string>('');

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<SignInBusinessInput>({
    mode: 'all',
    resolver: yupResolver(RegisterBusinessSchema),
  });

  const { isLoading, isSuccess, sendCode } = useSendCodeAPI();

  const onSubmitHandler: SubmitHandler<SignInBusinessInput> = (values) => {
    sendCode(values?.email as string);
    setEmail(values?.email as string);
  };

  return (
    <>
      <LoginLayout>
        <Helmet>
          <title>Sign in| LEV</title>
        </Helmet>
        <Logo sx={{ width: 144, height: 36 }} />
        <Show
          condition={!isSuccess}
          or={
            <Box sx={{ maxWidth: '35rem', m: '48px auto 0' }}>
              <VerifyAccount isLogin email={email} />
            </Box>
          }
        >
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box sx={{ maxWidth: '25rem', m: '48px auto 0' }}>
              <BusinessStyledTypograhpy fontSize={22} fontWeight={'bold'} sx={{ mb: 1 }}>
                Sign in to LEV Admin Panel
              </BusinessStyledTypograhpy>
              <StyledTextField
                sx={{ mb: 2 }}
                label="Email Address"
                fullWidth
                required
                error={fieldError(errors, 'email')}
                helperText={fieldHelpertext(errors, 'email')}
                {...register('email')}
              />
              <LoadingButton
                disabled={!isValid}
                loading={isLoading}
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  fontSize: 18,
                  fontFamily: 'CircularStd',
                  height: 50,
                }}
              >
                Next
              </LoadingButton>
              <Box>
                <BusinessStyledTypograhpy fontWeight={500} fontSize={14} sx={{ mt: 6 }}>
                  Don't have an account?
                </BusinessStyledTypograhpy>
                <Link
                  href="/register"
                  underline="always"
                  fontWeight={500}
                  fontSize={14}
                  fontFamily={'CircularStd'}
                >
                  Register a business and start advertising with LEV
                </Link>
              </Box>
            </Box>
          </Box>
        </Show>
      </LoginLayout>
    </>
  );
};

export default BusinessManagerLogin;
