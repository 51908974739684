import {useState, ReactNode, useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useKeycloak } from 'src/hooks/useKeycloak';
// components
import LoadingScreen from '../components/loading-screen';
//

import {useGetAPI as useGetBusinessesAPI, useGetAPI} from 'src/api/businesses';
import { RootState, useSelector } from 'src/store';
import { getRegisterInProgress } from 'src/Keycloak';
import {EBusinessRegStatus, EUserRole, ROLE_ADMIN, ROLE_BUSINESS_MANAGER} from 'src/constants';
import useLocalStorage from "../hooks/useLocalStorage";

// ----------------------------------------------------------------------

const allowPaths = [
  '/business/dashboard',
  '/business/campaigns',
  '/business/locations',
  '/business/offers',
  '/business/analytics',
  '/business/account'
];

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const [roles, ] = useLocalStorage('roles', []);

  const { account, businesses } = useSelector((state: RootState) => state);
  const { authenticated, initialized } = useKeycloak();
  const { pathname } = useLocation();

  const isBusinessExists = businesses?.businesses?.length;
  const isManagerRole = account?.role === ROLE_BUSINESS_MANAGER;


  const {data, isLoading, isSuccess, error} = useGetBusinessesAPI({ page: 1, size: 1, enabled: authenticated  });


  useEffect(() => {

    const isBusinessInfoCompleted = localStorage.getItem('businessRegistrationInfo') === EBusinessRegStatus.COMPLETED;

    if (!isLoading && isSuccess && !error) {
      const firstBusiness =  data?.data?.content[0];
      if (!firstBusiness && !isBusinessInfoCompleted) {
        localStorage.setItem('registerInProgress', 'inProgress');
      } else {
        localStorage.removeItem('registerInProgress');
      }
    }


  },[isLoading, isSuccess, data, error])

  useGetAPI({
    page: 1,
    size: 10,
    enabled:
      !isBusinessExists && authenticated && isManagerRole,
  });

  if (isLoading) {
    return <LoadingScreen />;
  }


  if (authenticated && (getRegisterInProgress() || !isBusinessExists)) {

    const keycloakToken = localStorage.getItem("keycloakToken");
    const lastRoleAccount = localStorage.getItem("currentRole");

    if (!keycloakToken) {
      if (lastRoleAccount === ROLE_BUSINESS_MANAGER) {
        return <Navigate to="/manager-login" />;
      } else if (lastRoleAccount === ROLE_ADMIN) {
        return <Navigate to="/admin-login" />;
      }
    }
    return <Navigate to="/register" />;
  }

  if (!initialized) {
    return <LoadingScreen />;
  }

  if (isBusinessExists) {
    if (
      authenticated && isManagerRole &&
      allowPaths.every((path) => pathname.indexOf(path) === -1)
    ) {
      return <Navigate to={'/dashboard/business/dashboard'} />;
    }
  }

  if (
    authenticated && !isManagerRole && account.keycloakToken &&
    allowPaths.some((path) => pathname.indexOf(path) > -1)
  ) {
    return <Navigate to={'/dashboard/businesses'} />;
  }

  if (!authenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    if (roles.includes(EUserRole.ROLE_ADMIN)) {
      return <Navigate to={'/admin-login'} />;
    } else {
      return <Navigate to={'/manager-login'} />;
    }

  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
