import { Theme } from '@mui/system';

const confirmModalStyles = (theme: Theme, text: string) => ({
  titleProps: {
    sx: { color: theme.palette.text.primary },
  },
  dialogProps: {
    sx: { '& .MuiPaper-root': { backgroundColor: theme.palette.background.default } },
  },
  confirmationButtonProps: {
    style: {
      color: text === 'Delete' ? 'red' : '',
    },
  },
  dialogActionsProps: {
    sx: {
      '& .MuiButtonBase-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  confirmationText: text,
});
export default confirmModalStyles;

export const modalStyles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: `2px solid primary`,
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100vh',
  overflow: 'auto',
};
