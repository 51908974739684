import { Dispatch, SetStateAction, memo } from 'react';

import { Backdrop, Box, Fade, Link, Modal, Typography } from '@mui/material';

import { modalStyles } from 'src/helpers/modalStyles';

const PurchaseBonesComingSoonModal = memo(
  ({ open, setOpened }: { open: boolean; setOpened: Dispatch<SetStateAction<boolean>> }) => (
    <Modal
      open={!!open}
      onClose={() => setOpened(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={!!open}>
        <Box sx={modalStyles}>
          <Box textAlign={'center'}>
            <Typography component={'h1'} variant="h4" fontFamily={'Dela Gothic One'}>
              Digital bones purchase coming soon!
            </Typography>
            <Typography mt={3} fontFamily={'CircularStd'}>
              Thank you for your interest in advertising with LEV! We're working on bringing you a
              payment gateway soon. In the meantime, please contact our sales team at{' '}
              <Link href="mailto:sales@levmaps.com">sales@levmaps.com</Link> to purchase digital
              bones and start advertising with us.
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
);

export default PurchaseBonesComingSoonModal;
