import dayjs from 'dayjs';

import { Box, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { UUID } from 'src/api';
import { useGetAPI } from 'src/api/offers';
import { EGroupBy, ETimezone } from 'src/api/businessAPI';
import { useGeneralFilters } from 'src/hooks/useGeneralFIlters';
import { StyledTextField } from 'src/pages/components/RegisterSteps/Register';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import GeneralStatistics from './GeneralStatistics';

const FIXED_PERIODS = [
  { label: 'Today', value: 'TODAY' },
  { label: 'Yesterday', value: 'YESTERDAY' },
  { label: 'Last 7 days', value: 'LAST_7_DAYS' },
  { label: 'Last 28 days', value: 'LAST_28_DAYS' },
  { label: 'Custom', value: 'CUSTOM' },
];
const GROUP_BY = [
  { label: 'Day', value: EGroupBy.DAY },
  { label: 'Week', value: EGroupBy.WEEK },
  { label: 'Month', value: EGroupBy.MONTH },
];
const TIMEZONE = [
  { label: 'UTC', value: ETimezone.UTC },
  { label: 'New-York', value: ETimezone.NEW_YORK },
];

const Statistics = ({ businessId }: { businessId: UUID }) => {
  const {
    endDate,
    fixedPeriod,
    groupBy,
    startDate,
    timezone,
    setEndDate,
    onFixedPeriodChange,
    setGropuBy,
    setStartDate,
    setTimezone,
  } = useGeneralFilters();

  useGetAPI({ businessId });

  return (
    <Box>
      <Box>
        <BusinessStyledTypograhpy fontSize={'26px'}>Filters</BusinessStyledTypograhpy>
        <Box display={'flex'} gap={7} mt={1}>
          <StyledTextField
            select
            sx={{ width: '10rem', mb: 2 }}
            label="Period"
            defaultValue={fixedPeriod}
            onChange={(e) => onFixedPeriodChange(e.target.value)}
          >
            {FIXED_PERIODS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledTextField>
          <StyledTextField
            select
            sx={{ width: '10rem', mb: 2 }}
            label="Group by"
            defaultValue={groupBy}
            onChange={(e) => setGropuBy(e.target.value as EGroupBy)}
          >
            {GROUP_BY.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledTextField>
          <StyledTextField
            select
            sx={{ width: '10rem', mb: 2 }}
            label="Timezone"
            defaultValue={timezone}
            onChange={(e) => setTimezone(e.target.value as ETimezone)}
          >
            {TIMEZONE.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledTextField>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display={'flex'} gap={10}>
            <DesktopDatePicker
              label="Start date"
              inputFormat="MM/DD/YYYY"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <StyledTextField {...params} />}
              disabled={!(fixedPeriod === 'CUSTOM')}
              maxDate={dayjs()}
            />
            <DesktopDatePicker
              label="End date"
              inputFormat="MM/DD/YYYY"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <StyledTextField {...params} />}
              disabled={!(fixedPeriod === 'CUSTOM')}
              maxDate={dayjs()}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <GeneralStatistics
        startDate={startDate}
        endDate={endDate}
        groupBy={groupBy}
        timezone={timezone}
        businessId={businessId}
      />
    </Box>
  );
};

export default Statistics;
