interface IShow {
  children: JSX.Element;
  or?: JSX.Element;
  condition?: boolean;
}

const Show = ({ condition, children, or }: IShow) => {
  if (!condition && or) {
    return or;
  }

  if (!condition) {
    return null;
  }

  return children;
};

export default Show;
