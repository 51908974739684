const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const setActiveTab = (activeTab: string) => ({
  type: SET_ACTIVE_TAB,
  activeTab,
});

const initialState: string = '1';

// eslint-disable-next-line @typescript-eslint/default-param-last
const businessActiveTabReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.activeTab;
    default:
      return state;
  }
};

export default businessActiveTabReducer;
