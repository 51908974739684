import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useKeycloak } from 'src/hooks/useKeycloak';
import { getRegisterInProgress } from 'src/Keycloak';

import LoadingScreen from '../components/loading-screen';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { authenticated, initialized } = useKeycloak();

  if (!initialized) {
    return <LoadingScreen />;
  }

  if (authenticated && !getRegisterInProgress()) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}
