/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';
import Geocode from 'react-geocode';

import { v4 as uuidv4 } from 'uuid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button, Fade, MenuItem, Modal, useTheme } from '@mui/material';

import { defaultLocation } from 'src/constants';
import { IPinLocation } from 'src/api/businessAPI';
import { RootState, useSelector } from 'src/store';
import { useSnackbar } from 'src/components/snackbar';
import { modalStyles } from 'src/helpers/modalStyles';
import { CreateEditPinSchema } from 'src/utils/validators';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { StyledTextField } from 'src/pages/components/RegisterSteps/Register';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import useMapLoad from 'src/hooks/useMapLoad';
import Show from 'src/pages/components/Show/Show';
import GoogleMap from 'src/pages/components/Map/Map';

export enum EPINS {
  CAFE = 'CAFE',
  BAR = 'BAR',
  RESTAURANT = 'RESTAURANT',
  PARK = 'PARK',
  HOTEL = 'HOTEL',
  SUPERMARKET = 'SUPERMARKET',
  PET_SHOP = 'PET_SHOP',
  VET = 'VET',
  GROOMER = 'GROOMER',
  BEACH = 'BEACH',
  FLOWER_SHOP = 'FLOWER_SHOP',
  DOG_SHELTER = 'DOG_SHELTER',
  DOGGY_DAYCARE = 'DOGGY_DAYCARE',
  OTHER = 'OTHER',
}

const PINS = [
  { label: 'Cafe', value: EPINS.CAFE },
  { label: 'Bar', value: EPINS.BAR },
  { label: 'Restaurant', value: EPINS.RESTAURANT },
  { label: 'Park', value: EPINS.PARK },
  { label: 'Hotel', value: EPINS.HOTEL },
  { label: 'Supermarket', value: EPINS.SUPERMARKET },
  { label: 'Pet shop', value: EPINS.PET_SHOP },
  { label: 'Vet', value: EPINS.VET },
  { label: 'Groomer', value: EPINS.GROOMER },
  { label: 'Beach', value: EPINS.BEACH },
  { label: 'Flower shop', value: EPINS.FLOWER_SHOP },
  { label: 'Dog shelter', value: EPINS.DOG_SHELTER },
  { label: 'Doggy daycare', value: EPINS.DOGGY_DAYCARE },
  { label: 'Other', value: EPINS.OTHER },
];

export interface IPinCreateEditModal {
  pin: IPinLocation | null;
  open: boolean;
  isLoading: boolean;
  isSuccess?: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  setPin?: Dispatch<SetStateAction<IPinLocation | null>>;
  mutate: (params: IPinLocation) => void;
  resetMutation: () => void;
}

export type CreateEditPinInput = yup.Asserts<typeof CreateEditPinSchema>;

const PinCreateEditModal = ({
  pin,
  open,
  isLoading,
  isSuccess,
  setPin = () => {},
  setOpened,
  mutate,
  resetMutation,
}: IPinCreateEditModal) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [zoom, setZoom] = useState<number>(10);
  const [address, setAddress] = useState<string>('');
  const [isEdited, setIsEdited] = useState(false);
  const [location, setLocation] = useState<{ lat: number; lng: number }>(
    pin ? { lat: pin?.latitude, lng: pin?.longitude } : defaultLocation
  );
  const [center, setCenter] = useState<{ lat: number; lng: number }>(
    pin ? { lat: pin?.latitude, lng: pin?.longitude } : defaultLocation
  );

  const locations = useSelector((state: RootState) => state.locations?.locations);

  const { isLoaded, onLoad } = useMapLoad();
  const {
    register,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm<CreateEditPinInput>({
    mode: 'all',
    resolver: yupResolver(CreateEditPinSchema),
  });
  const watchLocation = watch(['latitude', 'longitude']);
  const watchAddress = watch('address');

  const changedFields = Object.keys(dirtyFields);

  useEffect(() => {

    if (watchLocation?.[0] && watchLocation?.[1] && isEdited) {
      setLocation({
        lat: parseFloat(watchLocation?.[0] || '0'),
        lng: parseFloat(watchLocation?.[1] || '0'),
      });
    }
  }, [watchLocation[0], watchLocation[1]]);

  useEffect(() => {
    const fieldsLength = changedFields.length > 0;
    if (fieldsLength) {
      setIsEdited(fieldsLength);
    }
  }, [changedFields]);

  useEffect(() => {
    if (address) {
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          if (lat && lng) {
            setCenter({ lat, lng });
            setZoom(19);
            enqueueSnackbar('Address found', { variant: 'success' });
          }
        },
        (err) => {
          enqueueSnackbar('Address was not found', { variant: 'error' });
        }
      );
    }
  }, [address]);

  useEffect(() => {
    if (
      (location?.lat && !pin) ||
      (location?.lat && changedFields.find((field) => field === 'latitude' || 'longitude'))
    ) {
      Geocode.fromLatLng(location?.lat.toString(), location?.lng.toString()).then(
        (response) => {
          const foundAddress = response.results?.find(
            (address: any) => address?.types?.[0] === 'street_address'
          );
          if (foundAddress) {
            setValue('address', foundAddress?.formatted_address, {
              shouldValidate: true,
            });

            enqueueSnackbar('Address found', { variant: 'success' });
            return;
          }
          enqueueSnackbar('Address was not found', { variant: 'error' });
        },
        (err) => {
          enqueueSnackbar('Address was not found', { variant: 'error' });
        }
      );
    }
  }, [location]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setOpened(false);
      setPin(null);
      resetMutation();
      reset();
    }
  }, [isLoading, isSuccess]);

  const onSubmitHandler: SubmitHandler<yup.Asserts<typeof CreateEditPinSchema>> = (values) => {
    const params = {
      ...values,
      phone: values?.phone || null,
      longitude: parseFloat(values.longitude || '0'),
      latitude: parseFloat(values.latitude || '0'),
    };
    mutate(params as IPinLocation);
  };

  const handleSelectedLocationChange = (lat: number, lng: number) => {
    setValue('latitude', lat.toString(), { shouldDirty: true });
    setValue('longitude', lng.toString(), { shouldDirty: true });
    setIsEdited(true);
    clearErrors(['latitude', 'longitude']);
  };

  const handleSearchAddress = (e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpened(false);
        setPin(null);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyles}>
          <BusinessStyledTypograhpy id="transition-modal-title" variant="h6">
            {pin ? 'Update' : 'Create'} pin
          </BusinessStyledTypograhpy>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box gap={2} sx={{ display: { xs: 'block', md: 'flex' } }}>
              <Show condition={isLoaded}>
                <Box position={'relative'} mt={2}>
                  <StyledTextField
                    value={address}
                    onChange={handleSearchAddress}
                    placeholder={'Search Address'}
                    sx={{
                      width: 446,
                      height: 36,
                      m: 1,
                      position: 'absolute',
                      zIndex: 1000,
                      backgroundColor: theme.palette.mode === 'dark' ? '#212b36' : 'white',
                      input: {
                        height: 5,
                        '&::placeholder': {
                          color: '#aeb9ce',
                        },
                      },
                      '.MuiOutlinedInput-root': {
                        borderRadius: '11px',
                        '&:hover': { border: 'none!' },
                      },
                    }}
                  />
                  <GoogleMap
                    location={location}
                    locations={locations}
                    center={center}
                    zoom={zoom}
                    setLocation={setLocation}
                    onLoad={onLoad}
                    onSelectedLocationChange={handleSelectedLocationChange}
                    options={{
                      fullscreenControl: false,
                      mapTypeControl: false,
                      streetViewControl: false,
                      controlSize: 22,
                    }}
                    styles={{
                      width: 462,
                      height: 350,
                      borderRadius: 12,
                    }}
                  />
                  <Box display={'flex'} mt={2} gap={2}>
                    <StyledTextField
                      sx={{ mb: 2 }}
                      label="Latitude"
                      fullWidth
                      required
                      defaultValue={pin?.latitude || location.lat}
                      error={fieldError(errors, 'latitude')}
                      helperText={fieldHelpertext(errors, 'latitude')}
                      {...register('latitude')}
                    />
                    <StyledTextField
                      sx={{ mb: 2 }}
                      label="Longitude"
                      fullWidth
                      required
                      defaultValue={pin?.longitude || location.lng}
                      error={fieldError(errors, 'longitude')}
                      helperText={fieldHelpertext(errors, 'longitude')}
                      {...register('longitude')}
                    />
                  </Box>
                </Box>
              </Show>
              <Box>
                <StyledTextField
                  sx={{ my: 2 }}
                  label="Pin Name"
                  fullWidth
                  required
                  defaultValue={pin?.name}
                  error={fieldError(errors, 'name')}
                  helperText={fieldHelpertext(errors, 'name')}
                  {...register('name')}
                />
                <StyledTextField
                  select
                  sx={{ mb: 2 }}
                  label="Type"
                  fullWidth
                  required
                  defaultValue={pin ? EPINS[pin?.type] : ''}
                  error={fieldError(errors, 'type')}
                  helperText={fieldHelpertext(errors, 'type')}
                  {...register('type')}
                >
                  {PINS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </StyledTextField>
                <StyledTextField
                  sx={{ mb: 2 }}
                  label="Address"
                  fullWidth
                  required
                  defaultValue={pin?.address}
                  InputLabelProps={{ shrink: !!watchAddress }}
                  error={fieldError(errors, 'address')}
                  helperText={fieldHelpertext(errors, 'address')}
                  {...register('address')}
                />
                <StyledTextField
                  sx={{ mb: 2 }}
                  label="Phone"
                  fullWidth
                  defaultValue={pin?.phone}
                  error={fieldError(errors, 'phone')}
                  helperText={fieldHelpertext(errors, 'phone')}
                  {...register('phone')}
                />
                <StyledTextField
                  sx={{ mb: 1 }}
                  label="Notes"
                  fullWidth
                  defaultValue={pin?.notes}
                  error={fieldError(errors, 'notes')}
                  helperText={fieldHelpertext(errors, 'notes')}
                  {...register('notes')}
                />
                <StyledTextField
                  InputProps={{
                    type: 'hidden',
                  }}
                  sx={{ visibility: 'hidden' }}
                  value={pin?.id || uuidv4()}
                  {...register('id')}
                />
                <Box display={'flex'} gap={2}>
                  <LoadingButton
                    disabled={!(isValid && isEdited)}
                    loading={isLoading}
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{ py: '0.8rem', mt: '1rem', fontFamily: 'CircularStd', maxWidth: 372 }}
                  >
                    {pin ? 'Update' : 'Create'}
                  </LoadingButton>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ py: '0.8rem', mt: '1rem', fontFamily: 'CircularStd', maxWidth: 372 }}
                    onClick={() => {
                      reset();
                      setOpened(false);
                      setPin(null);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PinCreateEditModal;
