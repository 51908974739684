import dayjs from 'dayjs';
import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';

import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { ALL } from 'src/constants';
import { useSelector } from 'src/store';
import { getOrNull } from 'src/helpers/formHeleprs';
import { IGeneralStatistics } from './GeneralStatistics';
import { useGetPurchasedOffersStatsAPI } from 'src/api/statistics';
import { StyledTextField } from 'src/pages/components/RegisterSteps/Register';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import CustomTooltip from './CustomToolTip';

export const StyledMenuItem = styled(MenuItem)(() => ({
  fontFamily: 'CircularStd',
}));

const PurchasedOffersStats = ({
  startDate,
  endDate,
  timezone,
  groupBy,
  businessId,
}: IGeneralStatistics) => {
  const theme = useTheme();

  const [offerId, setOfferId] = useState(ALL);
  const [byUserUniq, setByUserUniq] = useState<boolean>(false);

  const offers = useSelector((state) => state.offers.offers);
  const purchasedOffers = useSelector((state) => state.statistics.purchasedOffers);

  useGetPurchasedOffersStatsAPI({
    offerId: getOrNull(offerId, ALL),
    startDate: startDate || dayjs().subtract(7, 'day'),
    endDate: endDate || dayjs(),
    groupBy,
    timezone,
    businessId,
    byUserUniq,
  });

  return (
    <Box>
      <Box display={'flex'} gap={2}>
        <BusinessStyledTypograhpy fontSize={'26px'}>
          Offers purchased:{' '}
          <Typography
            fontFamily={'CircularStd'}
            fontSize={'26px'}
            component="span"
            color={theme.palette.primary.main}
          >
            {purchasedOffers?.totalOffers}
          </Typography>
        </BusinessStyledTypograhpy>
        <FormControlLabel
          control={<Checkbox checked={byUserUniq} onChange={() => setByUserUniq(!byUserUniq)} />}
          label={<BusinessStyledTypograhpy children={'Unique users'} />}
        />
      </Box>
      <Box display={'flex'} gap={7}>
        <StyledTextField
          select
          sx={{ width: '10rem', mt: 2 }}
          label="Offer"
          defaultValue={offerId}
          onChange={(e) => setOfferId(e.target.value)}
        >
          <StyledMenuItem value="ALL" selected={true}>
            All
          </StyledMenuItem>
          {offers?.map(({ id, title }) => (
            <StyledMenuItem key={id} value={id}>
              {title}
            </StyledMenuItem>
          ))}
        </StyledTextField>
      </Box>
      <BarChart
        width={650}
        height={500}
        data={purchasedOffers?.purchasedOffers}
        margin={{
          top: 20,
          right: 0,
          left: -20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          content={<CustomTooltip purchasedOffers={'offers purchased'} />}
          cursor={{ fillOpacity: '25%' }}
        />
        <Legend />
        <Bar
          fontFamily="CircularStd"
          name="purchased offers"
          dataKey="totalOffers"
          stackId="a"
          fill={theme.palette.primary.main}
        />
      </BarChart>
    </Box>
  );
};

export default PurchasedOffersStats;
