import * as yup from 'yup';
import Geocode from 'react-geocode';

import { v4 as uuidv4 } from 'uuid';
import { UseMutateFunction } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Modal,
  useTheme,
} from '@mui/material';

import { UUID } from 'src/api';
import { useGetAPI } from 'src/api/location';
import { defaultLocation } from 'src/constants';
import { RootState, useSelector } from 'src/store';
import { useSnackbar } from 'src/components/snackbar';
import { modalStyles } from 'src/helpers/modalStyles';
import { LocationData } from 'src/store/actions/businesses';
import { LOCATION_STATUSES, LocationBody } from 'src/api/businessAPI';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { StyledTextField } from 'src/pages/components/RegisterSteps/Register';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

import useMapLoad from 'src/hooks/useMapLoad';
import Show from 'src/pages/components/Show/Show';
import GoogleMap from 'src/pages/components/Map/Map';

export interface ILocationsModal {
  data?: LocationData;
  businessId: UUID;
  openId: UUID | null;
  isLoading: boolean;
  isSuccess?: boolean;
  isCreateNewLocation?: boolean;
  label: string;
  businessCity?: string;
  schema: yup.AnyObjectSchema;
  setOpenId: Dispatch<SetStateAction<UUID | null>>;
  setCheckedLocId?: Dispatch<SetStateAction<UUID | null>>;
  setIsCreateNewLocation?: Dispatch<SetStateAction<boolean>>;
  mutate: UseMutateFunction<
    AxiosResponse<any, any>,
    AxiosError<unknown, any>,
    { id: string | undefined; params: LocationBody },
    unknown
  >;
  refetchLocations: () => void;
}

const LocationsModal = ({
  data,
  businessId,
  openId,
  isLoading,
  isSuccess,
  isCreateNewLocation,
  label,
  schema,
  businessCity,
  setOpenId,
  setCheckedLocId = () => {},
  setIsCreateNewLocation = () => {},
  mutate,
  refetchLocations,
}: ILocationsModal) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [zoom, setZoom] = useState<number>(10);
  const [address, setAddress] = useState<string>('');
  const [isEdited, setIsEdited] = useState(false);
  const [createdCount, setCreatedCount] = useState<number>(0);
  const [location, setLocation] = useState<{ lat: number; lng: number }>(
    data ? { lat: data?.latitude, lng: data?.longitude } : defaultLocation
  );
  const [center, setCenter] = useState<{ lat: number; lng: number }>(
    data ? { lat: data?.latitude, lng: data?.longitude } : defaultLocation
  );

  const locations = useSelector((state: RootState) => state.locations?.locations);
  useGetAPI({ businessId, status: LOCATION_STATUSES.ACTIVE, size: 100, page: 1 });
  const { isLoaded, onLoad } = useMapLoad();
  const {
    register,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm<yup.Asserts<typeof schema>>({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const watchLocation = watch(['latitude', 'longitude']);
  const changedFields = Object.keys(dirtyFields);

  useEffect(() => {
    setLocation({
      lat: parseFloat(watchLocation?.[0] || '0'),
      lng: parseFloat(watchLocation?.[1] || '0'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLocation[0], watchLocation[1]]);

  useEffect(() => {
    const fieldsLength = changedFields.length > 0;
    if (fieldsLength) {
      setIsEdited(fieldsLength);
    }
  }, [changedFields]);

  useEffect(() => {
    if (businessCity) {
      Geocode.fromAddress(businessCity).then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter({ lat, lng });
        setValue('latitude', lat.toString());
        setValue('longitude', lng.toString());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCity]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (isCreateNewLocation) {
        reset();
        setCreatedCount((prevValue) => prevValue + 1);
        return;
      }
      refetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (address) {
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          if (lat && lng) {
            setCenter({ lat, lng });
            setZoom(19);
            enqueueSnackbar('Address found', { variant: 'success' });
          }
        },
        (err) => {
          enqueueSnackbar('Address was not found', { variant: 'error' });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const onSubmitHandler: SubmitHandler<yup.Asserts<typeof schema>> = (values) => {
    const params = {
      ...values,
      longitude: parseFloat(values.longitude || '0'),
      latitude: parseFloat(values.latitude || '0'),
    };
    mutate({ id: data ? data?.id : businessId, params });
    if (data) {
      setCheckedLocId(null);
    }
  };

  const handleSelectedLocationChange = (lat: number, lng: number) => {
    setValue('latitude', lat.toString());
    setValue('longitude', lng.toString());
    setIsEdited(true);
    clearErrors(['latitude', 'longitude']);
  };

  const handleSearchAddress = (e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);

  return (
    <Modal
      open={!!openId}
      onClose={() => {
        setOpenId(null);
        setCheckedLocId(null);
        refetchLocations();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={!!openId}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Box sx={modalStyles}>
            <Box sx={{ margin: '0 auto 32px' }}>
              <BusinessStyledTypograhpy id="transition-modal-title" variant="h6">
                {label} location
              </BusinessStyledTypograhpy>
              <StyledTextField
                sx={{ mb: 2, mt: 2 }}
                label="Location name"
                fullWidth
                required
                defaultValue={data?.title}
                error={fieldError(errors, 'title')}
                helperText={fieldHelpertext(errors, 'title')}
                {...register('title')}
              />
              <StyledTextField
                sx={{ mb: 2 }}
                label="Latitude"
                fullWidth
                required
                type="number"
                defaultValue={data?.latitude || location.lat}
                error={fieldError(errors, 'latitude')}
                helperText={fieldHelpertext(errors, 'latitude')}
                {...register('latitude')}
              />
              <StyledTextField
                sx={{ mb: 2 }}
                label="Longitude"
                fullWidth
                required
                type="number"
                defaultValue={data?.longitude || location.lng}
                error={fieldError(errors, 'longitude')}
                helperText={fieldHelpertext(errors, 'longitude')}
                {...register('longitude')}
              />
              {!data && (
                <StyledTextField
                  InputProps={{
                    type: 'hidden',
                  }}
                  sx={{ visibility: 'hidden' }}
                  value={uuidv4()}
                  {...register('id')}
                />
              )}
              <Box display={'flex'} gap={2}>
                <LoadingButton
                  disabled={!(isValid && isEdited)}
                  loading={isLoading}
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{ py: '0.8rem', mt: '1rem', fontFamily: 'CircularStd' }}
                >
                  {label} location
                </LoadingButton>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ py: '0.8rem', mt: '1rem', fontFamily: 'CircularStd' }}
                  onClick={() => {
                    reset();
                    setOpenId('');
                    setCheckedLocId('');
                    refetchLocations();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
            {isLoaded && (
              <>
                <StyledTextField
                  fullWidth
                  value={address}
                  onChange={handleSearchAddress}
                  sx={{ mb: '1rem' }}
                  label={'Search'}
                  placeholder={'Address'}
                />
                <GoogleMap
                  location={location}
                  locations={locations?.filter((loc) => loc?.id !== openId)}
                  center={center}
                  zoom={zoom}
                  setLocation={setLocation}
                  onLoad={onLoad}
                  onSelectedLocationChange={handleSelectedLocationChange}
                  styles={{
                    width: '100%',
                    height: 425,
                  }}
                />
              </>
            )}
            <Show condition={!data}>
              <Box mt={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCreateNewLocation}
                      onChange={() => setIsCreateNewLocation(!isCreateNewLocation)}
                    />
                  }
                  label={
                    <BusinessStyledTypograhpy>Create another location</BusinessStyledTypograhpy>
                  }
                />
                <Show condition={!!createdCount}>
                  <BusinessStyledTypograhpy color={theme.palette.primary.main}>
                    {createdCount} locations created
                  </BusinessStyledTypograhpy>
                </Show>
              </Box>
            </Show>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default LocationsModal;
