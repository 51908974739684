import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDispatch } from 'src/store';
import { setBatchDetails } from 'src/store/actions/batches';

import { businessAPI, UUID } from '.';
import { useSnackbar } from '../components/snackbar';

export const useGetAPI = ({ id }: { id: UUID }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, error, data } = useQuery(
    ['batch', id],
    () => businessAPI.getBatchDetails(id),
    {
      onSuccess(data) {
        dispatch(setBatchDetails(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Batch details: ${err.message} `, { variant: 'error' });
      },
    }
  );

  return { isLoading, error, data };
};

export const useMutateAPI = (setPromoTextModalId: (value: string) => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, isSuccess, mutate, variables } = useMutation(businessAPI.updateSomeBatchData, {
    onSuccess(_, { id, params: { offerId } }) {
      queryClient.invalidateQueries('batches');
      queryClient.invalidateQueries('offers');
      if (offerId) {
        setPromoTextModalId(id);
      }
      enqueueSnackbar(`Batch updated`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Batch update: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, variables, updateBatch: mutate };
};
