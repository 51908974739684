import { UUID } from 'src/api';

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';

export interface AccountData {
  id?: UUID;
  userName?: string;
  email?: string;
  emailVerified?: boolean;
  keycloakToken: string;
  refreshToken: string;
  role?: string;
}

export const setAccount = (profile: AccountData) => ({
  type: SET_ACCOUNT,
  payload: profile,
});

export const clearAccount = () => ({
  type: CLEAR_ACCOUNT,
});
