import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { clearTokens, setTokens } from 'src/Keycloak';

import { useSnackbar } from 'notistack';
import { authAPI } from './authAPI';
import { useDispatch, userLogout } from 'src/store';
import { useSettingsContext } from 'src/components/settings';

export const useAdminSignInAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, isSuccess, mutate } = useMutation(authAPI.adminSignIn, {
    onSuccess(response) {
      setTokens(response?.data?.access_token, response?.data?.refresh_token);
      enqueueSnackbar('Logged in', { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(
        `Sign In: ${
          err?.response?.status === 401
            ? 'Incorrect login or password!'
            : (err?.response?.data as AxiosError)?.message
        }`,
        {
          variant: 'error',
        }
      );
    },
  });

  return { isLoading, isSuccess, signIn: mutate };
};

export const useRegisterAPI = (isResend?: boolean) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, isSuccess, mutate } = useMutation(authAPI.registerAccount, {
    onSuccess() {
      enqueueSnackbar(isResend ? 'Code was sent' : 'Registered', { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Sign Up: ${(err?.response?.data as AxiosError)?.message}`, {
        variant: 'error',
      });
    },
  });

  return { isLoading, isSuccess, registerAccount: mutate };
};

export const useConfirmAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isSuccess, mutate } = useMutation(authAPI.confirmCode, {
    onSuccess(response) {
      setTokens(response?.data?.access_token, response?.data?.refresh_token);
      enqueueSnackbar('Verified', { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Verify account: ${(err?.response?.data as AxiosError)?.message}`, {
        variant: 'error',
      });
    },
  });

  return { data, isLoading, isSuccess, confirmCode: mutate };
};

export const useSendCodeAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isSuccess, mutate } = useMutation(authAPI.sendCode, {
    onSuccess() {
      enqueueSnackbar('Code was sent', { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Send code: ${(err?.response?.data as AxiosError)?.message}`, {
        variant: 'error',
      });
    },
  });

  return { data, isLoading, isSuccess, sendCode: mutate };
};

export const useRefreshTokenAPI = () => {
  const { isLoading, isSuccess, mutate } = useMutation(authAPI.refreshToken, {
    onSuccess(response) {
      setTokens(response?.data?.access_token, response?.data?.refresh_token);
    },
  });

  return { isLoading, isSuccess, refreshToken: mutate };
};

export const useLogoutAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { onResetSetting } = useSettingsContext();
  const { isLoading, isSuccess, mutate } = useMutation(authAPI.logout, {
    onSuccess() {
      clearTokens();
      dispatch(userLogout());
      onResetSetting();
      enqueueSnackbar('Logged out', { variant: 'success' });
    },
  });

  return { isLoading, isSuccess, logout: mutate };
};
