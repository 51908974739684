import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { businessAPI } from '.';
import { useDispatch } from 'src/store';
import { MONTHES, WEEKDAYS } from 'src/constants';
import { useSnackbar } from '../components/snackbar';
import {
  setCollectedBonesStats,
  setOpenedOffersStats,
  setPurchasedOffersStats,
  setActivatedOffersStats,
} from 'src/store/reducers/statistics';
import {
  EDayPeriod,
  EGroupBy,
  ICollectedBonesStats,
  IOpenedOffersStats,
  IPurchasedOffersStats,
} from './businessAPI';

const groupByCorrector = (groupBy: EGroupBy, date: Date) => {
  let name;
  if (groupBy === 'DAY') {
    name = WEEKDAYS[date.getDay()];
  } else if (groupBy === 'WEEK') {
    name = date.toLocaleDateString();
  } else if (groupBy === 'MONTH') {
    name = MONTHES[date.getMonth()];
  }
  return name;
};

const requestDateChecker = (
  params: ICollectedBonesStats | IPurchasedOffersStats,
  enqueueSnackbar: any,
  get: any
) => {
  const diffInDays = params.endDate?.diff(params.startDate, 'day', true);
  if (diffInDays > 365) {
    enqueueSnackbar('Please select period less than a year long', { variant: 'error' });
    return;
  }
  if (diffInDays > 61 && params.groupBy === EGroupBy.DAY) {
    enqueueSnackbar('Please select period less than 2 month long or change Group by value', {
      variant: 'error',
    });
    return;
  }
  if (params.startDate > params.endDate) {
    enqueueSnackbar('Please select a valid range', {
      variant: 'error',
    });
    return;
  }
  return get(params);
};

const getFinalValues = (stats: { date: Date; totalOffers: number }[], groupBy: EGroupBy) =>
  stats
    ?.map((stat: any) => {
      const date = new Date(stat.date);

      return {
        name: groupByCorrector(groupBy, date),
        totalOffers: stat.totalOffers,
        date: new Date(date).toLocaleDateString(),
      };
    })
    .flat(1);

export const useGetCollectedBonesStatsAPI = (params: ICollectedBonesStats) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, isSuccess, error, data } = useQuery(
    ['collectedBonesStats', params],
    () => requestDateChecker(params, enqueueSnackbar, businessAPI.getCollectedBonesStats),
    {
      onSuccess(data) {
        const collectedBones = data?.data?.stats
          ?.map((stat: { date: Date; stats: { part: EDayPeriod; total: number }[] }) => {
            const date = new Date(stat.date);
            // eslint-disable-next-line array-callback-return
            let dayPartData = stat?.stats?.reduce((dayPartData: any, st: any) => {
              switch (st?.part) {
                case EDayPeriod.MORNING:
                  return { ...dayPartData, morning: st.total };
                case EDayPeriod.DAY:
                  return { ...dayPartData, day: st.total };
                case EDayPeriod.EVENING:
                  return { ...dayPartData, evening: st.total };
                case EDayPeriod.NIGHT:
                  return { ...dayPartData, night: st.total };
                case EDayPeriod.ALL_DAY:
                  return { ...dayPartData, allDay: st.total };
              }
            }, {});

            return {
              name: groupByCorrector(data?.data?.groupBy, date),
              date: date.toLocaleDateString(),
              ...dayPartData,
            };
          })
          .flat(1);

        data &&
          dispatch(setCollectedBonesStats({ totalBones: data?.data?.totalBones, collectedBones }));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Bones stats: ${err.message}`, { variant: 'error' });
      },
    }
  );

  return { isLoading, isSuccess, error, data };
};

export const useGetPurchasedOffersStatsAPI = (params: IPurchasedOffersStats) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, isSuccess, error, data } = useQuery(
    ['purchasedOffersStats', params],
    () => requestDateChecker(params, enqueueSnackbar, businessAPI.getPurchasedOffersStats),
    {
      onSuccess(data) {
        const purchasedOffers = getFinalValues(data?.data?.stats, data?.data?.groupBy);
        data &&
          dispatch(
            setPurchasedOffersStats({ totalOffers: data?.data?.totalOffers, purchasedOffers })
          );
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Purchased stats: ${err.message}`, { variant: 'error' });
      },
    }
  );

  return { isLoading, isSuccess, error, data };
};

export const useGetOpenedOffersStatsAPI = (params: IOpenedOffersStats) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, isSuccess, error, data } = useQuery(
    ['openedOffersStats', params],
    () => requestDateChecker(params, enqueueSnackbar, businessAPI.getOpenedOffersStats),
    {
      onSuccess(data) {
        const openedOffers = getFinalValues(data?.data?.stats, data?.data?.groupBy);
        data &&
          dispatch(setOpenedOffersStats({ totalOffers: data?.data?.totalOffers, openedOffers }));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Opened stats: ${err.message}`, { variant: 'error' });
      },
    }
  );

  return { isLoading, isSuccess, error, data };
};

export const useGetActivatedOffersStatsAPI = (params: IOpenedOffersStats) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, isSuccess, error, data } = useQuery(
    ['activatedOffersStats', params],
    () => requestDateChecker(params, enqueueSnackbar, businessAPI.getActivatedOffersStats),
    {
      onSuccess(data) {
        const activatedOffers = getFinalValues(data?.data?.stats, data?.data?.groupBy);
        data &&
          dispatch(
            setActivatedOffersStats({ totalOffers: data?.data?.totalOffers, activatedOffers })
          );
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Activated stats: ${err.message}`, { variant: 'error' });
      },
    }
  );

  return { isLoading, isSuccess, error, data };
};
