import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getTokens } from 'src/api';
import { PATH_AUTH } from 'src/routes/paths';
import { checkTokenValidity } from 'src/Keycloak';
import { useRefreshTokenAPI } from 'src/api/auth';
import { ROLE_BUSINESS_MANAGER } from 'src/constants';
import { setAccount } from 'src/store/actions/account';
import { RootState, useDispatch, useSelector } from 'src/store';
import useLocalStorage from "./useLocalStorage";

export const useKeycloak = () => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const [, setUserRoles] = useLocalStorage('roles', []);
  // @ts-ignore
  const { keycloakToken: token, refreshToken: refresh } = getTokens();
  const { refreshToken } = useRefreshTokenAPI();
  const accountToken = useSelector((state: RootState) => state.account.keycloakToken);

  useEffect(() => {
    if (!accountToken) {
      setAuthenticated(false);
    }
  },[accountToken])

  useEffect(() => {
    setInitialized(true);
  }, []);

  let decodedToken = token && jwt_decode(token);

  useEffect(() => {
    if (decodedToken) {
      if (checkTokenValidity(decodedToken?.exp)) {
        setAuthenticated(true);
      } else if (refresh) {
        refreshToken(refresh);
      } else {
        navigate(PATH_AUTH.login);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, refresh]);

  useEffect(() => {
    if (!accountToken && token) {
      const roles = decodedToken?.realm_access?.roles || [];
      dispatch(
        setAccount({
          keycloakToken: token,
          refreshToken: refresh,
          email: decodedToken?.email,
          userName: decodedToken?.preferred_username,
          role: roles.includes(ROLE_BUSINESS_MANAGER) ? ROLE_BUSINESS_MANAGER : undefined,
        })
      );

      setUserRoles(decodedToken?.realm_access?.roles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountToken, token]);

  console.log({authenticated});

  return {
    authenticated,
    initialized
  };
};
