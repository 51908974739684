import { AccountData, CLEAR_ACCOUNT, SET_ACCOUNT } from '../actions/account';

const initialState: AccountData = {
  id: '',
  userName: '',
  email: '',
  emailVerified: false,
  keycloakToken: '',
  refreshToken: '',
  role: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const accountReducer = (state = initialState, action: any): AccountData => {
  switch (action.type) {
    case SET_ACCOUNT:
      return action.payload;
    case CLEAR_ACCOUNT:
      return initialState;
    default:
      return state;
  }
};

export default accountReducer;
