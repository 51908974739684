// @mui
import { Stack, Box, useTheme } from '@mui/material';
// config
import { NAV } from '../../../config';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import { navConfig } from './config';

export default function NavMini() {
  const theme = useTheme();
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          bgcolor: theme.palette.background.default,
          zIndex: 10000,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo isMain sx={{ width: 40, height: 40, mx: 'auto', my: 2, borderRadius: '50%' }} />

        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  );
}
