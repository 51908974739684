import Edit from '@mui/icons-material/Edit';

import { useState } from 'react';
import { Box, Typography, Card, CardContent, Divider } from '@mui/material';
import { IconButtonAnimate } from 'src/components/animate';
import { AddressData } from 'src/store/actions/businesses';
import { UUID } from 'src/api';
import BusinessBasicInfoUpdater from './BusinessBasicInfoUpdater';
import BusinessAddressUpdater from './BusinessAddressUpdater';

export interface BusinessCardrops {
  id: UUID;
  name: string;
  userEmail: string;
  description: string;
  addresses: AddressData[];
}

export enum EDIT_MODE {
  BASIC = 'basic',
  ADDRESS = 'address',
  OFF = 'off',
}

const BusinessCard = ({ id, name, userEmail, description, addresses }: BusinessCardrops) => {
  const [editMode, setEditMode] = useState<EDIT_MODE>(EDIT_MODE.OFF);

  //it's a temporary thing
  const address = addresses?.[0];

  return (
    <Box>
      <Typography variant="h3" component="h1" paragraph>
        Business Info
      </Typography>
      <Card sx={{ width: 'fit-content' }}>
        <CardContent>
          <Box sx={{ maxWidth: '26rem' }}>
            {editMode === EDIT_MODE.OFF && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography mt={1} sx={{ fontSize: 24 }} component="div">
                    Name
                  </Typography>
                  <IconButtonAnimate
                    color="primary"
                    onClick={() => setEditMode(EDIT_MODE.BASIC)}
                    sx={{ p: 1.25 }}
                  >
                    <Edit />
                  </IconButtonAnimate>
                </Box>
                <Typography sx={{ fontSize: 18, width: '100%', overflowWrap: 'break-word' }}>
                  {name}
                </Typography>
                <Typography sx={{ fontSize: 18, width: '100%', overflowWrap: 'break-word' }}>
                  {userEmail}
                </Typography>
                <Typography
                  mt={1}
                  mb={1}
                  sx={{ fontSize: 18, width: '100%', overflowWrap: 'break-word' }}
                  color="text.secondary"
                  component="div"
                >
                  {description}
                </Typography>
                <Divider />
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                  <Typography mt={1} sx={{ fontSize: 24 }} component="div">
                    HQ address
                  </Typography>
                  <IconButtonAnimate
                    color="primary"
                    onClick={() => setEditMode(EDIT_MODE.ADDRESS)}
                    sx={{ p: 1.25 }}
                  >
                    <Edit />
                  </IconButtonAnimate>
                </Box>
                <Typography
                  mt={1}
                  sx={{ fontSize: 18, width: '100%', overflowWrap: 'break-word' }}
                  component="div"
                >
                  {!!address?.state && `${address?.state},`} {address?.city}, {address?.address},{' '}
                  {address?.zip}
                </Typography>
              </>
            )}
            {editMode === EDIT_MODE.BASIC && (
              <BusinessBasicInfoUpdater
                id={id}
                name={name}
                description={description}
                setEditMode={setEditMode}
              />
            )}
            {editMode === EDIT_MODE.ADDRESS && (
              <BusinessAddressUpdater address={address} setEditMode={setEditMode} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default BusinessCard;
