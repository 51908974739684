// New-York coordinates
export const defaultLocation = { lat: 40.73061, lng: -73.93524 };
export const MAX_IMG_SIZE = 4200000;
export const ROLE_BUSINESS_MANAGER = 'ROLE_BUSINESS_MANAGER';
export const ROLE_ADMIN = 'ROLE_ADMIN';


export enum EBusinessRegStatus {
  COMPLETED = 'COMPLETED'
}

export enum EUserRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_BUSINESS_MANAGER = 'ROLE_BUSINESS_MANAGER',
}

export const OFFER_VALUES: any = {
  GENERATE_CODES: 'Generate',
  MANUALLY: 'Upload',
  PROMO_CODE: 'Promo code',
  TIMED_COUPON: 'Timed coupon',
  ACTIVE: 'Active',
  NOT_ACTIVE: 'Inactive',
  EMPTY: 'Empty',
  PAUSED: 'Paused',
  COMPLETED: 'Completed',
  PERCENTAGE: 'Discount Percentage',
  DISCOUNT_AMOUNT: 'Discount amount',
  BUY_ONE_GET_ONE_FREE: 'Free',
};

export const OFFER_ALL_STATUSES = {
  GENERATE_CODES: 'GENERATE_CODES',
  MANUALLY: 'MANUALLY',
  PROMO_CODE: 'PROMO_CODE',
  TIMED_COUPON: 'TIMED_COUPON',
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
  EMPTY: 'EMPTY',
  PAUSED: 'PAUSED',
  COMPLETED: 'COMPLETED',
  PERCENTAGE: 'PERCENTAGE',
  DISCOUNT_AMOUNT: 'DISCOUNT_AMOUNT',
  BUY_ONE_GET_ONE_FREE: 'BUY_ONE_GET_ONE_FREE',
};

export const BATCH_STATUSES = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  STOPPED: 'STOPPED',
  FINISHED: 'FINISHED',
};

export const ALL = 'ALL';
export const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const MONTHES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export enum ORDER_BY {
  NAME = 'name',
  EMAIL = 'email',
  CREATED = 'audit.createdDate',
  ACTIVE = 'audit.updatedDate',
}

export interface HeadCell {
  id: string;
  label: string;
}

export const USERS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'bonesBalance',
    label: 'Current balance',
  },
  {
    id: 'totalBonesSpent',
    label: 'Bones spent (total)',
  },
  {
    id: 'totalBonesSpentLast30Days',
    label: 'Bones spent (last 30 days)',
  },
  {
    id: 'linkedDogsCount',
    label: 'Number of dogs',
  },
  {
    id: 'audit.createdDate',
    label: 'Created',
  },
  {
    id: 'audit.updatedDate',
    label: 'Last active',
  },
  {
    id: 'totalInvited',
    label: 'Invited users',
  },
];

export const BUSINESS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'userEmail',
    label: 'Email',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'locations',
    label: 'Locations',
  },
  {
    id: 'bonesBalance',
    label: 'Current bones balance',
  },
  {
    id: 'activeBatchBones',
    label: 'Active Ad campaigns',
  },
  {
    id: 'activeOffers',
    label: 'Active offers',
  },
  {
    id: 'audit.createdDate',
    label: 'Created',
  },
  {
    id: 'audit.updatedDate',
    label: 'Last active',
  },
];

export const DOG_INFO_HEAD_CELLS: HeadCell[] = [
  {
    id: 'photo',
    label: 'Photo',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'breed',
    label: 'Breed',
  },
  {
    id: 'gender',
    label: 'Gender',
  },
  {
    id: 'bd',
    label: 'Birthday',
  },
  {
    id: 'weight',
    label: 'Weight',
  },
  {
    id: 'height',
    label: 'Height',
  },
  {
    id: 'notes',
    label: 'Notes',
  },
];

export const LOACTIONS_INFO_HEAD_CELLS: HeadCell[] = [
  {
    id: 'title',
    label: 'Name',
  },
  {
    id: 'latitude',
    label: 'Latitude',
  },
  {
    id: 'longitude',
    label: 'Longitude',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];

export const BATCHES_HEAD_CELLS: HeadCell[] = [
  {
    id: 'displayId',
    label: '#',
  },
  {
    id: 'location.title',
    label: 'Location',
  },
  {
    id: 'totalBones',
    label: 'Bones limit',
  },
  {
    id: 'bonesLeft',
    label: 'Bones left',
  },
  {
    id: 'promoText',
    label: 'Promotional text',
  },
  {
    id: 'progress',
    label: 'Progress',
  },
  {
    id: 'message',
    label: 'Advertisement',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'offer',
    label: 'Offer',
  },
  {
    id: 'notes',
    label: 'Notes',
  },
  {
    id: 'audit.createdDate',
    label: 'Created',
  },
  {
    id: 'finishedDate',
    label: 'Finished',
  },
];

export const ROLE_BUSINESS_BATCHES_HEAD_CELLS: HeadCell[] = [
  {
    id: 'name',
    label: 'Campaign name',
  },
  {
    id: 'location.title',
    label: 'Location',
  },
  {
    id: 'totalBones',
    label: 'Bones Budgeted',
  },
  {
    id: 'bonesLeft',
    label: 'Bones remaining',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'offer',
    label: 'Offer',
  },
  {
    id: 'audit.createdDate',
    label: 'Created',
  },
  // {
  //   id: 'actions',
  //   label: 'Actions',
  // },
];

export const OFFERS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'price',
    label: 'Price in bones',
  },
  {
    id: 'totalOffersLimit',
    label: 'Total offers',
  },
  {
    id: 'offersleft',
    label: 'Offers left ',
  },
  {
    id: 'progress',
    label: 'Progress',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'discountType',
    label: 'Codes type',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

export const ROLE_BUSINESS_OFFERS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'title',
    label: 'Offer Name',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'price',
    label: 'Price (bones)',
  },
  {
    id: 'totalOffersLimit',
    label: 'Total offers',
  },
  {
    id: 'offersLeft',
    label: 'Remaining',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'audit.createdDate',
    label: 'Created',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];

export const TRANSACTIONS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'business.name',
    label: 'Business name',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'user.email',
    label: 'Admin email',
  },
  {
    id: 'createdDate',
    label: 'Date',
  },
];

export const POSTED_PINS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'notes',
    label: 'Description',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'createdBy',
    label: 'Created by',
  },
  {
    id: 'audit.createdDate',
    label: 'Created',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

export const REPORTED_PINS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'notes',
    label: 'Description',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'createdBy',
    label: 'Created by',
  },
  {
    id: 'audit.createdDate',
    label: 'Created',
  },
  {
    id: 'complaintStatus',
    label: 'Report type',
  },
];

export const APPROVED_BY_ADMIN_PINS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'notes',
    label: 'Description',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'createdBy',
    label: 'Created by',
  },
  {
    id: 'audit.createdDate',
    label: 'Created',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];
