import { UUID } from 'src/api';

export const GET_BUSINESSES = 'GET_BUSINESSES';
export const SET_BUSINESSES = 'SET_BUSINESSES';
export const SET_BUSINESS_DETAILS = 'SET_BUSINESS_DETAILS';
export const SET_LOCATIONS = 'SET_LOCATIONS';

export enum EBusinessLocationStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
export interface LocationData {
  id: UUID;
  title: string;
  longitude: number;
  latitude: number;
  status: EBusinessLocationStatus | '';
}

export interface AddressData {
  id: UUID;
  address: string;
  state?: string;
  zip: string;
  city: string;
  country: {
    id: UUID;
    name: string;
    avatar?: string;
  };
}

export interface BusinessData {
  id: UUID;
  name: string;
  description: string;
  locations: LocationData[];
  addresses: AddressData[];
  activeBatches?: string;
  totalBones?: string;
  bonesLeft?: string;
  createdDate: string;
  updatedDate: string;
}

export const setBusinesses = (businessData: BusinessData[]) => ({
  type: SET_BUSINESSES,
  payload: businessData,
});

export const setBusinessDetails = (usersData: BusinessData) => ({
  type: SET_BUSINESS_DETAILS,
  payload: usersData,
});

export const setLocations = (locatiosData: LocationData) => ({
  type: SET_LOCATIONS,
  payload: locatiosData,
});
