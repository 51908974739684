/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';

import Geocode from 'react-geocode';

import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';

import { UUID } from 'src/api';
import { history } from 'src/store';
import { useGetAPI } from 'src/api/countries';
import { defaultLocation } from 'src/constants';
import { useMutateAPI } from 'src/api/businesses';
import { useSnackbar } from 'src/components/snackbar';
import { IconButtonAnimate } from 'src/components/animate';
import { CreateBusinessSchema } from 'src/utils/validators';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';

import Map from '../Map/Map';
import useMapLoad from 'src/hooks/useMapLoad';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type BusinessInput = yup.Asserts<typeof CreateBusinessSchema>;

const CreateBusiness = () => {
  const navigate = useNavigate();

  const [zoom, setZoom] = useState<number>(10);
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [country, setCountry] = useState<any>({ name: 'United States', id: '' });
  const [center, setCenter] = useState<{ lat: number; lng: number }>(defaultLocation);
  const [location, setLocation] = useState<{ lat: number; lng: number }>(defaultLocation);
  const { enqueueSnackbar } = useSnackbar();

  const { data: countriesData } = useGetAPI();
  const { isLoading, isSuccess, createBusiness } = useMutateAPI();

  const { isLoaded, onLoad } = useMapLoad();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm<BusinessInput>({
    mode: 'all',
    resolver: yupResolver(CreateBusinessSchema),
  });

  const watchLocation = watch([`locations.${0}.latitude`, `locations.${0}.longitude`]);
  const watchCity = watch(`addresses.${0}.city`);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      navigate('/dashboard/businesses');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  useEffect(() => {
    setLocation({
      lat: parseFloat(watchLocation?.[0] || '0'),
      lng: parseFloat(watchLocation?.[1] || '0'),
    });
  }, [watchLocation[0], watchLocation[1]]);

  useEffect(() => {
    if (countriesData) {
      const country = countriesData?.data.find(
        (country: { name: string; id: UUID }) => country.name === 'United States'
      );
      setValue(`addresses.${0}.countryId`, country?.id);
    }
  }, [countriesData]);

  useEffect(() => {
    if (watchCity) {
      Geocode.fromAddress(watchCity).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;

          const { long_name } = response.results[0].address_components.find(
            (elem: { types: string[]; long_name: any }) => elem.types.includes('country')
          );
          if (long_name) {
            const country = countriesData?.data.find(
              (country: { name: string; id: UUID }) => country.name === long_name
            );
            if (country) {
              setValue(`locations.${0}.latitude`, lat);
              setValue(`locations.${0}.longitude`, lng);
              setZoom(13);
              setCountry({ name: country?.name, id: country?.id });
              setValue(`addresses.${0}.countryId`, country?.id);
              setCenter({ lat, lng });
              enqueueSnackbar('Country found', { variant: 'success' });
            }
          }
        },
        (err) => {
          enqueueSnackbar('Country was not found', { variant: 'error' });
        }
      );
    }
  }, [watchCity]);

  useEffect(() => {
    if (searchAddress) {
      Geocode.fromAddress(searchAddress).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          if (lat && lng) {
            setCenter({ lat, lng });
            setZoom(19);
            enqueueSnackbar('Address found', { variant: 'success' });
          }
        },
        (err) => {
          enqueueSnackbar('Address was not found', { variant: 'error' });
        }
      );
    }
  }, [searchAddress]);

  const onSubmitHandler: SubmitHandler<BusinessInput> = (values) => {
    const params = {
      ...values,
      locations: values?.locations?.map((location) => ({
        ...location,
        longitude: parseFloat(location.longitude || '0'),
        latitude: parseFloat(location.latitude || '0'),
      })),
      addresses: values?.addresses?.map((address) => ({
        ...address,
        state: address.state || undefined,
      })),
    };

    createBusiness(params);
  };

  const handleSelectedLocationChange = (lat: number, lng: number) => {
    setValue(`locations.${0}.latitude`, lat.toString());
    setValue(`locations.${0}.longitude`, lng.toString());
    clearErrors([`locations.${0}.latitude`, `locations.${0}.longitude`]);
  };

  return (
    <>
      <Helmet>
        <title>User Detailed View | LEV</title>
      </Helmet>
      <Box sx={{ display: 'flex', alignItems: 'baseline', padding: '0 20px' }}>
        <IconButtonAnimate color="primary" onClick={() => history.back()} sx={{ p: 1.25 }}>
          <ArrowBackIcon />
        </IconButtonAnimate>
        <Typography variant="h3" component="h1" paragraph>
          Add New Business
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 3fr',
          gap: '20px',
          width: '96%',
          margin: '0 auto',
        }}
      >
        <Box sx={{ maxWidth: '25rem' }}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box>
              <Typography variant="h4" component="h1" sx={{ mb: '1rem' }}>
                General details
              </Typography>
              <TextField
                sx={{ mb: 2 }}
                label="Name"
                fullWidth
                required
                error={fieldError(errors, 'name')}
                helperText={fieldHelpertext(errors, 'name')}
                {...register('name')}
              />
              <TextField
                sx={{ mb: 2 }}
                label="Description"
                fullWidth
                required
                type="text"
                multiline={true}
                error={fieldError(errors, 'description')}
                helperText={fieldHelpertext(errors, 'description')}
                {...register('description')}
              />
              <Typography variant="h4" component="h1" sx={{ m: '1rem 0' }}>
                HQ address
              </Typography>
              <TextField
                sx={{ mb: 2 }}
                label="Address"
                fullWidth
                required
                error={fieldError(errors.addresses?.[0], 'address')}
                helperText={fieldHelpertext(errors.addresses?.[0], 'address')}
                {...register(`addresses.${0}.address`)}
              />
              <TextField
                sx={{ mb: 2 }}
                label="Zip"
                fullWidth
                required
                error={fieldError(errors.addresses?.[0], 'zip')}
                helperText={fieldHelpertext(errors.addresses?.[0], 'zip')}
                {...register(`addresses.${0}.zip`)}
              />
              <TextField
                sx={{ mb: 2 }}
                label="City"
                defaultValue={'New York'}
                fullWidth
                required
                error={fieldError(errors.addresses?.[0], 'city')}
                helperText={fieldHelpertext(errors.addresses?.[0], 'city')}
                {...register(`addresses.${0}.city`)}
              />
              <TextField
                sx={{ mb: 2 }}
                label="State"
                fullWidth
                error={fieldError(errors.addresses?.[0], 'state')}
                helperText={fieldHelpertext(errors.addresses?.[0], 'state')}
                {...register(`addresses.${0}.state`)}
              />
              <Controller
                name={`addresses.${0}.countryId`}
                control={control}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    options={countriesData?.data || []}
                    getOptionLabel={(option): any => option.name}
                    onChange={(_, newValue) => {
                      setCountry({ name: newValue?.name, id: newValue?.id });
                      onChange(newValue?.id || undefined);
                    }}
                    value={country}
                    defaultValue={country}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={fieldError(errors.addresses?.[0], 'countryId')}
                        helperText={fieldHelpertext(errors.addresses?.[0], 'countryId')}
                        FormHelperTextProps={{
                          style: {
                            color: '#FF5630',
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
              <Typography variant="h4" component="h1" sx={{ m: '1rem 0' }}>
                First Ad location
              </Typography>
              <TextField
                sx={{ mb: 2 }}
                label="Location name"
                fullWidth
                required
                error={fieldError(errors.locations?.[0], 'title')}
                helperText={fieldHelpertext(errors.locations?.[0], 'title')}
                {...register(`locations.${0}.title`)}
              />
              <TextField
                sx={{ mb: 2 }}
                label="Latitude"
                fullWidth
                required
                defaultValue={location.lat}
                type="number"
                error={fieldError(errors.locations?.[0], 'latitude')}
                helperText={fieldHelpertext(errors.locations?.[0], 'latitude')}
                {...register(`locations.${0}.latitude`)}
              />
              <TextField
                sx={{ mb: 2 }}
                label="Longitude"
                fullWidth
                required
                defaultValue={location.lng}
                type="number"
                error={fieldError(errors.locations?.[0], 'longitude')}
                helperText={fieldHelpertext(errors.locations?.[0], 'longitude')}
                {...register(`locations.${0}.longitude`)}
              />
              <TextField
                InputProps={{
                  type: 'hidden',
                }}
                sx={{ visibility: 'hidden' }}
                value={uuidv4()}
                {...register('id')}
              />
              <TextField
                InputProps={{
                  type: 'hidden',
                }}
                sx={{ visibility: 'hidden' }}
                value={uuidv4()}
                {...register(`locations.${0}.id`)}
              />
              <TextField
                InputProps={{
                  type: 'hidden',
                }}
                sx={{ visibility: 'hidden' }}
                value={uuidv4()}
                {...register(`addresses.${0}.id`)}
              />
              <LoadingButton
                disabled={!isValid}
                variant="contained"
                fullWidth
                type="submit"
                sx={{ py: '0.8rem', mt: '1rem' }}
              >
                Add New Business
              </LoadingButton>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 6 }}>
          {isLoaded && (
            <>
              <TextField
                value={searchAddress}
                onChange={(e) => setSearchAddress(e.target.value as string)}
                sx={{ mt: '0.25rem', mb: '1rem', width: '30rem' }}
                label={'Search'}
                placeholder={'Address'}
              />
              <Map
                location={location}
                center={center}
                zoom={zoom}
                setLocation={setLocation}
                onLoad={onLoad}
                onSelectedLocationChange={handleSelectedLocationChange}
                styles={{
                  width: '100%',
                  height: 910,
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateBusiness;
