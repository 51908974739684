function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  login: '/',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  users: path(ROOTS_DASHBOARD, '/users'),
  registerBusiness: path(ROOTS_DASHBOARD, '/register'),
  businesses: path(ROOTS_DASHBOARD, '/businesses'),
  signInBusiness: path(ROOTS_DASHBOARD, '/sign-in'),
  customLocations: path(ROOTS_DASHBOARD, '/custom-locations'),
  businessTopup: path(ROOTS_DASHBOARD, '/business-topup'),
  treasure: path(ROOTS_DASHBOARD, '/treasure'),
  create: path(ROOTS_DASHBOARD, '/businesses/create'),
  chart: path(ROOTS_DASHBOARD, '/chart'),
  usersDescription: path(ROOTS_DASHBOARD, '/users/:id'),
  businessDescription: path(ROOTS_DASHBOARD, '/businesses/:id'),
  offerDescription: path(ROOTS_DASHBOARD, '/businesses/offers/:id'),
  businessDashboard: path(ROOTS_DASHBOARD, '/business/dashboard'),
  businessLocations: path(ROOTS_DASHBOARD, '/business/locations'),
  businessCampaigns: path(ROOTS_DASHBOARD, '/business/campaigns'),
  businessOffers: path(ROOTS_DASHBOARD, '/business/offers'),
  marketplacePerformance: path(ROOTS_DASHBOARD, '/marketplace-performance'),
  businessAnalytics: path(ROOTS_DASHBOARD, '/business/analytics'),
  businessAccount: path(ROOTS_DASHBOARD, '/business/account'),
  businessCampaignDetails: path(ROOTS_DASHBOARD, '/business/campaigns/:id'),
  businessOfferDetails: path(ROOTS_DASHBOARD, '/business/offers/:id'),
};
