import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { useDispatch } from 'src/store';
import { setTransactions } from 'src/store/actions/transactions';

import { businessAPI } from '.';
import { useSnackbar } from '../components/snackbar';

export const useGetAPI = ({ page, size, sort }: { page: number; size: number; sort: string[] }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, error, data } = useQuery(
    ['transactions', page, size, sort],
    () => businessAPI.getBusinessTransactions({ page, size, sort }),
    {
      onSuccess(data) {
        dispatch(setTransactions(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Topups: ${err.message} `, { variant: 'error' });
      },
    }
  );

  return { isLoading, error, data };
};
