import { MouseEvent, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  StyledTabList,
  StyledTab,
} from '../components/BusinessDetails/DetailedBusinessDescription';

import Edit from '@mui/icons-material/Edit';

import { RootState, useSelector } from 'src/store';
import { getOrNull } from 'src/helpers/formHeleprs';
import { useSnackbar } from 'src/components/snackbar';
import { IconButtonAnimate } from 'src/components/animate';
import { IPinLocation as IAdminPinLocation } from 'src/api/businessAPI';
import { useDynamicTableActions } from 'src/hooks/useDynamicTableActions';
import { StyledTableCell } from '../RoleBusinessLocations/RoleBusinessLocations';
import { useCreatePinAPI, useGetPinsLocationsAPI, useUpdatePinAPI } from 'src/api/location';
import { EComplaint, EPinLocationStatus, IPinLocation } from 'src/store/reducers/pinsLocations';
import {
  ALL,
  APPROVED_BY_ADMIN_PINS_HEAD_CELLS,
  POSTED_PINS_HEAD_CELLS,
  REPORTED_PINS_HEAD_CELLS,
} from 'src/constants';

import Show from '../components/Show/Show';
import DynamicTableHead from '../components/DynamicTableHead';
import DynamicTablePagination from '../components/DynamicTablePagination';
import PinCreateEditModal from 'src/components/modal/PinCreateEditModal';

export const POSTED_PINS_STATUSES = {
  POLLING: 'Polling',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
  DECLINED_BY_COMPLAINT: 'Declined by complaint',
  APPROVED_BY_ADMIN: 'Approved by admin',
};

export const REPORTED_PINS_TYPES = {
  NO_LONGER_DOG_FRIENDLY: 'Not dog-friendly',
  NOT_EXISTS: 'Does not exist',
  DUPLICATE: 'Duplicate',
  ABUSIVE_INFO: 'Abusive info or description',
  OTHER: 'Other',
};

export const POSTED_TYPES = {
  CAFE: 'Cafe',
  BAR: 'Bar',
  RESTAURANT: 'Restaurant',
  PARK: 'Park',
  HOTEL: 'Hotel',
  SUPERMARKET: 'Supermarket',
  PET_SHOP: 'Pet shop',
  VET: 'Vet',
  GROOMER: 'Groomer',
  BEACH: 'Beach',
  FLOWER_SHOP: 'Flower shop',
  DOG_SHELTER: 'Dog shelter',
  DOGGY_DAYCARE: 'Doggy daycare',
  OTHER: 'Other',
};

export const POSTED_PINS_VOTE_RESULTS = {
  NOT_FRIENDLY: 'Not friendly',
  NO_SUCH_PLACE: 'No such place',
  DOG_FRIENDLY: 'Dog friendly',
};

const POSTED_PINS = [
  { label: 'Polling', value: 'POLLING' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Declined', value: 'DECLINED' },
  { label: 'Expired', value: 'EXPIRED' },
];

const REPORTED_PINS = [
  { label: 'Not dog-friendly', value: 'NO_LONGER_DOG_FRIENDLY' },
  { label: 'Does not exist', value: 'NOT_EXISTS' },
  { label: 'Duplicate', value: 'DUPLICATE' },
  { label: 'Abusive info or description', value: 'ABUSIVE_INFO' },
  { label: 'Other', value: 'OTHER' },
];

interface ICutomLocationsTable {
  values: any;
  isReported?: boolean;
  statusByAdmin?: EPinLocationStatus.APPROVED_BY_ADMIN;
  inputValues: {
    value: string;
    label: string;
  }[];
  headCells: {
    id: string;
    label: string;
  }[];
}

const CustomLocationsTable = ({
  values,
  headCells,
  isReported,
  inputValues,
  statusByAdmin,
}: ICutomLocationsTable) => {
  const [status, setStatus] = useState<EPinLocationStatus | 'ALL'>(ALL);
  const [complaintStatus, setComplaintStatus] = useState<EComplaint | 'ALL'>(ALL);
  const [isEditPin, setIsEditPin] = useState<boolean>(false);
  const [isCreatePin, setIsCreatePin] = useState<boolean>(false);
  const [pin, setPin] = useState<IAdminPinLocation | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const pinsLocations = useSelector((state: RootState) => state.pinsLocations);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    sort,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    reset,
  } = useDynamicTableActions('audit.createdDate');

  const { isLoading, isSuccess, createPin, reset: resetCreatePin } = useCreatePinAPI();
  const {
    isLoading: loading,
    isSuccess: success,
    updatePin,
    reset: resetUpdatePin,
  } = useUpdatePinAPI();

  useGetPinsLocationsAPI({
    page,
    sort,
    size: rowsPerPage,
    status:
      statusByAdmin === EPinLocationStatus.APPROVED_BY_ADMIN
        ? [statusByAdmin]
        : isReported
        ? [EPinLocationStatus.DECLINED_BY_COMPLAINT]
        : status === ALL
        ? Object.values(EPinLocationStatus).filter(
            (status) =>
              status !== EPinLocationStatus.DECLINED_BY_COMPLAINT &&
              status !== EPinLocationStatus.APPROVED_BY_ADMIN
          )
        : [status],
    complaintStatus: isReported
      ? complaintStatus === ALL
        ? Object.values(EComplaint)
        : [complaintStatus]
      : undefined,
  });

  const filter = useMemo(
    () => (isReported ? complaintStatus : status),
    [isReported, status, complaintStatus]
  );

  return (
    <>
      <Box>
        <Show
          condition={!statusByAdmin}
          or={
            <Box textAlign={'end'}>
              <Button
                variant="contained"
                sx={{ mt: '0.75rem', mb: '1rem' }}
                onClick={() => setIsCreatePin(true)}
              >
                Create Pin
              </Button>
            </Box>
          }
        >
          <TextField
            select
            label={isReported ? 'Report type' : 'Status'}
            sx={{ width: '15rem', mb: 2 }}
            value={filter}
            disabled={!pinsLocations?.pinsLocations?.length && !getOrNull(values[filter], ALL)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              reset();
              if (isReported) {
                return setComplaintStatus(e.target.value as EComplaint);
              }
              setStatus(e.target.value as EPinLocationStatus);
            }}
            variant="outlined"
          >
            <MenuItem key={ALL} value={ALL}>
              All
            </MenuItem>
            {inputValues?.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Show>
      </Box>
      <Show
        condition={!!pinsLocations?.pinsLocations?.length}
        or={
          <Typography variant="h4" component="h1" paragraph>
            There are no {getOrNull(values[filter], ALL)} locations
          </Typography>
        }
      >
        <Paper sx={{ width: '100%', overflow: 'hidden', mb: 2 }}>
          <TableContainer
            sx={{
              height: 670,
              '&::-webkit-scrollbar:vertical': {
                display: 'none',
              },

              '&::-webkit-scrollbar': {
                height: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(145, 158, 171, 0.16)',
                borderRadius: '12px',
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table" size={'medium'}>
              <DynamicTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                rowsCount={pinsLocations?.pinsLocations?.length}
                headCells={headCells}
              />
              <TableBody>
                {pinsLocations?.pinsLocations?.map((location: IPinLocation) => (
                  <TableRow hover tabIndex={-1} key={location.id}>
                    <StyledTableCell
                      align="center"
                      sx={{
                        maxWidth: 240,
                      }}
                    >
                      {location?.name}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 320,
                      }}
                      align="center"
                    >
                      {location?.address}
                    </StyledTableCell>
                    <StyledTableCell align="center">{location?.phone || '-'}</StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 400,
                        cursor: 'pointer',
                        overflow: 'hidden',
                      }}
                      align="center"
                      onClick={(e: MouseEvent<HTMLTableCellElement>) => {
                        navigator.clipboard.writeText((e.target as HTMLElement).innerText);
                        enqueueSnackbar('Copied', { variant: 'success' });
                      }}
                    >
                      {location?.notes || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">{POSTED_TYPES[location?.type]}</StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        maxWidth: 250,
                      }}
                    >
                      {location?.createdBy.name || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {new Date(location?.createdDate || '').toLocaleDateString()}
                    </StyledTableCell>
                    <Show condition={!statusByAdmin}>
                      <Show
                        condition={!location?.complaintStatus}
                        or={
                          <StyledTableCell align="center">
                            {values[location?.complaintStatus] || '-'}
                          </StyledTableCell>
                        }
                      >
                        <StyledTableCell align="center">
                          {values[location?.status] || '-'}{' '}
                          {location?.status === EPinLocationStatus.DECLINED &&
                            `(${POSTED_PINS_VOTE_RESULTS[location?.voteResult]})`}
                        </StyledTableCell>
                      </Show>
                    </Show>
                    <Show condition={!!statusByAdmin}>
                      <StyledTableCell>
                        <IconButtonAnimate
                          color="primary"
                          onClick={() => {
                            setIsEditPin(true);
                            // @ts-ignore
                            setPin(location);
                          }}
                        >
                          <Edit />
                        </IconButtonAnimate>
                        <Divider orientation="vertical" flexItem />
                      </StyledTableCell>
                    </Show>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <DynamicTablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            totalPages={pinsLocations?.totalPages}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
          />
        </Paper>
      </Show>
      <Show condition={statusByAdmin && isCreatePin}>
        <PinCreateEditModal
          pin={pin}
          open={isCreatePin}
          isLoading={isLoading}
          isSuccess={isSuccess}
          setOpened={setIsCreatePin}
          mutate={createPin}
          resetMutation={resetCreatePin}
        />
      </Show>
      <Show condition={statusByAdmin && isEditPin && !!pin}>
        <PinCreateEditModal
          pin={pin}
          open={isEditPin}
          isLoading={loading}
          isSuccess={success}
          setPin={setPin}
          setOpened={setIsEditPin}
          mutate={updatePin}
          resetMutation={resetUpdatePin}
        />
      </Show>
    </>
  );
};

const CustomLocations = () => {
  const [activeTab, setActiveTab] = useState('3');
  return (
    <>
      <Helmet>
        <title>Custom user locations | LEV</title>
      </Helmet>
      <Box sx={{ width: '100%', padding: '0 20px' }}>
        <TabContext value={activeTab}>
          <Box sx={{ width: '100%' }}>
            <Box>
              <StyledTabList onChange={(_, newValue) => setActiveTab(newValue)}>
                <StyledTab label="Created locations" value="1" />
                <StyledTab label="Reported locations" value="2" />
                <StyledTab label="Admin locations" value="3" />
              </StyledTabList>
            </Box>
            <TabPanel value="1">
              <CustomLocationsTable
                inputValues={POSTED_PINS}
                values={POSTED_PINS_STATUSES}
                headCells={POSTED_PINS_HEAD_CELLS}
              />
            </TabPanel>
            <TabPanel value="2">
              <CustomLocationsTable
                isReported
                inputValues={REPORTED_PINS}
                values={REPORTED_PINS_TYPES}
                headCells={REPORTED_PINS_HEAD_CELLS}
              />
            </TabPanel>
            <TabPanel value="3">
              <CustomLocationsTable
                inputValues={REPORTED_PINS}
                values={REPORTED_PINS_TYPES}
                headCells={APPROVED_BY_ADMIN_PINS_HEAD_CELLS}
                statusByAdmin={EPinLocationStatus.APPROVED_BY_ADMIN}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </>
  );
};

export default CustomLocations;
