import { SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { IParamsKeys, useSearchQuery } from './useSearchQuery';

export type Order = 'asc' | 'desc';

export const useDynamicTableActions = (defaultOrder: string) => {
  const { params, onParamsChange } = useSearchQuery();
  const [order, setOrder] = useState<Order>(params[IParamsKeys.ORDER] ?? 'desc');
  const [orderBy, setOrderBy] = useState<string>(params[IParamsKeys.ORDER_BY] ?? defaultOrder);
  const [page, setPage] = useState(params[IParamsKeys.PAGE] ?? 1);
  const [rowsPerPage, setRowsPerPage] = useState(params[IParamsKeys.ROWS_PER_PAGE] ?? 10);
  const [searchText, setSearchText] = useState(params[IParamsKeys.SEARCH_TEXT] ?? '');

  const sort = [orderBy + ',' + order];

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const orderDirection = order === 'asc' ? 'desc' : 'asc';
    setOrder(orderDirection);
    setOrderBy(property);
    onParamsChange({
      [IParamsKeys.ORDER]: orderDirection,
      [IParamsKeys.ORDER_BY]: property
    })
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onParamsChange({ [IParamsKeys.PAGE]: newPage });
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    setPage(1);
    onParamsChange({
      [IParamsKeys.PAGE]: 1,
      [IParamsKeys.ROWS_PER_PAGE]: value
    })
  };

  const handleSearchText = (e: any) => {
      setSearchText(e.target.value);
      setPage(1)
      onParamsChange({
        [IParamsKeys.SEARCH_TEXT]: e.target.value,
        [IParamsKeys.PAGE]: 1
      });
    }

  return {
    sort,
    order,
    orderBy,
    rowsPerPage,
    page,
    searchText,
    onRequestSort: handleRequestSort,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onSearchTextChange: handleSearchText,
    reset: () => setPage(1),
  };
};
