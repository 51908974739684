import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const Login = Loadable(lazy(() => import('../pages/Login/Login')));

export const Users = Loadable(lazy(() => import('../pages/Users/Users')));
export const Businesses = Loadable(lazy(() => import('../pages/Businesses/Businesses')));
export const Treasury = Loadable(lazy(() => import('../pages/Treasury/Treasury')));

export const RoleBusinessOffers = Loadable(
  lazy(() => import('../pages/RoleBusinessOffers/RoleBusinessOffers'))
);
export const RoleBusinessAccount = Loadable(
  lazy(() => import('../pages/RoleBusinessAccount/RoleBusinessAccount'))
);
export const RoleBusinessLocations = Loadable(
  lazy(() => import('../pages/RoleBusinessLocations/RoleBusinessLocations'))
);
export const RoleBusinessDashboard = Loadable(
  lazy(() => import('../pages/RoleBusinessDashboard/RoleBusinessDashboard'))
);
export const RoleBusinessCampaigns = Loadable(
  lazy(() => import('../pages/RoleBusinessCampaigns/RoleBusinessCampaigns'))
);
export const RoleBusinessAnalytics = Loadable(
  lazy(() => import('../pages/RoleBusinessAnalytics/RoleBusinessAnalytics'))
);
export const RoleBusinessCampaignDetails = Loadable(
  lazy(() => import('../pages/components/RoleBusinessCampaignDetails/RoleBusinessCampaignDetails'))
);
export const RoleBusinessOfferDetails = Loadable(
  lazy(() => import('../pages/components/RoleBusinessOfferDetails/RoleBusinessOfferDetails'))
);

export const Page404 = Loadable(lazy(() => import('../pages/Page404/Page404')));
