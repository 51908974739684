// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import PaidIcon from '@mui/icons-material/Paid';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_user'),
  business_dashboard: icon('ic_dashboard_2'),
  campaigns: icon('ic_campaigns'),
  locations: icon('ic_locations'),
  offers: icon('ic_offers'),
  statistics: icon('ic_statistics'),
  account: icon('ic_account'),
};

export const navConfig = [
  {
    subheader: 'Content',
    items: [
      { title: 'Users', path: PATH_DASHBOARD.users, icon: ICONS.dashboard },
      { title: 'Businesses', path: PATH_DASHBOARD.businesses, icon: ICONS.ecommerce },
      { title: 'Treasury', path: PATH_DASHBOARD.treasure, icon: <PaidIcon /> },
      {
        title: 'Bones purchase history',
        path: PATH_DASHBOARD.businessTopup,
        icon: <FormatListBulletedIcon />,
      },
      {
        title: 'Custom user locations',
        path: PATH_DASHBOARD.customLocations,
        icon: <PersonPinCircleIcon fontSize={'large'} />,
      },
      { 
        title: 'Marketplace Performance', 
        path: PATH_DASHBOARD.marketplacePerformance,
        icon: ICONS.analytics
      },
    ],
  },
];

export const businessNavConfig = [
  {
    subheader: 'Content',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.businessDashboard,
        icon: ICONS.business_dashboard,
      },
      { title: 'Campaigns', path: PATH_DASHBOARD.businessCampaigns, icon: ICONS.campaigns },
      { title: 'Locations', path: PATH_DASHBOARD.businessLocations, icon: ICONS.locations },
      {
        title: 'Offers',
        path: PATH_DASHBOARD.businessOffers,
        icon: ICONS.offers,
      },
      {
        title: 'Analytics',
        path: PATH_DASHBOARD.businessAnalytics,
        icon: ICONS.statistics,
      },
      { title: 'Account', path: PATH_DASHBOARD.businessAccount, icon: ICONS.account },
    ],
  },
];
