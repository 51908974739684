import { UUID } from 'src/api';
import { SET_BATCH_DETAILS } from '../actions/batches';

export interface IBatchState {
  id: UUID;
  displayId: number;
  status: string;
  location: {
    id: UUID;
    address: string;
    state?: string;
    zip: string;
    longitude: number;
    latitude: number;
    city: string;
    country: {
      id: UUID;
      name: string;
      image?: string;
    };
  };
  message: string;
  totalBones: number;
  bonesLeft: number;
  createdDate: string;
  finishedDate: string;
  notes: string;
}

const initialState: IBatchState = {
  id: '',
  displayId: 0,
  status: '',
  location: {
    id: '',
    longitude: 0,
    latitude: 0,
    country: {
      id: '',
      name: '',
      image: '',
    },
    city: '',
    zip: '',
    address: '',
  },
  message: '',
  totalBones: 0,
  bonesLeft: 0,
  createdDate: '',
  finishedDate: '',
  notes: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const batchDetailInfoReducer = (state = initialState, action: any): IBatchState => {
  switch (action.type) {
    case SET_BATCH_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default batchDetailInfoReducer;
