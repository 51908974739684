import { OffersData, SET_OFFER_DETAILS } from '../actions/offers';

const initialState: OffersData = {
  id: '',
  title: '',
  banner: '',
  shortDescription: '',
  description: '',
  type: '',
  category: '',
  discountType: '',
  promoText: '',
  price: 0,
  status: '',
  totalOffersLimit: 0,
  totalOffers: 0,
  offersLeft: 0,
  business: {
    id: '',
    name: '',
  },
  isUseInBatchBones: false,
  websiteUrl: '',
  amount: 0,
  averageOrderValue: 0,
  discountPercentage: 0,
  retailPrice: 0,
  createdDate: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const offerDetailInfoReducer = (state = initialState, action: any): OffersData => {
  switch (action.type) {
    case SET_OFFER_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default offerDetailInfoReducer;
