import { instance } from './index';

export const authAPI = {
  adminSignIn(params: { email: string; password: string }) {
    return instance.post('auth/admin-sign-in', params);
  },
  registerAccount(email: string) {
    return instance.post('auth/admin-sign-up', { email });
  },
  confirmCode(params: { email: string; code: string }) {
    return instance.post('auth/confirm-code', params);
  },
  sendCode(email: string) {
    return instance.post('auth/admin-send-code', { email });
  },
  refreshToken(refreshToken: string) {
    return instance.post('auth/refresh-token', { refreshToken });
  },
  logout() {
    return instance.post('auth/logout');
  },
};
