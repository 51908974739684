import { Box, Divider, Typography, Card, CardContent } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { DetailInfoState } from 'src/store/reducers/user';

const UserCard = ({
  name,
  email,
  created,
  totalBones = 0,
  totalBonesSpent = 0,
}: DetailInfoState) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="h3" component="h1" paragraph>
        User Info
      </Typography>
      <Card sx={{ width: 'fit-content' }}>
        <CardContent>
          <Typography sx={{ fontSize: 24 }}>{name}</Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" component="div">
            {email}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" component="div">
            Member since: {new Date(created || '').toLocaleDateString()}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Typography sx={{ fontSize: 20 }}>
            <b style={{ color: theme.palette.primary.light }}>Balance: </b>
            {totalBones}
          </Typography>
          <Typography sx={{ fontSize: 20 }}>
            <b style={{ color: theme.palette.primary.light }}>Total Spent: </b>
            {totalBonesSpent}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserCard;
