import * as yup from 'yup';

import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import { Box, Link, styled, TextField, Typography } from '@mui/material';

import { useRegisterAPI } from 'src/api/auth';
import { RegisterBusinessSchema } from 'src/utils/validators';
import { fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';
import { BusinessStyledTypograhpy } from 'src/pages/RoleBusinessLocations/RoleBusinessLocations';

type RegisterBusinessInput = yup.Asserts<typeof RegisterBusinessSchema>;

export const StyledTextField = styled(TextField)(() => ({
  '.MuiInputLabel-root': {
    fontFamily: 'CircularStd',
  },
  '.MuiInput': {
    fontFamily: 'CircularStd',
  },
  '.MuiSelect-select': {
    fontFamily: 'CircularStd',
  },
  input: {
    fontFamily: 'CircularStd',
    borderRadius: '12px',
  },
  borderRadius: '12px',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Dela Gothic One',
}));

const Register = ({ setActiveStep, setEmail }: { setActiveStep: any; setEmail: any }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<RegisterBusinessInput>({
    mode: 'all',
    resolver: yupResolver(RegisterBusinessSchema),
  });

  const { isLoading, isSuccess, registerAccount } = useRegisterAPI();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setActiveStep((step: number) => step + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  const onSubmitHandler: SubmitHandler<RegisterBusinessInput> = (values) => {
    setEmail(values?.email);
    registerAccount(values?.email as string);
  };

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
      <Box sx={{ minWidth: '25rem', width: 380, m: '0 auto' }}>
        <BusinessStyledTypograhpy fontSize={22} fontWeight={'bold'} sx={{ mb: 1 }}>
          Create Business Account
        </BusinessStyledTypograhpy>
        <StyledTextField
          sx={{ mb: 2 }}
          label="Email Address"
          fullWidth
          required
          error={fieldError(errors, 'email')}
          helperText={fieldHelpertext(errors, 'email')}
          {...register('email')}
        />
        <LoadingButton
          disabled={!isValid}
          variant="contained"
          fullWidth
          type="submit"
          sx={{
            fontSize: 18,
            fontFamily: 'CircularStd',
            height: 50,
          }}
        >
          Continue
        </LoadingButton>
        <Box>
          <BusinessStyledTypograhpy fontWeight={500} fontSize={14} sx={{ mt: 6 }}>
            Already have an account?
          </BusinessStyledTypograhpy>
          <Link
            href="/manager-login"
            underline="always"
            fontWeight={500}
            fontSize={14}
            fontFamily={'CircularStd'}
          >
            Sign in
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
