import * as React from 'react';

import { SxProps } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { RootState, useSelector } from 'src/store';
import { Order } from 'src/hooks/useDynamicTableActions';
import { HeadCell, ROLE_BUSINESS_MANAGER } from 'src/constants';

interface DynamicTableHeadProps {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  order?: Order;
  orderBy?: string;
  rowsCount?: number;
  headCells: HeadCell[];
}

const DynamicTableHead = ({
  order,
  orderBy,
  onRequestSort = () => {},
  headCells,
}: DynamicTableHeadProps) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const role = useSelector((state: RootState) => state.account.role);

  const detectAlignment = (headCellId: string) =>
    role === ROLE_BUSINESS_MANAGER && headCellId === 'actions' ? 'right' : 'left';

  const finalTableCellStyles =
    role === ROLE_BUSINESS_MANAGER
      ? { fontFamily: 'CircularStd', fontSize: 10, textTransform: 'uppercase' }
      : { textAlign: 'center' };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={detectAlignment(headCell.id)}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={finalTableCellStyles as SxProps}
          >
            {order ? (
              <TableSortLabel
                disabled={[
                  'bonesBalance',
                  'linkedDogsCount',
                  'locations',
                  'activeBatches',
                  'bonesBalance',
                  'bonesLeft',
                  'status',
                  'userEmail',
                  'progress',
                  'activeBatchBones',
                  'activeOffers',
                  'totalBonesSpentLast30Days',
                  'totalBonesSpent',
                  'complaintStatus',
                  'createdBy',
                  'longitude',
                  'latitude',
                  'actions',
                  'price',
                  'totalBones',
                  'offer',
                  'offersLeft',
                  'totalInvited'
                ].includes(headCell.id)}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default DynamicTableHead;
