import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useEffect, useMemo, useState } from 'react';

import Edit from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, Divider, Link, Typography, useTheme } from '@mui/material';

import { OFFER_VALUES } from 'src/constants';
import { EditOfferSchema } from 'src/utils/validators';
import { isValidHttpUrl } from 'src/helpers/formHeleprs';
import { IconButtonAnimate } from 'src/components/animate';
import { history, RootState, useSelector } from 'src/store';
import { useGetAPI, useMutateAPI } from 'src/api/offerDetails';

import Show from '../Show/Show';
import CreateEditOffer from './TabsContent/CreateEditOffer';
import { useUploadCsv } from 'src/hooks/useUploadCsv';

export const CsvDownloader: any = () => (
  <Button
    variant="contained"
    sx={{ mt: 2, textTransform: 'none', fontFamily: 'CircularStd', fontSize: 16 }}
    startIcon={<FileDownloadIcon />}
    href={process.env.REACT_APP_DISCOUNT_TEMPLATE}
  >
    Download Sample
  </Button>
);

const DetailedOffersDescription = () => {
  const theme = useTheme();
  const [isEdit, setIsEdit] = useState(false);
  const { id = '' } = useParams();
  const offer = useSelector((state: RootState) => state.offerDetailInfo);

  useGetAPI(id);

  const { loading, handleUploadCsv } = useUploadCsv(id);

  const { isLoading, isSuccess, updateOffer, reset } = useMutateAPI();

  const isManual = useMemo(() => offer?.discountType === 'MANUALLY', [offer]);

  const uploadStyles = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  const showInfo = (
    title: string,
    value: any,
    margin?: number | null,
    upload?: boolean,
    isUrl?: boolean
  ) => (
    <Box
      sx={{
        mb: margin || 1,
        ...(upload && isManual && uploadStyles),
      }}
    >
      <Box flexGrow={0.9}>
        <Typography color="text.secondary" sx={{ fontSize: 16 }}>
          {title}
        </Typography>
        <Divider />

        <Show
          condition={isUrl}
          or={<Typography sx={{ mt: 0.5, fontSize: 18 }}>{value}</Typography>}
        >
          <Link
            onClick={() => window.open(isValidHttpUrl(value) ? value : `http://${value}`)}
            sx={{ cursor: 'pointer' }}
            underline="none"
          >
            {value}
          </Link>
        </Show>
      </Box>
      <Show condition={upload && isManual}>
        <Box>
          <Typography fontSize={14} color={'primary'}>
            Codes: {offer?.totalOffers}
          </Typography>
          <LoadingButton
            variant="contained"
            component="label"
            startIcon={<FileUploadIcon />}
            loading={loading}
          >
            Upload CSV
            <input id="file" type="file" accept=".csv" hidden onChange={handleUploadCsv} />
          </LoadingButton>
        </Box>
      </Show>
    </Box>
  );

  const fileInput = document.getElementById('file');
  //@ts-ignore
  const emptyFileInput = () => (fileInput.value = '');
  fileInput?.addEventListener('click', emptyFileInput);

  useEffect(
    () => fileInput?.removeEventListener('click', emptyFileInput),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <Helmet>
        <title>Business Detailed View | LEV</title>
      </Helmet>
      <Box sx={{ width: '100%', padding: '0 20px', display: 'flex' }}>
        <Box marginRight={1}>
          <IconButtonAnimate color="primary" onClick={() => history.back()} sx={{ p: 1.25 }}>
            <ArrowBackIcon />
          </IconButtonAnimate>
        </Box>
        <Box flexGrow={1}>
          <Box display={'flex'} textAlign={'center'} mb={1}>
            <Typography variant="h3" component="h1">
              Offer Info
            </Typography>
            <IconButtonAnimate
              disabled={!offer?.totalOffers}
              color="primary"
              onClick={() => setIsEdit(!isEdit)}
              sx={{ p: 1.25 }}
            >
              <Edit />
            </IconButtonAnimate>
          </Box>
          {isEdit ? (
            <CreateEditOffer
              data={offer}
              offerId={id}
              isLoading={isLoading}
              isSuccess={isSuccess}
              schema={EditOfferSchema}
              mutate={updateOffer}
              setIsAction={setIsEdit}
              resetMutation={reset}
            />
          ) : (
            <>
              <Card sx={{ maxWidth: 600 }}>
                <CardContent>
                  <Box>
                    {showInfo('Title', offer?.title, 0)}
                    <Box>
                      <Typography color="text.secondary" sx={{ fontSize: 16 }}>
                        Banner
                      </Typography>
                      <Divider sx={{ mb: 1 }} />
                      <Box
                        component="img"
                        display={'inline-block'}
                        sx={{
                          borderRadius: '10%',
                        }}
                        alt="banner"
                        src={offer?.banner}
                      />
                    </Box>
                    {showInfo('Short description', offer?.shortDescription)}
                    {showInfo('Full Description', offer?.description)}
                    {showInfo('Category', OFFER_VALUES[offer?.category || ''])}
                    {showInfo('Price', offer?.price)}
                    {showInfo('Type', OFFER_VALUES[offer?.type || ''])}
                    <Show condition={!!offer?.websiteUrl}>
                      {showInfo('Website URL', offer?.websiteUrl, null, false, true)}
                    </Show>
                    {showInfo('Codes type', OFFER_VALUES[offer?.discountType || ''], null, true)}
                    <Show
                      condition={
                        OFFER_VALUES[offer?.discountType || ''] === OFFER_VALUES.GENERATE_CODES
                      }
                    >
                      <Box
                        sx={{
                          mb: 1,
                        }}
                      >
                        <Box flexGrow={0.9}>
                          <Typography color="text.secondary" sx={{ fontSize: 16 }}>
                            Offers left
                          </Typography>
                          <Divider />
                          <Typography
                            sx={{ mt: 0.5, fontSize: 18, color: theme.palette.primary.main }}
                          >
                            {offer?.offersLeft}
                          </Typography>
                        </Box>
                      </Box>
                    </Show>
                    <Show condition={!!offer?.promoText}>
                      {showInfo('Promotional text', offer?.promoText, 0)}
                    </Show>
                  </Box>
                </CardContent>
              </Card>
              <Show condition={isManual}>
                <CsvDownloader />
              </Show>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DetailedOffersDescription;
