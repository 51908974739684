import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, Button, Fade, Modal, TextField, Typography } from '@mui/material';

import { UUID } from 'src/api';
import { TOPUP_DIRECTION } from 'src/api/businessAPI';
import { modalStyles } from 'src/helpers/modalStyles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { acceptOnlyNumbers, fieldError, fieldHelpertext } from 'src/helpers/formHeleprs';

const TopupBonesBalanceModal = memo(
  ({
    id,
    open,
    schema,
    isUser,
    isLoading,
    isSuccess,
    setIsOpen,
    resetMutation,
    mutate,
  }: {
    id: UUID;
    open: boolean;
    schema: yup.AnyObjectSchema;
    isUser?: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    resetMutation: () => void;
    mutate: (params: any) => void;
  }) => {
    const {
      register,
      formState: { errors, isValid },
      handleSubmit,
    } = useForm<yup.Asserts<typeof schema>>({
      mode: 'all',
      resolver: yupResolver(schema),
    });

    const onSubmitHandler: SubmitHandler<yup.Asserts<typeof schema>> = (values) => {
      const params = {
        ...values,
        direction: isUser ? undefined : TOPUP_DIRECTION.IN,
      };

      mutate({ id, params });
    };

    useEffect(() => {
      if (!isLoading && isSuccess) {
        setIsOpen(!open);
        resetMutation();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isSuccess]);

    return (
      <Modal
        open={!!open}
        onClose={() => setIsOpen(!open)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!open}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box minWidth={350} sx={modalStyles}>
              <Typography id="transition-modal-title" variant="h6">
                {isUser ? 'Add bones' : 'Purchase bones'}
              </Typography>
              <TextField
                sx={{ mb: 2, mt: 2 }}
                label="Amount"
                type="number"
                fullWidth
                required
                defaultValue={isUser ? 1 : 100}
                onKeyDown={(event) => acceptOnlyNumbers(event)}
                error={fieldError(errors, 'amount')}
                helperText={fieldHelpertext(errors, 'amount')}
                {...register('amount')}
              />
              <TextField
                InputProps={{
                  type: 'hidden',
                }}
                sx={{ visibility: 'hidden' }}
                value={uuidv4()}
                {...register('id')}
              />
              <Box display={'flex'} gap={2}>
                <LoadingButton
                  disabled={!isValid}
                  loading={isLoading}
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{ py: '0.8rem', mt: '1rem' }}
                >
                  {isUser ? 'Add bones' : 'Purchase'}
                </LoadingButton>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ py: '0.8rem', mt: '1rem' }}
                  onClick={() => setIsOpen(!open)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  }
);

export default TopupBonesBalanceModal;
