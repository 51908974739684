import { HeadCell } from 'src/constants';
import { IOfferFilterItem } from './types';

export enum MPOfferStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  EMPTY = 'EMPTY',
  NOT_ACTIVE = 'NOT_ACTIVE',
  COMPLETED = 'COMPLETED',
}

export const OFFER_FILTERS: IOfferFilterItem[] = [
  {
    label: 'All',
    value: [
      MPOfferStatus.ACTIVE,
      MPOfferStatus.PAUSED,
      MPOfferStatus.EMPTY,
      MPOfferStatus.NOT_ACTIVE,
      MPOfferStatus.COMPLETED,
    ],
  },
  {
    label: 'Active',
    value: [MPOfferStatus.ACTIVE],
  },
  {
    label: 'Paused',
    value: [MPOfferStatus.PAUSED],
  },
  {
    label: 'Empty',
    value: [MPOfferStatus.EMPTY],
  },
  {
    label: 'Not active',
    value: [MPOfferStatus.NOT_ACTIVE],
  },
  {
    label: 'Completed',
    value: [MPOfferStatus.COMPLETED],
  },
];

export const HEAD_CELLS: HeadCell[] = [
  {
    id: 'title',
    label: 'Name of offer',

  },
  {
    id: 'business',
    label: 'Business name',
  },
  {
    id: 'opened',
    label: 'View',
  },
  {
    id: 'purchased',
    label: 'Purchases',
  },
  {
    id: 'activated',
    label: 'Activations',
  },
];
