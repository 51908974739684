import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDispatch } from 'src/store';
import { setBusinessDetails } from 'src/store/actions/businesses';

import { businessAPI, UUID } from '.';
import { useSnackbar } from '../components/snackbar';

export const useGetAPI = ({ id }: { id: UUID }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, error, data } = useQuery(
    ['business', id],
    () => businessAPI.getBusinessDetails(id),
    {
      onSuccess(data) {
        dispatch(setBusinessDetails(data.data));
      },
      onError(err: AxiosError) {
        enqueueSnackbar(`Business details: ${err.message} `, { variant: 'error' });
      },
      enabled: !!id,
    }
  );

  return { isLoading, error, data };
};

export const useMutateAPI = (reset: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, isSuccess, mutate } = useMutation(businessAPI.updateBusinessBasicInfo, {
    onSuccess() {
      reset();
      queryClient.invalidateQueries('business');
      enqueueSnackbar(`Business updated successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Business update: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, updateBasicInfo: mutate };
};

export const useUpdateAPI = (reset: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, isSuccess, mutate } = useMutation(businessAPI.updateBusinessAddress, {
    onSuccess() {
      reset();
      queryClient.invalidateQueries('business');
      enqueueSnackbar(`Business address updated successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Business address update: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, updateAddress: mutate };
};

export const useUpdateBusinessAccountAPI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, isSuccess, mutate, reset } = useMutation(businessAPI.updateBusinessAccount, {
    onSuccess() {
      queryClient.invalidateQueries('businesses');
      enqueueSnackbar(`Business account updated successfully`, { variant: 'success' });
    },
    onError(err: AxiosError) {
      enqueueSnackbar(`Business account update: ${err.message} `, { variant: 'error' });
    },
  });

  return { isLoading, isSuccess, updateBusinessAccount: mutate, reset };
};
