import { ChangeEvent } from 'react';
import { UUID } from 'src/api';
import { useUploadCsvAPI } from 'src/api/file';

export const useUploadCsv = (id?: UUID) => {
  const { isLoading: loading, isSuccess: success, uploadCsvFile } = useUploadCsvAPI();

  const handleUploadCsv = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    uploadCsvFile({ id, file: formData });
  };

  return {
    loading,
    success,
    handleUploadCsv,
  };
};
