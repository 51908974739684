import { ITransaction, SET_TRANSACTIONS } from '../actions/transactions';

export interface TransactionsState {
  transactions: ITransaction[];
  totalPages: number;
  totalElements: number;
}

const initialState: TransactionsState = {
  transactions: [],
  totalPages: 0,
  totalElements: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const transactionsReducer = (state = initialState, action: any): TransactionsState => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return {
        transactions: action.payload.content,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
