import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';

export interface LogoProps extends BoxProps {
  isMain?: boolean;
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ isMain = false, disabledLink = false, sx }, ref) => {
    const theme = useTheme();
    const textLogo =
      theme.palette.mode === 'dark' ? '/logo/logo-white.png' : '/logo/logo-black.png';
    const source = isMain ? '/logo/logo.png' : textLogo;

    const logo = <Box component="img" src={source} sx={{ cursor: 'pointer', ...sx }} />;

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
