import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { useCallback, useRef } from 'react';

const useMapLoad = () => {
  const mapRef = useRef<GoogleMap>();
  const onLoad = useCallback((map: any) => (mapRef.current = map), []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY || '',
  });

  return { isLoaded, mapRef, onLoad };
};

export default useMapLoad;
