import { Dayjs } from 'dayjs';
import { EComplaint, EPinLocationStatus } from 'src/store/reducers/pinsLocations';
import { instance, UUID } from './index';
import { EPINS } from 'src/components/modal/PinCreateEditModal';

export interface LocationBody {
  id?: UUID;
  title: string;
  longitude: number;
  latitude: number;
}
export interface AddressBody {
  id?: UUID;
  address: string;
  state?: string;
  zip: string;
  city: string;
  countryId: UUID;
}

export interface BusinessBasicInfoBody {
  id: UUID;
  name: string;
  description: string;
}

export interface BusinessAddressBody {
  address: string;
  state: string | null;
  zip: string;
  city: string;
  countryId: UUID;
}
export interface IBusiness {
  id?: UUID;
  name: string;
  description: string;
  locations?: LocationBody[];
  addresses?: AddressBody[];
}

export interface IBatch {
  name: string;
  businessId: UUID;
  locationIds: UUID[];
  message: string;
  totalBones: number;
  offerId?: UUID | null;
  promoText: string | null;
  startDate: string;
  endDate: string;
}

export interface IOffer {
  id?: UUID;
  banner: UUID;
  businessId: UUID;
  title: string;
  shortDescription: string;
  description: string;
  promoText: string | null;
  category: string;
  type: string;
  discountType: string;
  status: string;
  price: number;
  totalOffersLimit: number;
  websiteUrl?: string;
}

export interface IOfferUpdate {
  banner: UUID;
  title: string;
  shortDescription: string;
  description: string;
  promoText: string | null;
  category: string;
  type: string;
  discountType: string;
  price: number;
  websiteUrl?: string;
}

export interface IBatchUpdate {
  message?: string;
  note?: string;
  offerId?: UUID | null;
}

export interface IGetSortParams {
  page?: number;
  size?: number;
  sort?: string[];
  querySearch?: string;
  businessId?: UUID;
  status?: EPinLocationStatus[];
  statusList?: any;
  complaintStatus?: EComplaint[];
  enabled?: boolean;
}

export interface IMarketplaceperformanceParams extends IGetSortParams, Partial<IStats> {}

export enum TOPUP_DIRECTION {
  IN = 'IN',
  OUT = 'OUT',
}

export type EDirection = 'IN' | 'OUT';
export interface ITopupBalance {
  id: UUID;
  amount: number;
  direction?: EDirection;
}

export enum ETimezone {
  UTC = 'UTC',
  NEW_YORK = 'America/New_York',
}

export enum EGroupBy {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum EDayPeriod {
  MORNING = 'MORNING',
  DAY = 'DAY',
  EVENING = 'EVENING',
  NIGHT = 'NIGHT',
  ALL_DAY = 'ALL_DAY',
}

export enum EOfferLinkSource {
  FROM_MARKETPLACE = 'FROM_MARKETPLACE',
  FROM_BONE = 'FROM_BONE',
}

export enum LOCATION_STATUSES {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface IStats {
  startDate: Dayjs;
  endDate: Dayjs;
  groupBy: EGroupBy;
  timezone: ETimezone;
}

export interface ICollectedBonesStats extends IStats {
  dayPeriod: EDayPeriod;
  locationId?: UUID;
  businessId?: UUID;
  byUserUniq?: boolean;
}

export interface IPurchasedOffersStats extends IStats {
  offerId?: UUID;
  businessId?: UUID;
  byUserUniq?: boolean;
}
export interface IOpenedOffersStats extends IStats {
  offerId?: UUID;
  locationId?: UUID;
  businessId?: UUID;
  byUserUniq?: boolean;
  source?: EOfferLinkSource;
}

export interface IRoleBusinessUpdate {
  id: UUID;
  addressId: UUID;
  name: string;
  description: string;
  address: string;
  state: string | null;
  zip: string;
  city: string;
  countryId: UUID;
}

export interface IPinLocation {
  id: UUID;
  longitude: number;
  latitude: number;
  name: string;
  address: string;
  phone: string;
  notes: string;
  type: EPINS;
}

export interface IActivatedOffersStats extends IPurchasedOffersStats {}

export const businessAPI = {
  getBusinesses(params: IGetSortParams) {
    return instance.get('admin/business', { params });
  },
  getBusinessDetails(id: UUID) {
    return instance.get(`admin/business/${id}`);
  },
  createBusiness(params: IBusiness) {
    return instance.post('admin/business', params);
  },
  updateBusinessBasicInfo({ id, name, description }: BusinessBasicInfoBody) {
    return instance.put(`admin/business/${id}`, { name, description });
  },
  updateBusinessAccount(params: IRoleBusinessUpdate) {
    return instance.put('admin/business-account', params);
  },
  updateBusinessAddress({ id, params }: { id: UUID; params: BusinessAddressBody }) {
    return instance.put(`admin/business/address/${id}`, params);
  },
  createLocation({ id, params }: { id: UUID | undefined; params: LocationBody }) {
    return instance.post(`admin/business/${id}/location`, params);
  },
  updateBusinessLocation({ id, params }: { id: UUID | undefined; params: LocationBody }) {
    return instance.put(`admin/business/location/${id}`, params);
  },
  getBatches(params: IGetSortParams) {
    return instance.get('admin/batch-bones/', { params });
  },
  getBatchDetails(id: UUID) {
    return instance.get(`admin/batch-bones/${id}`);
  },
  createBatch(params: IBatch) {
    return instance.post('admin/batch-bones/campaign', params);
  },
  updateSomeBatchData({ id, params }: { id: UUID; params: IBatchUpdate }) {
    return instance.patch(`admin/batch-bones/${id}`, params);
  },
  startBatch(id: UUID) {
    return instance.put(`admin/batch-bones/start/${id}`);
  },
  stopBatch(id: UUID) {
    return instance.put(`admin/batch-bones/stop/${id}`);
  },
  getOffers(params: IGetSortParams) {
    return instance.get('admin/offer', { params });
  },
  getMarketPlacePerformance(params: IMarketplaceperformanceParams) {
    return instance.get('admin/offer/marketplace-performance', { params });
  },
  getOffersStats(businessId: UUID | null) {
    return instance.get('admin/offer/stats', { params: { businessId } });
  },
  getOfferDetails(id?: UUID) {
    return instance.get(`admin/offer/${id}`);
  },
  createOffer(params: IOffer) {
    return instance.post('admin/offer', params);
  },
  updateOffer({ id, params }: { id: UUID; params: IOfferUpdate }) {
    return instance.put(`admin/offer/${id}`, params);
  },
  updateOfferStatus({ id, status }: { id: UUID; status: string }) {
    return instance.put(`admin/offer/change-status/${id}`, null, { params: { status } });
  },
  archiveLocation(id: UUID) {
    return instance.put(`admin/business/location/${id}/archive`);
  },
  checkActiveLocation(id: UUID | null) {
    return instance.get(`admin/business/location/${id}/active`);
  },
  deleteLocation(id: UUID) {
    return instance.delete(`admin/business/location/${id}`);
  },
  getDiscountCodes({ id, params }: { id?: UUID; params?: IGetSortParams }) {
    return instance.get(`admin/offer/${id}/discounts`, { params });
  },
  topupBalance({ id, params }: { id: UUID; params: ITopupBalance }) {
    return instance.post(`admin/business-transaction/${id}/create-transaction`, params);
  },
  topupUserBalance({ id, params }: { id: UUID; params: ITopupBalance }) {
    return instance.post(`admin/bone-transaction/${id}/create-transaction`, params);
  },
  getSystemBalance() {
    return instance.get(`admin/treasure`);
  },
  getBusinessBalance(id: UUID) {
    return instance.get(`admin/treasure/${id}`);
  },
  getBusinessTransactions(params: IGetSortParams) {
    return instance.get('admin/business-transaction', { params });
  },
  getCollectedBonesStats(params: ICollectedBonesStats) {
    return instance.get('admin/stats/collected-bones', { params });
  },
  getPurchasedOffersStats(params: IPurchasedOffersStats) {
    return instance.get('admin/stats/purchased-offers', { params });
  },
  getOpenedOffersStats(params: IOpenedOffersStats) {
    return instance.get('admin/stats/opened-offers', { params });
  },
  getActivatedOffersStats(params: IActivatedOffersStats) {
    return instance.get('admin/stats/activated-offers', { params });
  },
  getLocations(params: { businessId?: UUID; status: LOCATION_STATUSES, size?: number }) {
    return instance.get('admin/business-location', { params });
  },
  getLocationsStats(businessId: UUID | null) {
    return instance.get('admin/business-location/stats', { params: { businessId } });
  },
  getPinsLocations(params: IGetSortParams) {
    return instance.get('admin/location', { params });
  },
  createPinLocation(params: IPinLocation) {
    return instance.post('admin/location', params);
  },
  updatePinLocation(params: IPinLocation) {
    return instance.put('admin/location', params);
  }
};
