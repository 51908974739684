import { CampaignsData, SET_CAMPAIGNS } from '../actions/campaigns';

export interface CampaignsState {
  campaigns: CampaignsData[];
  totalPages: number;
  totalElements: number;
}

const initialState: CampaignsState = {
  campaigns: [],
  totalPages: 0,
  totalElements: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const campaignsReducer = (state = initialState, action: any): CampaignsState => {
  switch (action.type) {
    case SET_CAMPAIGNS:
      return {
        campaigns: action.payload.content,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
};

export default campaignsReducer;
