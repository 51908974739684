import { SET_BUSINESSES, BusinessData } from '../actions/businesses';

export interface BusinessState {
  businesses: BusinessData[];
  totalPages: number;
  totalElements: number;
}

const initialState: BusinessState = {
  businesses: [],
  totalPages: 0,
  totalElements: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const businessesReducer = (state = initialState, action: any): BusinessState => {
  switch (action.type) {
    case SET_BUSINESSES:
      return {
        businesses: action.payload.content,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
};

export default businessesReducer;
