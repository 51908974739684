import { IGetSortParams } from './businessAPI';
import { instance, UUID } from './index';

export const usersAPI = {
  getUsers(params: IGetSortParams) {
    return instance.get('admin/users', {
      params,
    });
  },
  getUserDetails(id: UUID) {
    return instance.get(`admin/users/${id}`);
  },
};
